import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { PageResponse } from '@models/api';
import { PartnerProductPriceBulkUpload } from '../../models/partnerBulk';
import { getPriceUploadHistory } from '../../apis/partnerBulk';

export const usePriceUploadHistory = (partnerId: number, uploadId: number) => {
  const queryOptions: UseQueryOptions<
    PageResponse<PartnerProductPriceBulkUpload>,
    Error
  > = {
    queryKey: ['priceUploadDetail', partnerId, uploadId],
    queryFn: () => {
      return getPriceUploadHistory(partnerId, uploadId);
    },
  };
  return useQuery(queryOptions);
};
