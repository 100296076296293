import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { PageResponse } from '@models/api';
import { getOrders, GetOrdersParams, OrderResponse } from '@apis/order';
import { dropEmptyValues } from '../../utils/common';
import { User, UserGroup } from '@models/user';

export const useOrderList = ({
  page,
  searchParam,
  user,
}: {
  page: number;
  searchParam: GetOrdersParams;
  user: User | null;
}) => {
  const queryOptions: UseQueryOptions<PageResponse<OrderResponse>, Error> = {
    queryKey: ['orders', page, user?.userId],
    queryFn: () => {
      return getOrders({
        pageNo: page,
        pageSize: 50,
        ...dropEmptyValues(searchParam, [
          'orderCode',
          'productCode',
          'productName',
          'stockId',
          'partnerId',
          'orderStatus',
          'createdAtFrom',
          'createdAtTo',
        ]),
        ...(user && user.group === UserGroup.PARTNER
          ? { partnerId: user.partnerId }
          : {}),
      });
    },
    enabled:
      !!user &&
      (user.group === UserGroup.PARTNER ? !!searchParam.partnerId : true),
  };
  return useQuery(queryOptions);
};
