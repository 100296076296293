import { useMemo, useState } from 'react';
import { Box, Typography } from '@mui/material';
import TextInput from '@components/common/TextInput';
import { Divider } from '@material-ui/core';
import Label from '@components/common/Label';
import { FormControl } from '@mui/base/FormControl';
import Button from '@components/common/Button';
import { useNavigate } from 'react-router-dom';
import { useCountryList } from '@queries/countries/useCountryList';
import { alertMissingValues } from '@utils/common';
import {
  CreateOrderRequest,
  CustomerDetail,
  createOrder,
} from '../../apis/order';
import { useRecoilValue } from 'recoil';
import { userState } from '../../recoils/atoms';
import { RequiredDataType } from '../../models/product';
import { useActivePartnerProductPrices } from '../../queries/partners/useActivePartnerProductPrices';
import CheckBox from '@components/common/CheckBox';
import Select from '@components/common/Select';
import SearchSelect from '../../components/common/SearchSelect';

const getField = (field: RequiredDataType) => {
  switch (field) {
    case 'ACTIVATION_DATE':
      return 'activationDate';
    case 'VALIDITY_DAYS':
      return 'validDays';
    case 'IMEI':
      return 'imei';
    case 'EID':
      return 'eid';
    case 'CUSTOMER_NAME':
      return 'customerName';
  }
  return '';
};

const getValue = (
  customerDetail: CustomerDetail,
  field: RequiredDataType
): string => {
  switch (field) {
    case 'ACTIVATION_DATE':
      return customerDetail.activationDate ?? '';
    case 'VALIDITY_DAYS':
      return customerDetail.validDays?.toString() ?? '';
    case 'IMEI':
      return customerDetail.imei ?? '';
    case 'EID':
      return customerDetail.eid ?? '';
    case 'CUSTOMER_NAME':
      return customerDetail.customerName ?? '';
  }
  return '';
};
export const AddOrder = () => {
  const navigate = useNavigate();
  const [form, setForm] = useState<CreateOrderRequest>({
    productCode: '',
    quantity: 0,
    customerDetails: [],
    sendEmail: true,
  });
  const user = useRecoilValue(userState);
  const { data: productPrices } = useActivePartnerProductPrices({
    page: 1,
    pageSize: 10000,
    partnerId: user?.partnerId ?? 0,
    searchParam: {},
  });
  const requiredFields: RequiredDataType[] = useMemo(
    () =>
      (productPrices?.data ?? [])
        .filter((p) => p.productCode === form.productCode)
        .map((p) => p.requiredData.requiredFields),
    [productPrices, form.productCode]
  ).flatMap((r) => r);

  const productOptions = useMemo(
    () =>
      (productPrices?.data ?? []).map((p) => ({
        label: p.productName,
        value: p.productCode,
      })),
    [productPrices?.data]
  );

  const { data: countriesList } = useCountryList();

  const countryOptions = useMemo(
    () =>
      (countriesList?.data ?? []).map((c) => ({
        label: c.englishName,
        value: c.countryCode,
      })),
    [countriesList]
  );

  const handleChangeInput = (
    name: keyof CreateOrderRequest,
    value: string | boolean | number | null
  ) => {
    setForm((prev) => ({ ...prev, [name]: value }));
  };
  const handleChangeQuantity = (quantity: number) => {
    if (form.customerDetails.length < quantity) {
      setForm({
        ...form,
        quantity: quantity,
        customerDetails: [
          ...form.customerDetails,
          ...Array.from(
            { length: quantity - form.customerDetails.length },
            () => ({})
          ),
        ],
      });
    } else {
      setForm({
        ...form,
        quantity: quantity,
        customerDetails: form.customerDetails.slice(0, quantity),
      });
    }
  };

  const handleUpdateCustomerDetail = (
    index: number,
    customerDetail: CustomerDetail
  ) => {
    setForm((prev) => ({
      ...prev,
      customerDetails: prev.customerDetails.map((d, i) =>
        i === index ? customerDetail : d
      ),
    }));
  };
  const handleCancel = () => {
    if (!!window.confirm('Do you want to cancel?')) {
      navigate(-1);
    }
  };

  const handleAdd = async () => {
    const hasMissingValues = alertMissingValues(form);
    if (hasMissingValues) {
      return;
    }
    const customerDetails = form.customerDetails.map((d) => {
      const updated: CustomerDetail = {
        customerName: d.customerName,
        phoneNumber: d.phoneNumber,
        email: d.email,
      };
      if (requiredFields.includes(RequiredDataType.ACTIVATION_DATE)) {
        updated.activationDate = d.activationDate;
      }
      if (requiredFields.includes(RequiredDataType.VALIDITY_DAYS)) {
        updated.validDays = d.validDays;
      }
      if (requiredFields.includes(RequiredDataType.IMEI)) {
        updated.imei = d.imei;
      }
      if (requiredFields.includes(RequiredDataType.EID)) {
        updated.eid = d.eid;
      }
      if (requiredFields.includes(RequiredDataType.CUSTOMER_NAME)) {
        updated.customerName = d.customerName;
      }
      if (requiredFields.includes(RequiredDataType.PASSPORT_DATA)) {
        updated.passportData = d.passportData;
      }
      return updated;
    });

    const response = await createOrder({
      ...form,
      customerDetails: customerDetails,
    });

    if (response.code === 2000) {
      alert('Order added successfully');
      navigate('/orders');
    } else {
      alert(response.message);
    }
  };

  return (
    <>
      <Typography variant="h4" component="h4">
        Create Order
      </Typography>
      <Box
        display={'flex'}
        justifyContent={'flex-end'}
        flexDirection={'column'}
        gap={3}
        mb={4}
        mt={4}
      >
        <SearchSelect
          disablePortal
          label={'Product'}
          options={productOptions}
          placeholder="Select Product"
          layout="horizontal"
          labelWidth={150}
          value={
            productOptions.find((c) => c.value === form.productCode) || null
          }
          setValue={(value) =>
            handleChangeInput('productCode', value?.value || null)
          }
        />
        <TextInput
          label={'Quantity'}
          value={form.quantity.toString()}
          onChange={(e) => handleChangeQuantity(Number(e.target.value))}
          layout="horizontal"
          labelWidth={150}
          inputWidth={200}
          type="number"
        />
        <Box flexDirection="row" display="flex">
          <CheckBox
            label={'Send Email to Customers'}
            value={form.sendEmail}
            onCheck={() => handleChangeInput('sendEmail', !form.sendEmail)}
          />
        </Box>

        <Divider />
        <FormControl>
          <>
            {form.customerDetails.map((detail, index) => (
              <>
                <Label>Customer #{index + 1}</Label>
                <Box
                  display={'grid'}
                  gridTemplateColumns={'1fr 1fr 1fr'}
                  mb={2}
                  mt={2}
                  alignItems={'center'}
                  gap={2}
                >
                  <TextInput
                    label={'Email'}
                    value={detail.email}
                    layout="horizontal"
                    labelWidth={150}
                    inputWidth={200}
                    onChange={(e) =>
                      handleUpdateCustomerDetail(index, {
                        ...detail,
                        email: e.target.value,
                      })
                    }
                  />
                  <TextInput
                    label={'Phone Number'}
                    value={detail.phoneNumber}
                    layout="horizontal"
                    labelWidth={150}
                    inputWidth={200}
                    onChange={(e) =>
                      handleUpdateCustomerDetail(index, {
                        ...detail,
                        phoneNumber: e.target.value,
                      })
                    }
                  />
                  <TextInput
                    label={RequiredDataType.CUSTOMER_NAME}
                    value={detail.customerName}
                    layout="horizontal"
                    labelWidth={150}
                    inputWidth={200}
                    onChange={(e) =>
                      handleUpdateCustomerDetail(index, {
                        ...detail,
                        customerName: e.target.value,
                      })
                    }
                  />
                  {requiredFields
                    .filter((field) => field !== RequiredDataType.PASSPORT_DATA)
                    .map((field) => (
                      <TextInput
                        label={field}
                        value={getValue(detail, field)}
                        onChange={(e) =>
                          setForm((prev) => ({
                            ...prev,
                            customerDetails: prev.customerDetails.map((d, i) =>
                              i === index
                                ? {
                                    ...d,
                                    [getField(field)]: e.target.value,
                                  }
                                : d
                            ),
                          }))
                        }
                        layout="horizontal"
                        labelWidth={150}
                        inputWidth={200}
                        type={
                          field === RequiredDataType.ACTIVATION_DATE
                            ? 'date'
                            : field === RequiredDataType.VALIDITY_DAYS
                            ? 'number'
                            : 'text'
                        }
                      />
                    ))}
                </Box>
                {requiredFields.length > 0 && <Divider />}
                {requiredFields.includes(RequiredDataType.PASSPORT_DATA) && (
                  <>
                    <Label>Customer #{index + 1} Passport Info</Label>

                    <Box
                      display={'grid'}
                      gridTemplateColumns={'1fr 1fr 1fr'}
                      mb={2}
                      mt={2}
                      alignItems={'center'}
                      gap={2}
                    >
                      {requiredFields.includes(
                        RequiredDataType.PASSPORT_DATA
                      ) && (
                        <>
                          <TextInput
                            label={'Passport Number'}
                            value={detail.passportData?.passportNumber ?? ''}
                            onChange={(e) =>
                              handleUpdateCustomerDetail(index, {
                                ...detail,
                                passportData: {
                                  ...detail.passportData,
                                  passportNumber: e.target.value,
                                },
                              })
                            }
                            layout="horizontal"
                            labelWidth={150}
                            inputWidth={200}
                          />

                          <TextInput
                            label={'Name'}
                            value={detail.passportData?.passportName ?? ''}
                            onChange={(e) =>
                              handleUpdateCustomerDetail(index, {
                                ...detail,
                                passportData: {
                                  ...detail.passportData,
                                  passportName: e.target.value,
                                },
                              })
                            }
                            layout="horizontal"
                            labelWidth={150}
                            inputWidth={200}
                          />
                          <Box />
                          <TextInput
                            label={'BirthDate'}
                            value={detail.passportData?.passportBirthdate ?? ''}
                            onChange={(e) =>
                              handleUpdateCustomerDetail(index, {
                                ...detail,
                                passportData: {
                                  ...detail.passportData,
                                  passportBirthdate: e.target.value,
                                },
                              })
                            }
                            type="date"
                            layout="horizontal"
                            labelWidth={150}
                            inputWidth={200}
                          />
                          <Select
                            label={'Country'}
                            optionList={countryOptions}
                            placeholder="Select Country"
                            layout="horizontal"
                            labelWidth={150}
                            value={detail.passportData?.passportCountry ?? 'US'}
                            setValue={(value) =>
                              handleUpdateCustomerDetail(index, {
                                ...detail,
                                passportData: {
                                  ...detail.passportData,
                                  passportCountry: value
                                    ? value.toString()
                                    : 'US',
                                },
                              })
                            }
                          />
                        </>
                      )}
                      <Box gap={5} display={'flex'} flexDirection={'row'}></Box>
                    </Box>
                  </>
                )}
              </>
            ))}
          </>
        </FormControl>
        <Divider />
      </Box>
      <Box
        display={'flex'}
        flexDirection={'row'}
        justifyContent={'flex-end'}
        gap={2}
      >
        <Button text={'CANCEL'} onClick={handleCancel} color={'error'} />
        <Button text={'ADD'} onClick={handleAdd} />
      </Box>
    </>
  );
};
