import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { PageResponse } from '@models/api';
import {
  GetSalesRankPartnerResponse,
  PeriodUnit,
  getPartnerSalesRank,
} from '@apis/summary';
import { getTimestampRangeByPeriod } from '@utils/time';

export const useSummarySalesByPartner = (period: PeriodUnit) => {
  const queryOptions: UseQueryOptions<
    PageResponse<GetSalesRankPartnerResponse>,
    Error
  > = {
    queryKey: ['dashboardSummaySalesByPartner'],
    queryFn: () => {
      const { from, to } = getTimestampRangeByPeriod(period);
      return getPartnerSalesRank({ from, to });
    },
  };
  return useQuery(queryOptions);
};
