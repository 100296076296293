import { FormControl } from '@mui/base/FormControl';
import { Box } from '@mui/material';
import Label from './Label';
import Checkbox from '@material-ui/core/Checkbox';

type CheckBoxProps = {
  label?: string;
  value: boolean;
  onCheck: (checked: boolean) => void;
  preLabel?: string;
};

export const CheckBox = ({
  preLabel,
  label,
  onCheck,
  value,
}: CheckBoxProps) => {
  return (
    <FormControl>
      <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
        {preLabel && (
          <Box>
            <Label>{preLabel}</Label>
          </Box>
        )}
        <Checkbox
          onChange={(_, c) => {
            onCheck(c);
          }}
          checked={value}
          value={value}
          color="primary"
        />
        {label && (
          <Box>
            <Label>{label}</Label>
          </Box>
        )}
      </Box>
    </FormControl>
  );
};

export default CheckBox;
