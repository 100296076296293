import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { PageResponse } from '@models/api';
import {
  GetOrderEsimsParams,
  OrderEsimResponse,
  getOrderEsims,
} from '@apis/order';
import { dropEmptyValues } from '../../utils/common';

export const useOrderEsims = ({
  page,
  orderId,
  searchParams,
}: {
  page: number;
  orderId: number;
  searchParams: GetOrderEsimsParams;
}) => {
  const queryOptions: UseQueryOptions<
    PageResponse<OrderEsimResponse>,
    Error
  > = {
    queryKey: ['orderEsims', orderId, page],
    queryFn: () => {
      return getOrderEsims(orderId, {
        pageNo: page,
        pageSize: 50,
        ...dropEmptyValues(searchParams, [
          'esimType',
          'parameterType',
          'parameterValue',
        ]),
      });
    },
  };
  return useQuery(queryOptions);
};
