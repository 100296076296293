import {
  Box,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
  styled,
  tableCellClasses,
} from '@mui/material';
import { useState } from 'react';
import IconButton from '@components/common/IconButton';
import { DeleteOutline } from '@mui/icons-material';
import Button from '@components/common/Button';
import DragDropFile from '@components/common/DragDropFile';
import { useNavigate, useParams } from 'react-router-dom';
import { timestampToDate } from '@utils/time';
import { downloadFile } from '@utils/file';
import { PARTNER_PRICE_UPLOAD_TEMPLATE } from '@constants/templates';
import { usePriceUploadHistories } from '../../queries/partners/usePriceUploadHistories';
import {
  deletePriceUploadHistory,
  uploadPriceFile,
} from '../../apis/partnerBulk';
import { usePriceUploadHistoriesCount } from '../../queries/partners/usePriceUploadHistoriesCount';

const PAGE_SIZE = 50;
export const PartnerPriceUpload = (): React.ReactElement | null => {
  const [pageNo, setPageNo] = useState(1);
  const navigate = useNavigate();

  const { partnerId: partnerIdParam } = useParams();

  const partnerId = Number(partnerIdParam);
  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPageNo(value);
  };

  const { data: historiesData, refetch } = usePriceUploadHistories(partnerId, {
    pageNo: pageNo,
  });

  const { data: totalCount, refetch: refetchCount } =
    usePriceUploadHistoriesCount(partnerId);

  const handleConfirmFile = async (file: File) => {
    // error
    const { data, message } = await uploadPriceFile(partnerId, file);

    if (message !== 'OK') {
      alert(message);
    } else {
      const uploadId = Number(data[0]);
      refetch();
      refetchCount();
      navigate(`/partners/${partnerId}/prices/upload/${uploadId}`);
    }
  };

  const handleOnViewTemplate = () => {
    downloadFile(
      PARTNER_PRICE_UPLOAD_TEMPLATE,
      'partner_price_upload_template'
    );
  };

  const handleDeleteUpload = async (uploadId: number) => {
    if (!!window.confirm('Are you sure to delete this history?')) {
      await deletePriceUploadHistory(partnerId, uploadId);
    }

    refetch();
    refetchCount();
  };

  return (
    <>
      <Typography variant="h4" component="h4">
        Bulk Price Upload
      </Typography>
      <Box display={'flex'} justifyContent={'flex-end'}>
        <Button
          text={'TEMPLATE'}
          color="secondary"
          onClick={handleOnViewTemplate}
        />
      </Box>
      <Box width={'100%'} mt={2}>
        <DragDropFile onConfirmFile={handleConfirmFile} />
      </Box>

      <Toolbar />
      <Typography variant="h5">History</Typography>
      <TableContainer
        component={Paper}
        sx={{
          marginTop: 2,
        }}
      >
        <Table>
          <TableHead
            sx={{
              backgroundColor: '#f5f5f5',
            }}
          >
            <TableRow>
              <StyledTableCell>Index</StyledTableCell>
              <StyledTableCell>Uploaded Qty.</StyledTableCell>
              <StyledTableCell>File name</StyledTableCell>
              <StyledTableCell>Date & Time</StyledTableCell>
              <StyledTableCell>Delete</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(historiesData?.data || []).map((history, index) => (
              <StyledTableRow
                key={history.partnerProductPriceBulkUploadId}
                onClick={() => {
                  navigate(
                    `/partners/${partnerId}/prices/upload/${history.partnerProductPriceBulkUploadId}`
                  );
                }}
              >
                <StyledTableCell>{index + 1}</StyledTableCell>
                <StyledTableCell>{history.successCount}</StyledTableCell>
                <StyledTableCell>{history.filename}</StyledTableCell>
                <StyledTableCell>
                  {timestampToDate(history.createdAt)}
                </StyledTableCell>
                <StyledTableCell>
                  <IconButton
                    onClick={() => {
                      handleDeleteUpload(
                        history.partnerProductPriceBulkUploadId
                      );
                    }}
                  >
                    <DeleteOutline />
                  </IconButton>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          mt: 2,
        }}
      >
        <Pagination
          count={Math.ceil((totalCount ?? 0) / PAGE_SIZE)}
          page={pageNo}
          onChange={handleChangePage}
        />
      </Box>
    </>
  );
};

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#000',
    color: '#fff',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(() => ({
  backgroundColor: '#FFFFFF',
  '&:hover': {
    backgroundColor: '#F3F6F9',
    cursor: 'pointer',
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));
