import { Box, Input, Modal, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import { styled } from '@mui/system';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { redeemEsim, resendMail } from '../apis/exchange';
import KoreanMessage from './messages-main-ko.json';
import JapaneseMessage from './messages-main-ja.json';
import SimplifiedChineseMessage from './messages-main-zh-CN.json';
import TraditionalChineseMessage from './messages-main-zh-TW.json';
import EnglishMessage from './messages-main-en.json';
import Select from '../components/common/Select';

type CountryCode = 'KR' | 'JP' | 'US' | 'CN' | 'TW';
type MessageKeys = keyof typeof KoreanMessage;

const StyledFlagsSelect = styled(Select)({
  border: 'none',
  padding: '0',
  bgcolor: '#00728C',
});

const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

function getLang() {
  if (navigator.languages !== undefined) return navigator.languages[0];
  return navigator.language;
}

const getMessage = (lang: CountryCode, key: MessageKeys) => {
  switch (lang) {
    case 'KR':
      return KoreanMessage[key];
    case 'JP':
      return JapaneseMessage[key];
    case 'CN':
      return SimplifiedChineseMessage[key];
    case 'TW':
      return TraditionalChineseMessage[key];
    default:
      return EnglishMessage[key];
  }
};

const createCaptcha = (length: number) => {
  const characters = 'abcdefghijkmnopqrstuvwxyz23456789';
  let result = '';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};
export const ExchangeMain = (): React.ReactElement => {
  const [captchaCode, setCaptchaCode] = React.useState(createCaptcha(5));
  const refreshCaptcha = () => {
    setCaptchaCode(createCaptcha(5));
  };
  const [country, setCountry] = React.useState<CountryCode>(
    getLang().includes('ko') ? 'KR' : 'US'
  );
  const [params] = useSearchParams();
  const navigate = useNavigate();

  const [email, setEmail] = React.useState('');
  const invalidEmail = useMemo(
    () => !!email && !email.match(EMAIL_REGEX),
    [email]
  );
  const [emailConfirm, setEmailConfirm] = React.useState('');
  const invalidEmailConfirm = useMemo(
    () => email !== emailConfirm,
    [email, emailConfirm]
  );
  const [exchangeCode, setExchangeCode] = React.useState('');
  const [captcha, setCaptcha] = React.useState('');
  const invalidCaptcha = useMemo(
    () => captcha !== captchaCode,
    [captcha, captchaCode]
  );

  const [error, setError] = React.useState<boolean>(!!params.get('error'));
  const [emailError, setEmailError] = React.useState<boolean>(false);
  const [emailSuccess, setEmailSuccess] = React.useState<boolean>(false);
  const onExchangeClicked = async () => {
    if (
      invalidEmail ||
      invalidEmailConfirm ||
      invalidCaptcha ||
      !exchangeCode
    ) {
      return;
    }
    const response = await redeemEsim({
      email: email,
      locale: country === 'KR' ? 'ko' : 'en',
      redemptionCode: exchangeCode,
    }).catch((e) => {
      setError(true);
    });

    if (!response) {
      return;
    }
    if (response.code !== 2000) {
      setEmailError(true);
      return;
    }
    navigate(
      `/exchange/${response.data[0].encryptedEsimId}?email=${encodeURIComponent(
        email
      )}&locale=${country}`
    );
  };

  const onEmailSend = async () => {
    if (
      invalidEmail ||
      invalidEmailConfirm ||
      invalidCaptcha ||
      !exchangeCode
    ) {
      return;
    }
    const response = await resendMail({
      email: email,
      locale: country === 'KR' ? 'ko' : 'en',
      redemptionCode: exchangeCode,
    }).catch((e) => {
      setError(true);
    });
    if (!response) {
      return;
    }
    if (response.code !== 2000) {
      setEmailError(true);
      return;
    } else {
      setEmailSuccess(true);
    }
  };

  const onEmailModalClose = () => {
    setEmailError(false);
    setEmailSuccess(false);
  };

  return (
    <>
      <Box
        width={600}
        height={1200}
        border="1px black solid"
        margin="0 auto"
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <Box height="30px" marginTop="14px" marginRight="0" marginLeft="auto">
          <StyledFlagsSelect
            value={country}
            setValue={(e) => {
              setCountry(e as CountryCode);
            }}
            // 'KR', 'US', 'JP', 'CN', 'TW'
            optionList={[
              {
                label: '한국어',
                value: 'KR',
              },
              {
                label: 'English',
                value: 'US',
              },
              {
                label: '日本語',
                value: 'JP',
              },
              {
                label: '简体',
                value: 'CN',
              },
              {
                label: '繁體',
                value: 'TW',
              },
            ]}
          />
        </Box>
        <Box textAlign="center">
          <Box textAlign="center">
            <Box marginTop="21px">
              <img
                src="https://admin.esimsta.com/resources/static/symbol.png"
                width={129}
                height={129}
              />
            </Box>
            <Box margin="31px auto">
              <Typography
                color="#00728C"
                fontSize={41}
                fontFamily="Pretendard"
                fontWeight="800"
              >
                {getMessage(country, 'str_redeem_title')}
              </Typography>
            </Box>
            <Box margin="23px auto">
              <Typography
                color="black"
                fontSize={18}
                fontFamily="Pretendard"
                fontWeight="600"
                whiteSpace={'pre-wrap'}
              >
                {getMessage(country, 'str_redeem_greeting')}
              </Typography>
            </Box>
            <Box
              width="522px"
              height="123px"
              marginLeft="34px"
              borderRadius="5px"
              bgcolor={'#EEEDEC'}
              margin="20px auto 0"
              textAlign={'left'}
              paddingLeft={'20px'}
            >
              <Typography
                color="black"
                fontSize="18px"
                fontFamily="Pretendard"
                fontWeight="700"
                whiteSpace={'break-word'}
              >
                {getMessage(country, 'str_redeem_notice')}
              </Typography>
              <Typography
                marginTop={'10px'}
                color="#000000"
                fontSize="16px"
                fontFamily="Pretendard"
                fontWeight="400"
                whiteSpace={'break-word'}
              >
                {'•' + getMessage(country, 'str_redeem_notice_detail_1')}
                <br />
                {'•' + getMessage(country, 'str_redeem_notice_detail_2')}
              </Typography>
            </Box>
            <Box>
              <Box
                marginTop="25px"
                width="520px"
                display={'flex'}
                alignItems="center"
                marginLeft="34px"
              >
                <Typography
                  color="black"
                  fontSize={24}
                  fontFamily="Pretendard"
                  fontWeight={800}
                  whiteSpace="break-word"
                >
                  {getMessage(country, 'str_redeem_input_email')}
                </Typography>
                <Typography
                  color="black"
                  fontSize={14}
                  fontFamily="Pretendard"
                  fontWeight={500}
                  whiteSpace="break-word"
                  marginLeft="18px"
                >
                  {'*' + getMessage(country, 'str_redeem_input_email_note')}
                </Typography>
              </Box>
              <Box
                marginLeft="34px"
                width="520px"
                bgcolor={'white'}
                borderBottom="1px #999999 solid"
                textAlign={'left'}
              >
                <Input
                  fullWidth
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder={getMessage(
                    country,
                    'str_redeem_input_email_guide'
                  )}
                  type="email"
                  error={invalidEmail}
                  renderSuffix={({ error }) =>
                    error && (
                      <Box
                        display={'inline-flex'}
                        marginRight="0px"
                        padding="0"
                        width="100%"
                      >
                        <Typography
                          color="red"
                          fontSize={14}
                          fontFamily="Pretendard"
                          fontWeight={500}
                          marginBottom="0px"
                        >
                          {getMessage(country, 'str_redeem_input_email_error')}
                        </Typography>
                        <img
                          src="https://admin.esimsta.com/resources/static/error_input.png"
                          width="20px"
                          height="20px"
                        />
                      </Box>
                    )
                  }
                />
              </Box>
              <Box
                marginLeft="34px"
                width="520px"
                display={'flex'}
                alignItems="center"
                marginTop="27px"
              >
                <Typography
                  color="black"
                  fontSize={24}
                  fontFamily="Pretendard"
                  fontWeight={800}
                  whiteSpace="break-word"
                >
                  {getMessage(country, 'str_redeem_verify_email')}
                </Typography>
              </Box>
              <Box
                marginLeft="34px"
                width="520px"
                bgcolor={'white'}
                borderBottom="1px #999999 solid"
                textAlign={'left'}
              >
                <Input
                  fullWidth
                  value={emailConfirm}
                  onChange={(e) => setEmailConfirm(e.target.value)}
                  placeholder={getMessage(
                    country,
                    'str_redeem_verify_email_guide'
                  )}
                  type="email"
                  error={!!emailConfirm && invalidEmailConfirm}
                  renderSuffix={({ error }) =>
                    error && (
                      <Box
                        display={'inline-flex'}
                        marginRight="0px"
                        width="100%"
                      >
                        <Typography
                          color="red"
                          fontSize={14}
                          fontFamily="Pretendard"
                          fontWeight={500}
                          marginBottom="0px"
                        >
                          {getMessage(country, 'str_redeem_verify_email_error')}
                        </Typography>
                        <img
                          src="https://admin.esimsta.com/resources/static/error_input.png"
                          width="20px"
                          height="20px"
                        />
                      </Box>
                    )
                  }
                />
              </Box>
              <Box
                marginLeft="34px"
                width="520px"
                display={'flex'}
                alignItems="center"
                marginTop="27px"
              >
                <Typography
                  color="black"
                  fontSize={24}
                  fontFamily="Pretendard"
                  fontWeight={800}
                  whiteSpace="break-word"
                >
                  {getMessage(country, 'str_redeem_coupon')}
                </Typography>
              </Box>
              <Box
                marginLeft="34px"
                width="520px"
                bgcolor={'white'}
                borderBottom="1px #999999 solid"
                textAlign={'left'}
              >
                <Input
                  fullWidth
                  value={exchangeCode}
                  onChange={(e) => setExchangeCode(e.target.value)}
                  placeholder={getMessage(country, 'str_redeem_coupon_guide')}
                  type="text"
                />
              </Box>
              <Box
                marginLeft="34px"
                display={'flex'}
                alignItems="center"
                marginTop={'27px'}
              >
                <Typography
                  color="black"
                  fontSize={24}
                  fontFamily="Pretendard"
                  fontWeight={700}
                  whiteSpace="break-word"
                >
                  {getMessage(country, 'str_redeem_captcha')}
                </Typography>
              </Box>
              <Box
                width="520px"
                marginLeft="34px"
                bgcolor={'white'}
                borderBottom="1px #999999 solid"
                textAlign={'left'}
              >
                <Box
                  display="inline-flex"
                  justifyContent={'space-between'}
                  width="100%"
                >
                  <Input
                    fullWidth
                    value={captcha}
                    onChange={(e) => setCaptcha(e.target.value)}
                    placeholder={getMessage(
                      country,
                      'str_redeem_captcha_guide'
                    )}
                    type="text"
                    error={!!captcha && invalidCaptcha}
                  />
                  <Box
                    bgcolor={'#EEEDEC'}
                    onClick={refreshCaptcha}
                    style={{
                      cursor: 'pointer',
                    }}
                  >
                    <Typography
                      color="black"
                      fontSize={26}
                      fontFamily="Pretendard"
                      fontWeight="700"
                      whiteSpace={'break-word'}
                      style={{ textDecoration: 'line-through' }}
                    >
                      {captchaCode}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box
            width={350}
            height={70}
            bgcolor={'#00728C'}
            borderRadius="10px"
            display={'inline-flex'}
            alignItems={'center'}
            justifyContent={'center'}
            marginTop="27px"
            onClick={() => onExchangeClicked()}
            style={{
              cursor: 'pointer',
            }}
          >
            <Typography
              color="white"
              fontSize={26}
              fontFamily="Pretendard"
              fontWeight="700"
              whiteSpace={'break-word'}
            >
              {getMessage(country, 'str_redeem_submit')}
            </Typography>
          </Box>
          <Box
            display={'inline-flex'}
            alignItems={'center'}
            justifyContent={'center'}
            marginTop="15px"
          >
            <Typography
              color="black"
              fontSize={15}
              fontFamily="Pretendard"
              fontWeight="400"
              whiteSpace="pre-wrap"
            >
              {getMessage(country, 'str_redeem_resend_email_guide_1')}
            </Typography>
            <Box
              onClick={() => onEmailSend()}
              style={{
                cursor: 'pointer',
              }}
            >
              <Typography
                color="black"
                fontSize={15}
                fontFamily="Pretendard"
                fontWeight="700"
                whiteSpace="pre-wrap"
                style={{ textDecoration: 'underline' }}
              >
                {getMessage(country, 'str_redeem_resend_email')}
              </Typography>
            </Box>
            {getMessage(country, 'str_redeem_resend_email_guide_2').length >
              0 && (
              <Typography
                color="black"
                fontSize={15}
                fontFamily="Pretendard"
                fontWeight="400"
                whiteSpace="pre-wrap"
              >
                {getMessage(country, 'str_redeem_resend_email_guide_2')}
              </Typography>
            )}
          </Box>
        </Box>
      </Box>
      {error && (
        <Modal open={error} onClose={() => setError(false)}>
          <Box
            top="0"
            left="0"
            right="0"
            bottom="0"
            margin="auto"
            textAlign={'center'}
            width={'515px'}
            height={'357px'}
            bgcolor={'white'}
            position={'absolute'}
            borderRadius="30px"
          >
            <Box>
              <img
                src="https://admin.esimsta.com/resources/static/popup_icon.png"
                width={'103px'}
                height={'103px'}
              />
              <Typography
                color="black"
                fontSize={34}
                fontFamily="Pretendard"
                fontWeight="800"
              >
                {getMessage(country, 'str_redeem_fail_title')}
              </Typography>
              <Typography
                marginTop={'10px'}
                color="#000000"
                fontSize={24}
                fontFamily="Pretendard"
                fontWeight="300"
                whiteSpace={'pre-wrap'}
              >
                {getMessage(country, 'str_redeem_fail_description')}
              </Typography>
              <Box
                width={'465px'}
                height={'60px'}
                bgcolor={'#00728C'}
                borderRadius="10px"
                display={'inline-flex'}
                alignItems={'center'}
                justifyContent={'center'}
                marginTop="27px"
                onClick={() => setError(false)}
                style={{
                  cursor: 'pointer',
                }}
              >
                <Typography
                  color="white"
                  fontSize={26}
                  fontFamily="Pretendard"
                  fontWeight="700"
                  whiteSpace={'break-word'}
                >
                  {getMessage(country, 'str_redeem_fail_confirm')}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Modal>
      )}
      {emailError && (
        <Modal open={emailError} onClose={onEmailModalClose}>
          <Box
            top="0"
            left="0"
            right="0"
            bottom="0"
            margin="auto"
            textAlign={'center'}
            width={'515px'}
            height={'500px'}
            bgcolor={'white'}
            position={'absolute'}
            padding="24px"
            borderRadius="30px"
          >
            <Box
              height="100%"
              width="100%"
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
              <img
                src="https://admin.esimsta.com/resources/static/popup_icon.png"
                width={'103px'}
                height={'103px'}
              />
              <Typography
                color="black"
                fontSize={34}
                fontFamily="Pretendard"
                fontWeight="800"
              >
                {getMessage(country, 'str_resend_fail_title')}
              </Typography>
              <Typography
                marginTop={'10px'}
                color="#000000"
                fontSize={24}
                fontFamily="Pretendard"
                fontWeight="300"
                whiteSpace={'pre-wrap'}
              >
                {getMessage(country, 'srt_resend_fail_to_find_info')}
              </Typography>
              <Box
                width={'465px'}
                height={'60px'}
                bgcolor={'#00728C'}
                borderRadius="10px"
                display={'inline-flex'}
                alignItems={'center'}
                justifyContent={'center'}
                marginTop="auto"
                marginBottom="10px"
                onClick={onEmailModalClose}
                style={{
                  cursor: 'pointer',
                }}
              >
                <Typography
                  color="white"
                  fontSize={26}
                  fontFamily="Pretendard"
                  fontWeight="700"
                  whiteSpace={'break-word'}
                >
                  {getMessage(country, 'str_resend_fail_confirm')}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Modal>
      )}
      {emailSuccess && (
        <Modal open={emailSuccess} onClose={onEmailModalClose}>
          <Box
            top="0"
            left="0"
            right="0"
            bottom="0"
            margin="auto"
            textAlign={'center'}
            width={'515px'}
            height={'400px'}
            bgcolor={'white'}
            position={'absolute'}
            padding="24px"
            borderRadius="30px"
          >
            <Box
              height="100%"
              width="100%"
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
              <img
                src="https://admin.esimsta.com/resources/static/check.png"
                width={'103px'}
                height={'103px'}
              />
              <Typography
                color="black"
                fontSize={34}
                fontFamily="Pretendard"
                fontWeight="800"
              >
                {getMessage(country, 'str_redeem_ok_title')}
              </Typography>
              <Typography
                marginTop={'10px'}
                color="#000000"
                fontSize={24}
                fontFamily="Pretendard"
                fontWeight="300"
                whiteSpace={'pre-wrap'}
              >
                {getMessage(country, 'str_redeem_ok_description').replaceAll(
                  '{{email}}',
                  email
                )}
              </Typography>
              <Box
                width={'465px'}
                height={'60px'}
                bgcolor={'#00728C'}
                borderRadius="10px"
                display={'inline-flex'}
                alignItems={'center'}
                justifyContent={'center'}
                marginTop="auto"
                marginBottom="10px"
                onClick={onEmailModalClose}
                style={{
                  cursor: 'pointer',
                }}
              >
                <Typography
                  color="white"
                  fontSize={26}
                  fontFamily="Pretendard"
                  fontWeight="700"
                  whiteSpace={'break-word'}
                >
                  {getMessage(country, 'str_redeem_ok_confirm')}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Modal>
      )}
    </>
  );
};
