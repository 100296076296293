import { Typography, Box } from '@mui/material';

const Introduction = () => {
  return (
    <Box>
      <Typography variant="h3">eSIMSTA</Typography>
      <Typography variant="h5">eSIM Wholesale Platform</Typography>
      <Box display="flex" flexDirection={'column'} gap={2}>
        <Typography variant="h5" marginTop={10}>
          All the eSIMs from around the world are here.
        </Typography>
        <Typography variant="h5">
          We supply the eSIM you desire anytime, anywhere.
        </Typography>
        <Typography
          variant="h5"
          style={{
            fontWeight: 'bold',
          }}
        >
          This is the eSIM station.
        </Typography>
      </Box>
    </Box>
  );
};

export default Introduction;
