import * as React from 'react';
import TextInput from '@components/common/TextInput';
import Select, { SelectValue } from '@components/common/Select';
import { Box } from '@mui/material';
import { CloseOutlined } from '@mui/icons-material';
import Label from '@components/common/Label';
import IconButton from '@components/common/IconButton';
import CheckBox from '@components/common/CheckBox';
import Button from '@components/common/Button';
import { StockStatus } from '@models/stock';
import {
  StockResponse,
  StockSupplierTaskType,
  StockUpsertRequest,
  editStock,
} from '@apis/stock';
import { alertMissingValues, dropEmptyValues } from '@utils/common';
import { useRegionList } from '@queries/regions/useRegionList';
import useAsyncEffect from '@hooks/useAsyncEffect';
import { useAllSupplierList } from '@queries/suppliers/useAllSupplierList';
import { ESIM_TYPE_OPTION_LIST } from '@constants/stocks';

type EditStockCodeModalContentProps = {
  onClose: (refetch: boolean) => void;
  selected: StockResponse;
};

const EditStockCodeModalContent = ({
  onClose,
  selected,
}: EditStockCodeModalContentProps) => {
  const [form, setForm] = React.useState<StockUpsertRequest>({
    stockCode: selected.stockCode,
    stockName: selected.stockName,
    regionId: selected.region.regionId,
    supplierId: selected.supplier.supplierId,
    isUsingApi: selected.supplierTaskType === StockSupplierTaskType.API,
    status: selected.status,
    esimType: selected.esimType,
    topUpValidityDays: selected.topUpValidityDays,
  });

  const [regionOptionList, setRegionOptionList] = React.useState<
    { value: SelectValue; label: string }[]
  >([]);
  const [supplierOptionList, setSupplierOptionList] = React.useState<
    { value: SelectValue; label: string }[]
  >([]);

  const { data: regionsData } = useRegionList();
  const { data: suppliersData } = useAllSupplierList();

  const handleChangeInput = (
    name: 'stockName' | 'topUpValidityDays',
    value: string | number | undefined
  ) => {
    setForm((prev) => ({ ...prev, [name]: value }));
  };

  const handleChangeSelect = (
    name: 'regionId' | 'supplierId' | 'esimType',
    value: SelectValue
  ) => {
    setForm((prev) => ({ ...prev, [name]: value }));
  };

  const handleChangeCheckBox = (name: 'isUsingApi', value: boolean) => {
    setForm((prev) => ({ ...prev, [name]: value }));
  };

  const handleChangeActive = (value: boolean) => {
    setForm((prev) => ({
      ...prev,
      status: value ? StockStatus.ACTIVE : StockStatus.INACTIVE,
    }));
  };

  const handleOnEdit = async () => {
    //validation
    const _form = dropEmptyValues(form, ['topUpValidityDays']);
    const hasMissingValues = alertMissingValues(_form);

    if (hasMissingValues) return;

    await editStock(selected.stockId, _form);

    onClose(true);
  };

  const handleOnDelete = async () => {
    if (!!window.confirm('Are you sure to delete this stock?')) {
      await editStock(selected.stockId, {
        ...form,
        isDeleted: true,
        supplierId: selected.supplier.supplierId,
      });
    }

    onClose(true);
  };

  useAsyncEffect(async () => {
    if (!regionsData) return;
    setRegionOptionList(
      regionsData?.data.map((c) => ({
        label: c.regionCode,
        value: c.regionId,
      }))
    );
  }, [regionsData]);

  useAsyncEffect(async () => {
    if (!suppliersData) return;
    setSupplierOptionList(
      suppliersData?.data.map((s) => ({
        label: s.supplierName + ' (' + s.supplierCode + ')',
        value: s.supplierId,
      }))
    );
  }, [suppliersData]);

  return (
    <Box display={'flex'} flexDirection={'column'}>
      <Box
        display="flex"
        width={'100%'}
        flexDirection="row"
        justifyContent={'space-between'}
      >
        <Box display={'flex'}>
          <Label>{selected.stockCode}</Label>
        </Box>
        <IconButton onClick={() => onClose(false)}>
          <CloseOutlined />
        </IconButton>
      </Box>
      <TextInput
        label={'Stock Name'}
        value={form.stockName}
        onChange={(e) => handleChangeInput('stockName', e.target.value)}
      />
      <Select
        label={'Region'}
        optionList={regionOptionList}
        value={form.regionId}
        setValue={(v) => {
          if (!v) handleChangeSelect('regionId', null);
          else handleChangeSelect('regionId', v);
        }}
        placeholder="Select the region"
      />
      <Select
        label={'Esim Type'}
        optionList={ESIM_TYPE_OPTION_LIST}
        value={form.esimType}
        setValue={(v) => handleChangeSelect('esimType', v)}
        placeholder="Select the esim type"
      />
      <Select
        label={'Supplier'}
        optionList={supplierOptionList}
        value={form.supplierId}
        setValue={(v) => {
          if (!v) handleChangeSelect('supplierId', null);
          else handleChangeSelect('supplierId', v);
        }}
        placeholder="Select the supplier"
      />
      <Box display={'flex'} alignItems={'center'} mt={2} gap={2}>
        <CheckBox
          label={'Use the API'}
          value={form.isUsingApi}
          onCheck={(checked) => {
            handleChangeCheckBox('isUsingApi', checked);
            if (!checked) handleChangeInput('topUpValidityDays', undefined);
          }}
        />
        <CheckBox
          label={'Active'}
          value={form.status === 'ACTIVE'}
          onCheck={handleChangeActive}
        />
      </Box>
      {!!form.isUsingApi && (
        <TextInput
          label={'Top Up Validity Days'}
          value={form.topUpValidityDays || 0}
          onChange={(e) => {
            console.log(e.target.value);
            if (!Number(e.target.value))
              handleChangeInput('topUpValidityDays', undefined);
            else handleChangeInput('topUpValidityDays', Number(e.target.value));
          }}
          placeholder="Enter the top up validity days"
          type="number"
        />
      )}
      <Box display={'flex'} mt={2} justifyContent={'space-between'}>
        <Button text={'DELETE'} color="error" onClick={handleOnDelete} />
        <Button text={'OK'} onClick={handleOnEdit} />
      </Box>
    </Box>
  );
};

export default EditStockCodeModalContent;
