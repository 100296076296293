import { Partner } from './partner';
import { ProductBase } from './product';

export enum OrderStatus {
  OK = 'OK',
  FAILED = 'FAILED',
  PARTIAL_REFUNDED = 'PARTIAL_REFUNDED',
  FULL_REFUNDED = 'FULL_REFUNDED',
}

export enum OrderEventType {
  PROCESSED = 'PROCESSED',
  PARTIAL_REFUND = 'PARTIAL_REFUND',
  USER_MEMO = 'USER_MEMO',
  FULL_REFUND = 'FULL_REFUND',
}

export type OrderProductInfo = {
  quantity: number;
  amount: number;
} & ProductBase;

export type Order = {
  id: string;
  name: string;
  product: OrderProductInfo; // order 하나당 product는 무조건 하나
  amount: number;
  cretaedAt: string;
  status: OrderStatus;
  partner: Partner;
};

export type OrderInfo = {
  refundQuantity: number; // TODO 나중에 info 안으로 넣어야 할 수도 있음
  refundAmount: number; // TODO 나중에 info 안으로 넣어야 할 수도 있음
  memoList: OrderMemo[];
} & Order;

// History 영역의 메모
export type OrderMemo = {
  id: number;
  event: string;
  owner: string;
  description: string;
  orderId: string;
  createdAt: string;
};

export type RefundForm = {
  refundFee: number;
};
