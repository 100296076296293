import { signIn, refreshToken } from '@apis/auth';
import { TOKEN_KEY, REFRESH_TOKEN_KEY } from '@constants/token';
import { authState } from '@recoils/atoms';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';

function useAuth() {
  const [loading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const [isAuthenticated, setIsAuthenticated] = useRecoilState<null | boolean>(
    authState
  );

  const login = async (user: { username: string; password: string }) => {
    try {
      setIsLoading(true);
      const { data } = await signIn(user);
      window.localStorage.setItem(TOKEN_KEY, data[0].accessToken);
      window.localStorage.setItem(REFRESH_TOKEN_KEY, data[0].refreshToken);
      setIsAuthenticated(true);
    } catch (error) {
      window.alert('Login failed\nPlease check your email or password again.');
    } finally {
      setIsLoading(false);
    }
  };

  const refresh = async () => {
    const token = window.localStorage.getItem(REFRESH_TOKEN_KEY);
    if (token) {
      const { data } = await refreshToken(token);
      window.localStorage.setItem(TOKEN_KEY, data[0]);
    }
  };

  const logOut = () => {
    window.localStorage.removeItem(TOKEN_KEY);
    window.localStorage.removeItem(REFRESH_TOKEN_KEY);
    setIsAuthenticated(false);
    return navigate('/login');
  };

  useEffect(() => {
    setIsAuthenticated(!!window.localStorage.getItem(TOKEN_KEY));
  }, [setIsAuthenticated]);

  return {
    loading,
    isAuthenticated,
    login,
    logOut,
    refresh,
  };
}

export default useAuth;
