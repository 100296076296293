import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { PageResponse } from '@models/api';
import { ProductResponse, getProduct } from '@apis/product';

export const useProductDetail = (productId: number) => {
  const queryOptions: UseQueryOptions<PageResponse<ProductResponse>, Error> = {
    queryKey: ['productDetail', productId],
    queryFn: () => {
      return getProduct(productId);
    },
    refetchOnMount: 'always',
  };
  return useQuery(queryOptions);
};
