import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { PageResponse } from '@models/api';
import {
  GetUpdateDetailsResponse,
  getUpdateHistoryDetails,
  GetUpdateHistoryDetailsParams,
} from '@apis/esimBulk/update';

export const useUpdateDetails = ({
  page,
  pageSize,
  updateId,
  params,
}: {
  page: number;
  pageSize: number;
  updateId: number;
  params: GetUpdateHistoryDetailsParams;
}) => {
  const queryOptions: UseQueryOptions<
    PageResponse<GetUpdateDetailsResponse>,
    Error
  > = {
    queryKey: ['updateHistoryDetails', updateId, !!params.onlyFailed],
    queryFn: () => {
      return getUpdateHistoryDetails(updateId, {
        pageNo: page,
        pageSize: pageSize,
        ...params,
      });
    },
  };
  return useQuery(queryOptions);
};
