import Button from '@components/common/Button';
import TextInput from '@components/common/TextInput';
import { Partner, PartnerProductPriceResponse } from '@models/partner';
import {
  Box,
  Pagination,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Divider } from '@material-ui/core';
import Modal from '@components/common/Modal';
import AddRemoteIpContent from '@components/partner/AddRemoteIpContent';
import useAsyncEffect from '@hooks/useAsyncEffect';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { usePartnerDetail } from '@queries/partners/usePartnerDetail';
import {
  exportActivePartnerPricesExcel,
  updatePartner,
  updatePartnerProductPrice,
} from '@apis/partner';
import { toUsdDisplay } from '../../utils/money';
import { toDisplayPartnerId } from './util';
import { useActivePartnerProductPrices } from '@queries/partners/useActivePartnerProductPrices';
import { useActivePartnerProductPriceCount } from '@queries/partners/useActivePartnerProductPriceCount';
import { GetProductsParams, ProductSaleType } from '../../apis/product';
import Select, { SelectValue } from '../../components/common/Select';
import { useCountryList } from '../../queries/countries/useCountryList';
import { filterSearchParams } from '../../utils/search';
import { utcToZonedTime } from 'date-fns-tz';
import { KOREAN_TIME_ZONE } from '../../constants/timezone';
import { downloadBlob } from '../../utils/file';
import { CheckBox } from '@components/common/CheckBox';
import { useRecoilValue } from 'recoil';
import { userState } from '../../recoils/atoms';
import { UserGroup } from '../../models/user';
import _ from 'lodash';
import SearchSelect from '../../components/common/SearchSelect';

type ProductSearchForm = GetProductsParams;
const PAGE_SIZE = 50;

const MyPartnerPriceList = () => {
  const user = useRecoilValue(userState);
  const partnerId = user?.group === UserGroup.PARTNER ? user.partnerId : 0;

  const [form, setForm] = React.useState<Partner>({
    name: '',
    partnerId: 0,
    managerUsername: '',
    managerPassword: '',
    managerEmail: '',
    managerName: '',
    address: '',
    balance: 0,
    baseDiscountRate: 0,
    remoteIps: [],
    appId: '',
    appSecret: '',
    createdAt: '',
    manualOrderEnabled: false,
  });
  const [addRemoteIp, setAddRemoteIp] = useState(false);
  const navigate = useNavigate();
  const { data: partnerDetailForm } = usePartnerDetail(Number(partnerId));
  const { data: countriesData } = useCountryList();
  const [countryOptionList, setCountryOptionList] = useState<
    { value: SelectValue; label: string }[]
  >([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchForm, setSearchForm] = useState<ProductSearchForm>({
    productName: searchParams.get('productName') || '',
    countryId: searchParams.get('countryId')
      ? Number(searchParams.get('countryId'))
      : undefined,
    productSaleType:
      (searchParams.get('productSaleType') as ProductSaleType) || undefined,
    stockId: undefined,
    productStatus: undefined,
  });

  useAsyncEffect(async () => {
    if (!countriesData) return;
    setCountryOptionList(
      _.sortBy(
        countriesData?.data.map((c) => ({
          label: c.englishName,
          value: c.countryId,
        })),
        (data) => data.label
      )
    );
  }, [countriesData]);

  const [needsRefetch, setNeedsRefetch] = useState(false);

  const handleChangeInput = (
    name: keyof ProductSearchForm,
    value: SelectValue
  ) => {
    setSearchForm((prev) => ({ ...prev, [name]: value }));
  };

  const [page, setPage] = useState(
    searchParams.get('page') ? Number(searchParams.get('page')) : 1
  );

  const {
    data: partnerProductPrices,
    refetch,
    isLoading,
  } = useActivePartnerProductPrices({
    partnerId: Number(partnerId),
    page: page,
    pageSize: PAGE_SIZE,
    searchParam: searchForm,
  });

  const [prices, setPrices] = useState<PartnerProductPriceResponse[]>([]);

  useEffect(() => {
    setPrices(partnerProductPrices?.data || []);
  }, [partnerProductPrices]);

  const {
    data: partnerProductPriceCount,
    refetch: refetchCount,
    isLoading: isLoadingCount,
  } = useActivePartnerProductPriceCount({
    partnerId: Number(partnerId),
    searchParam: searchForm,
  });

  const handleOnSearch = () => {
    setSearchParams({
      ...filterSearchParams(
        Object.fromEntries(new URLSearchParams(searchForm as any).entries())
      ),
    });
    refetch();
    refetchCount();
  };

  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPage(value);
  };

  useEffect(() => {
    if (!searchParams.size) {
      setSearchForm({});
      setPage(1);
      setNeedsRefetch(true);
    }
  }, [searchParams]);

  useAsyncEffect(async () => {
    if (!partnerId || !partnerDetailForm) return;
    setForm(partnerDetailForm?.data[0]);
  }, [partnerDetailForm, partnerId]);

  useEffect(() => {
    if (needsRefetch && !isLoading && !isLoadingCount) {
      refetch();
      refetchCount();
      setNeedsRefetch(false);
    }
  }, [needsRefetch, refetch, refetchCount, isLoading, isLoadingCount]);

  const handleOnExport = async () => {
    const nowInUTC = new Date();
    const kstDate = utcToZonedTime(nowInUTC, KOREAN_TIME_ZONE);
    const res = await exportActivePartnerPricesExcel(
      Number(partnerId) ?? 0,
      searchForm
    );
    const fileName = `partner-${
      form.name
    }-prices-${kstDate.toISOString()}.xlsx`;
    downloadBlob(res, fileName);
  };

  return (
    <>
      <Typography variant="h4" component="h4">
        {form.name} ({toDisplayPartnerId(form.partnerId)})
      </Typography>
      <Typography variant="h5" component="h5" marginTop={2}>
        {`Product Authorization and Pricing (${form.name})`}
      </Typography>
      <Box
        display={'flex'}
        justifyContent={'flex-end'}
        flexDirection={'column'}
        gap={3}
        marginTop={2}
        marginBottom={2}
      >
        <Box display={'flex'} alignItems={'center'} gap={2}>
          <Button text={'Download'} onClick={handleOnExport} />
        </Box>
      </Box>
      <Divider />
      <Box mt={2} mb={3} display={'flex'} alignItems={'flex-end'} gap={2}>
        <TextInput
          label={'Product Name'}
          value={searchForm.productName || ''}
          onChange={(e) => handleChangeInput('productName', e.target.value)}
          placeholder="Enter the product name"
        />
        <SearchSelect
          disablePortal
          label={'Country'}
          options={countryOptionList}
          placeholder="Enter the Country"
          value={
            countryOptionList.find((c) => c.value === searchForm.countryId) ||
            null
          }
          setValue={(value) => {
            handleChangeInput('countryId', value?.value || null);
          }}
        />
        <SearchSelect
          disablePortal
          label={'Sale Type'}
          options={Object.values(ProductSaleType).map((saleType) => ({
            label: saleType,
            value: saleType,
          }))}
          placeholder="Enter the Product Sale Type"
          value={
            Object.values(ProductSaleType)
              .map((saleType) => ({
                label: saleType,
                value: saleType,
              }))
              .find((option) => option.value === searchForm.productSaleType) ||
            null
          }
          setValue={(value) => {
            handleChangeInput('productSaleType', value?.value || null);
          }}
        />
        <Button text={'Search'} onClick={handleOnSearch} />
      </Box>
      <Box
        alignItems="center"
        justifyContent={'space-between'}
        display={'flex'}
        marginTop={2}
      ></Box>

      <TableContainer
        component={Paper}
        sx={{
          marginTop: 2,
        }}
      >
        <Table>
          <TableHead
            sx={{
              backgroundColor: '#f5f5f5',
            }}
          >
            <TableRow>
              <StyledTableCell>Product Code & Name</StyledTableCell>
              <StyledTableCell>Supply Price(USD)</StyledTableCell>
              <StyledTableCell>Product Sale Type</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {prices.map((price: PartnerProductPriceResponse) => (
              <StyledTableRow key={price.productCode}>
                <StyledTableCell>{`[${price.productCode}] ${price.productName}`}</StyledTableCell>
                <StyledTableCell>
                  {toUsdDisplay(
                    price.originalPrice *
                      (1 - (price.discountRate || form.baseDiscountRate))
                  )}
                </StyledTableCell>
                <StyledTableCell>{price.productSaleType}</StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {/* Pagination */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          mt: 2,
        }}
      >
        {`Total ${partnerProductPriceCount} items`}
        <Pagination
          count={Math.ceil((partnerProductPriceCount ?? 0) / PAGE_SIZE)}
          page={page}
          onChange={handleChangePage}
        />
      </Box>

      <Modal
        open={addRemoteIp}
        onClose={() => {
          setAddRemoteIp(false);
        }}
      >
        <AddRemoteIpContent
          onClose={() => {
            setAddRemoteIp(false);
          }}
          onAdd={(ip) => {
            setForm((prev) => ({
              ...prev,
              remoteIps: [...prev.remoteIps, ip],
            }));
            setAddRemoteIp(false);
          }}
        />
      </Modal>
    </>
  );
};

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#000',
    color: '#fff',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(() => ({
  backgroundColor: '#FFFFFF',
  '&:hover': {
    backgroundColor: '#F3F6F9',
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export default MyPartnerPriceList;
