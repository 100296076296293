import Button from '@components/common/Button';
import TextInput from '@components/common/TextInput';
import { Partner, PartnerProductPriceResponse } from '@models/partner';
import {
  Box,
  Pagination,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Divider } from '@material-ui/core';
import Modal from '@components/common/Modal';
import AddRemoteIpContent from '@components/partner/AddRemoteIpContent';
import useAsyncEffect from '@hooks/useAsyncEffect';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { usePartnerDetail } from '@queries/partners/usePartnerDetail';
import {
  exportPartnerPricesExcel,
  updatePartner,
  updatePartnerProductPrice,
} from '@apis/partner';
import { toDisplayPartnerId } from './util';
import { usePartnerProductPrices } from '@queries/partners/usePartnerProductPrices';
import { usePartnerProductPriceCount } from '@queries/partners/usePartnerProductPriceCount';
import { GetProductsParams, ProductSaleType } from '../../apis/product';
import { ProductStatus } from '../../models/product';
import { SelectValue } from '../../components/common/Select';
import { useCountryList } from '../../queries/countries/useCountryList';
import { useAllStockList } from '../../queries/stocks/useAllStockList';
import { filterSearchParams } from '../../utils/search';
import { utcToZonedTime } from 'date-fns-tz';
import { KOREAN_TIME_ZONE } from '../../constants/timezone';
import { downloadBlob } from '../../utils/file';
import _ from 'lodash';
import SearchSelect from '../../components/common/SearchSelect';
import { Decimal } from 'decimal.js';
import { PartnerPriceRow } from './PartnerPriceRow';
type ProductSearchForm = GetProductsParams;
const PAGE_SIZE = 50;

const PartnerPriceList = () => {
  const { partnerId } = useParams();

  const [form, setForm] = React.useState<Partner>({
    name: '',
    partnerId: 0,
    managerUsername: '',
    managerPassword: '',
    managerEmail: '',
    managerName: '',
    address: '',
    balance: 0,
    baseDiscountRate: 0,
    remoteIps: [],
    appId: '',
    appSecret: '',
    createdAt: '',
    manualOrderEnabled: false,
  });
  const [baseDiscountRate, setBaseDiscountRate] = useState<string>(
    form.baseDiscountRate.toString()
  );
  const [addRemoteIp, setAddRemoteIp] = useState(false);
  const navigate = useNavigate();
  const { data: partnerDetailForm } = usePartnerDetail(Number(partnerId));
  const { data: countriesData } = useCountryList();
  const { data: stocksData } = useAllStockList();
  const [countryOptionList, setCountryOptionList] = useState<
    { value: SelectValue; label: string }[]
  >([]);
  const [stockOptionList, setStockOptionList] = useState<
    { value: SelectValue; label: string }[]
  >([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchForm, setSearchForm] = useState<ProductSearchForm>({
    productName: searchParams.get('productName') || '',
    countryId: searchParams.get('countryId')
      ? Number(searchParams.get('countryId'))
      : undefined,
    stockId: searchParams.get('stockId')
      ? Number(searchParams.get('stockId'))
      : undefined,
    productStatus:
      (searchParams.get('productStatus') as ProductStatus) || undefined,
  });

  useAsyncEffect(async () => {
    if (!countriesData) return;
    setCountryOptionList(
      _.sortBy(
        countriesData?.data.map((c) => ({
          label: c.englishName,
          value: c.countryId,
        })),
        (data) => data.label
      )
    );
  }, [countriesData]);

  useAsyncEffect(async () => {
    if (!stocksData) return;
    setStockOptionList(
      stocksData?.data.map((s) => ({
        label: s.stockCode,
        value: s.stockId,
      }))
    );
  }, [stocksData]);
  const [needsRefetch, setNeedsRefetch] = useState(false);

  const handleChangeInput = (
    name: keyof ProductSearchForm,
    value: SelectValue
  ) => {
    setSearchForm((prev) => ({ ...prev, [name]: value }));
  };

  const [page, setPage] = useState(
    searchParams.get('page') ? Number(searchParams.get('page')) : 1
  );

  const {
    data: partnerProductPrices,
    refetch,
    isLoading,
  } = usePartnerProductPrices({
    partnerId: Number(partnerId),
    page: page,
    pageSize: PAGE_SIZE,
    searchParam: searchForm,
  });

  const [prices, setPrices] = useState<PartnerProductPriceResponse[]>([]);

  useEffect(() => {
    setPrices(partnerProductPrices?.data || []);
  }, [partnerProductPrices]);

  const {
    data: partnerProductPriceCount,
    refetch: refetchCount,
    isLoading: isLoadingCount,
  } = usePartnerProductPriceCount({
    partnerId: Number(partnerId),
    searchParam: searchForm,
  });

  const handleOnSearch = () => {
    setSearchParams({
      ...filterSearchParams(
        Object.fromEntries(new URLSearchParams(searchForm as any).entries())
      ),
    });
    refetch();
    refetchCount();
  };

  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPage(value);
    setSearchParams({
      ...filterSearchParams(
        Object.fromEntries(new URLSearchParams(searchForm as any).entries())
      ),
      page: value.toString(),
    });
  };

  const applyBaseDiscountRate = async () => {
    await updatePartner(Number(partnerId), form);
    await refetch();
  };

  useAsyncEffect(async () => {
    if (!partnerId || !partnerDetailForm) return;
    setForm(partnerDetailForm?.data[0]);
    setBaseDiscountRate(
      (100 * (partnerDetailForm?.data[0].baseDiscountRate ?? 0)).toString()
    );
  }, [partnerDetailForm, partnerId]);

  useEffect(() => {
    if (needsRefetch && !isLoading && !isLoadingCount) {
      refetch();
      refetchCount();
      setNeedsRefetch(false);
    }
  }, [needsRefetch, refetch, refetchCount, isLoading, isLoadingCount]);

  const handleOnExport = async () => {
    const nowInUTC = new Date();
    const kstDate = utcToZonedTime(nowInUTC, KOREAN_TIME_ZONE);
    const res = await exportPartnerPricesExcel(
      Number(partnerId) ?? 0,
      searchForm
    );
    const fileName = `partner-${
      form.name
    }-prices-${kstDate.toISOString()}.xlsx`;
    downloadBlob(res, fileName);
  };

  const savePrice = async (productId: number) => {
    const price = prices.find((p) => p.productId === productId);
    if (!price) {
      return;
    }
    await updatePartnerProductPrice(price);
  };

  return (
    <>
      <Typography variant="h4" component="h4">
        {form.name} ({toDisplayPartnerId(form.partnerId)})
      </Typography>
      <Typography variant="h5" component="h5" marginTop={2}>
        {`Product Authorization and Pricing (${form.name})`}
      </Typography>
      <Box
        display={'flex'}
        justifyContent={'flex-end'}
        flexDirection={'column'}
        gap={3}
        marginTop={2}
        marginBottom={2}
      >
        <Box display={'flex'} alignItems={'center'} gap={2}>
          <TextInput
            label={'Base Discount Rate (%)'}
            type="number"
            value={baseDiscountRate}
            onChange={(e) => {
              if (e.target.value.length === 0 || e.target.value === '-') {
                setBaseDiscountRate(e.target.value);
                return;
              }
              const newValue = new Decimal(e.target.value)
                .mul(100)
                .floor()
                .div(100);
              if (newValue.toNumber() > 100) {
                return;
              }
              setForm({
                ...form,
                baseDiscountRate: newValue.div(100).toNumber(),
              });
              setBaseDiscountRate(newValue.toString());
            }}
            layout="horizontal"
            labelWidth={150}
            inputWidth={300}
          />
          <Button text={'APPLY'} onClick={applyBaseDiscountRate} />
          <Typography marginLeft="100px">Batch Apply</Typography>
          <Button text={'Download'} onClick={handleOnExport} />
          <Button
            text={'Upload'}
            onClick={() => navigate(`/partners/${partnerId}/prices/upload`)}
          />
        </Box>
      </Box>
      <Divider />
      <Box mt={2} mb={3} display={'flex'} alignItems={'flex-end'} gap={2}>
        <TextInput
          label={'Product Name'}
          value={searchForm.productName || ''}
          onChange={(e) => handleChangeInput('productName', e.target.value)}
          placeholder="Enter the product name"
        />
        <SearchSelect
          disablePortal
          label={'Country'}
          options={countryOptionList}
          placeholder="Enter the Country"
          value={
            countryOptionList.find((c) => c.value === searchForm.countryId) ||
            null
          }
          setValue={(value) => {
            handleChangeInput('countryId', value?.value || null);
          }}
        />
        <SearchSelect
          disablePortal
          label={'Sale Type'}
          options={Object.values(ProductSaleType).map((saleType) => ({
            label: saleType,
            value: saleType,
          }))}
          placeholder="Enter the Product Sale Type"
          value={
            Object.values(ProductSaleType)
              .map((saleType) => ({
                label: saleType,
                value: saleType,
              }))
              .find((option) => option.value === searchForm.productSaleType) ||
            null
          }
          setValue={(value) => {
            handleChangeInput('productSaleType', value?.value || null);
          }}
        />
        <SearchSelect
          disablePortal
          label={'Stock Code'}
          options={stockOptionList}
          placeholder="Enter the Stock Code"
          value={
            stockOptionList.find((c) => c.value === searchForm.stockId) || null
          }
          setValue={(value) =>
            handleChangeInput('stockId', value?.value || null)
          }
        />
        <SearchSelect
          disablePortal
          label={'State'}
          options={Object.values(ProductStatus).map((s) => ({
            label: s,
            value: s,
          }))}
          placeholder="Enter the state"
          value={
            Object.values(ProductStatus)
              .map((s) => ({
                label: s,
                value: s,
              }))
              .find(
                (s) =>
                  s.value ===
                  (searchParams.get('productStatus') ||
                    searchForm.productStatus)
              ) || null
          }
          setValue={(value) =>
            handleChangeInput('productStatus', value?.value || null)
          }
        />

        <Button text={'Search'} onClick={handleOnSearch} />
      </Box>
      <Box
        alignItems="center"
        justifyContent={'space-between'}
        display={'flex'}
        marginTop={2}
      ></Box>

      <TableContainer
        component={Paper}
        sx={{
          marginTop: 2,
        }}
      >
        <Table>
          <TableHead
            sx={{
              backgroundColor: '#f5f5f5',
            }}
          >
            <TableRow>
              <StyledTableCell>Product Code & Name</StyledTableCell>
              <StyledTableCell>Original Price(USD)</StyledTableCell>
              <StyledTableCell>Supply Price(USD)</StyledTableCell>
              <StyledTableCell>Discount Rate (%)</StyledTableCell>
              <StyledTableCell>Visibility</StyledTableCell>
              <StyledTableCell>Product Sale Type</StyledTableCell>
              <StyledTableCell>Product Status</StyledTableCell>
              <StyledTableCell>Stock Code</StyledTableCell>
              <StyledTableCell>Apply</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {prices.map((price: PartnerProductPriceResponse) => (
              <PartnerPriceRow
                baseDiscountRate={form.baseDiscountRate}
                price={price}
                setPrice={(price: PartnerProductPriceResponse) => {
                  setPrices((prev) =>
                    prev.map((p) => {
                      if (p.productId === price.productId) {
                        return price;
                      }
                      return p;
                    })
                  );
                }}
                applyPrice={savePrice}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {/* Pagination */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          mt: 2,
        }}
      >
        {`Total ${partnerProductPriceCount} items`}
        <Pagination
          count={Math.ceil((partnerProductPriceCount ?? 0) / PAGE_SIZE)}
          page={page}
          onChange={handleChangePage}
        />
      </Box>

      <Modal
        open={addRemoteIp}
        onClose={() => {
          setAddRemoteIp(false);
        }}
      >
        <AddRemoteIpContent
          onClose={() => {
            setAddRemoteIp(false);
          }}
          onAdd={(ip) => {
            setForm((prev) => ({
              ...prev,
              remoteIps: [...prev.remoteIps, ip],
            }));
            setAddRemoteIp(false);
          }}
        />
      </Modal>
    </>
  );
};

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#000',
    color: '#fff',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

export default PartnerPriceList;
