import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { getPartnersCount } from '@apis/partner';
import { PartnersSearchForm } from '@models/partner';
import { dropEmptyValues } from '../../utils/common';

export const usePartnerCount = (searchParam: PartnersSearchForm) => {
  const queryOptions: UseQueryOptions<number, Error> = {
    queryKey: ['partnersCount'],
    queryFn: () => {
      return getPartnersCount(
        dropEmptyValues(searchParam, ['name', 'manager', 'email', 'address'])
      );
    },
  };
  return useQuery(queryOptions);
};
