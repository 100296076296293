import Button from '@components/common/Button';
import TextInput from '@components/common/TextInput';
import { PartnersSearchForm } from '@models/partner';
import {
  Box,
  CircularProgress,
  Divider,
  Pagination,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { usePartnerList } from '@queries/partners/usePartnerList';
import { timestampToDate } from '@utils/time';
import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toUsdDisplay } from '../../utils/money';
import { toDisplayPartnerId } from './util';
import { usePartnerCount } from '../../queries/partners/usePartnerCount';
import { filterSearchParams } from '@utils/search';

const PAGE_SIZE = 50;
const Partners = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchForm, setSearchForm] = useState<PartnersSearchForm>({
    name: searchParams.get('name') || '',
    manager: searchParams.get('manager') || '',
    email: searchParams.get('email') || '',
    address: searchParams.get('address') || '',
  });
  const [page, setPage] = useState(
    searchParams.get('page') ? Number(searchParams.get('page')) : 1
  );
  const [needsRefetch, setNeedsRefetch] = useState(false);
  const { data, refetch, isLoading } = usePartnerList({
    page: page,
    pageSize: PAGE_SIZE,
    searchParam: searchForm,
  });
  const {
    data: totalCount,
    refetch: refetchCount,
    isLoading: isLoadingCount,
  } = usePartnerCount(searchForm);

  const navigate = useNavigate();
  const handleOnAdd = () => {
    navigate('/partners/add');
  };

  const handleChangePage = (
    _event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPage(value);
    setSearchParams({
      ...filterSearchParams(
        Object.fromEntries(new URLSearchParams(searchForm as any).entries())
      ),
      page: value.toString(),
    });
  };

  const handleOnSearch = () => {
    refetch();
    refetchCount();

    setSearchParams({
      ...filterSearchParams(
        Object.fromEntries(new URLSearchParams(searchForm as any).entries())
      ),
    });
  };

  const handleChangeInput = (name: keyof PartnersSearchForm, value: string) => {
    setSearchForm((prev) => ({ ...prev, [name]: value }));
  };

  useEffect(() => {
    if (!searchParams.size) {
      setSearchForm({});
      setPage(1);
      setNeedsRefetch(true);
    }
  }, [searchParams]);

  useEffect(() => {
    if (needsRefetch && !isLoading && !isLoadingCount) {
      refetch();
      refetchCount();
      setNeedsRefetch(false);
    }
  }, [needsRefetch, refetch, refetchCount, isLoading, isLoadingCount]);

  return (
    <>
      <Typography variant="h4" component="h4">
        Partners
      </Typography>
      <Box display={'flex'} justifyContent={'flex-end'}>
        <Button text={'Add'} onClick={handleOnAdd} />
      </Box>
      {/* Search Section */}
      <Box mt={2} mb={3} gap={2} display={'flex'} alignItems={'flex-end'}>
        <TextInput
          label={'Company'}
          value={searchForm.name || ''}
          onChange={(e) => handleChangeInput('name', e.target.value)}
          placeholder="Enter Company Name"
        />
        <TextInput
          label={'Manager'}
          value={searchForm.manager || ''}
          onChange={(e) => handleChangeInput('manager', e.target.value)}
          placeholder="Enter Manager Name"
        />
        <TextInput
          label={'E-mail'}
          value={searchForm.email || ''}
          onChange={(e) => handleChangeInput('email', e.target.value)}
          placeholder="Enter E-mail"
        />
        <TextInput
          label={'Address'}
          value={searchForm.address || ''}
          onChange={(e) => handleChangeInput('address', e.target.value)}
          placeholder="Enter Address"
        />
        <Button text={'Search'} onClick={handleOnSearch} />
      </Box>
      <Divider
        sx={{
          mb: 2,
          mt: 2,
        }}
      />
      {/* List Section */}
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Index</StyledTableCell>
              <StyledTableCell>Partner ID</StyledTableCell>
              <StyledTableCell>Copmpany Name</StyledTableCell>
              <StyledTableCell>Manager Name</StyledTableCell>
              <StyledTableCell>E-mail</StyledTableCell>
              <StyledTableCell>Telephone</StyledTableCell>
              <StyledTableCell>Address</StyledTableCell>
              <StyledTableCell>Balance(USD)</StyledTableCell>
              <StyledTableCell>Created</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading && <CircularProgress />}
            {data?.data.map((row, index) => (
              <StyledTableRow
                key={row.partnerId}
                onClick={() => {
                  navigate(`/partners/${row.partnerId}`);
                }}
              >
                <StyledTableCell component="th" scope="row">
                  {index + 1}
                </StyledTableCell>
                <StyledTableCell>
                  {toDisplayPartnerId(row.partnerId)}
                </StyledTableCell>
                <StyledTableCell>{row.name}</StyledTableCell>
                <StyledTableCell>{row.managerName}</StyledTableCell>
                <StyledTableCell>{row.managerEmail}</StyledTableCell>
                <StyledTableCell>{row.managerTelephone}</StyledTableCell>
                <StyledTableCell>{row.address}</StyledTableCell>
                <StyledTableCell>{toUsdDisplay(row.balance)}</StyledTableCell>
                <StyledTableCell>
                  {timestampToDate(row.createdAt)}
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {/* Pagination */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          mt: 2,
        }}
      >
        <Pagination
          count={Math.ceil((totalCount ?? 0) / PAGE_SIZE)}
          page={page}
          onChange={handleChangePage}
        />
      </Box>
    </>
  );
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:hover': {
    cursor: 'pointer',
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export default Partners;
