import { changePassword } from '@apis/users';
import Button from '@components/common/Button';
import TextInput from '@components/common/TextInput';
import { Box, Typography } from '@mui/material';
import { useState } from 'react';
import Modal from '@components/common/Modal';
import {
  CheckOutlined,
  CloseOutlined,
  ErrorOutline,
} from '@mui/icons-material';
import { useRecoilValue } from 'recoil';
import { userState } from '@recoils/atoms';
import { useNavigate } from 'react-router-dom';
import IconButton from '@components/common/IconButton';
import Label from '@components/common/Label';

type ChangePasswordContentProps = {
  onClose: () => void;
};

const ChangePasswordContent = ({ onClose }: ChangePasswordContentProps) => {
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const user = useRecoilValue(userState);
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [success, setSuccess] = useState(false);

  const handleConfirm = async () => {
    if (!user) return;
    if (newPassword !== confirmPassword) {
      setErrorMessage('New password and confirm password are not matched.');
      setError(true);
      return;
    }

    try {
      await changePassword(Number(user?.userId), {
        username: user?.username,
        oldPassword: currentPassword,
        newPassword,
      });
      alert('Password changed successfully');
      return onClose();
    } catch (e) {
      setErrorMessage('Something went wrong. Please try again later.');
      setError(true);
      return;
    }
  };

  return (
    <>
      <Box
        display="flex"
        flexDirection={'column'}
        alignItems={'center'}
        justifyContent={'center'}
      >
        <Box
          display="flex"
          width={'100%'}
          flexDirection="row"
          justifyContent={'space-between'}
        >
          <Box display={'flex'}>
            <Label>Change Password</Label>
          </Box>
          <IconButton onClick={onClose}>
            <CloseOutlined />
          </IconButton>
        </Box>
        <Box display="flex" flexDirection={'row'} gap={20}>
          <Box>
            <Box>
              <TextInput
                label={'Username'}
                value={user?.username || ''}
                inputWidth={400}
                disabled
              />
              <TextInput
                label={'Current Password'}
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value)}
                type="password"
                inputWidth={400}
              />
              <TextInput
                label={'New Password'}
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                type="password"
                inputWidth={400}
              />
              <TextInput
                label={'Confirm Password'}
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                type="password"
                inputWidth={400}
              />
              <Box mt={2} display={'flex'} justifyContent={'flex-end'}>
                <Button
                  color="primary"
                  onClick={handleConfirm}
                  text={'Change Password'}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Modal
        open={success}
        onClose={() => {
          setSuccess(false);
        }}
      >
        <Box display={'flex'} flexDirection={'row'} gap={2}>
          <CheckOutlined color={'success'} />
          <Typography textAlign={'center'}>
            A temporary password has been sent to your email.
          </Typography>
        </Box>
        <Box display={'flex'} justifyContent={'flex-end'}>
          <Button
            color="primary"
            onClick={() => {
              setSuccess(false);
              navigate('/login');
            }}
            text={'OK'}
          />
        </Box>
      </Modal>
      <Modal
        open={error}
        onClose={() => {
          setSuccess(false);
        }}
      >
        <Box display={'flex'} flexDirection={'row'} gap={2}>
          <ErrorOutline color={'error'} />
          <Typography textAlign={'center'}>{errorMessage}</Typography>
        </Box>
        <Box display={'flex'} justifyContent={'flex-end'}>
          <Button
            color="primary"
            onClick={() => {
              setError(false);
            }}
            text={'OK'}
          />
        </Box>
      </Modal>
    </>
  );
};

export default ChangePasswordContent;
