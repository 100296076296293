import { Button as BaseButton, buttonClasses } from '@mui/base/Button';
import { styled } from '@mui/system';
import Stack from '@mui/material/Stack';
import { ButtonBaseProps } from '@material-ui/core';

type ButtonProps = {
  text: string;
  color?: ButtonColorType;
} & ButtonBaseProps;

type ButtonColorType = 'primary' | 'secondary' | 'error' | 'gray';

const BUTTON_COLORS = {
  primary: '#007FFF',
  secondary: '#355E3B',
  error: '#FF4545',
  gray: '#434D5B',
};

const BUTTON_HOVER_COLORS = {
  primary: '#0072E5',
  secondary: '#2D4D2D',
  error: '#FF4545',
  gray: '#434D5B',
};

const Button = ({ text, color = 'primary', ...props }: ButtonProps) => {
  return (
    <Stack spacing={2}>
      <StyledButton {...props} color={color}>
        {text}
      </StyledButton>
    </Stack>
  );
};

const StyledButton = styled(BaseButton)(
  ({ color }: { color: ButtonColorType }) => `
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.5;
  background-color: ${BUTTON_COLORS[color]};
  padding: 8px 16px;
  border-radius: 8px;
  color: white;
  transition: all 150ms ease;
  cursor: pointer;
  border: 1px solid ${BUTTON_COLORS[color]};

  &:hover {
    background-color: ${BUTTON_HOVER_COLORS[color]};
  }

  &.${buttonClasses.active} {
    background-color: #0066CC;
    box-shadow: none;
    transform: scale(0.99);
  }

  &.${buttonClasses.focusVisible} {
    box-shadow: 0 0 0 4px #99CCFF;
    outline: none;
  }

  &.${buttonClasses.disabled} {
    background-color: #DAE2ED;
    color: #434D5B;
    border: 0;
    cursor: default;
    box-shadow: none;
    transform: scale(1);
  }
  `
);

export default Button;
