import * as React from 'react';
import TextInput from '@components/common/TextInput';
import { Box } from '@mui/material';
import { CloseOutlined } from '@mui/icons-material';
import Label from '@components/common/Label';
import IconButton from '@components/common/IconButton';
import Button from '@components/common/Button';

type AddRemoteIpContentProps = {
  onClose: () => void;
  onAdd: (ip: string) => void;
};

const AddRemoteIpContent = ({ onClose, onAdd }: AddRemoteIpContentProps) => {
  const [ip, setIp] = React.useState<string>('');

  const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setIp(value);
  };

  const handleOnAdd = () => {
    onAdd(ip);
  };

  return (
    <Box display={'flex'} flexDirection={'column'}>
      <Box
        display="flex"
        width={'100%'}
        flexDirection="row"
        justifyContent={'space-between'}
      >
        <Box display={'flex'}>
          <Label>Add Remote IP</Label>
        </Box>
        <IconButton onClick={onClose}>
          <CloseOutlined />
        </IconButton>
      </Box>
      <Box display="flex" width={'100%'} flexDirection="column" gap={2}>
        <TextInput
          label={'ip'}
          value={ip}
          onChange={handleChangeInput}
          placeholder="Enter the IP"
          autoFocus
        />
      </Box>

      <Box display={'flex'} justifyContent={'flex-end'} mt={3}>
        <Button text={'ADD'} onClick={handleOnAdd} />
      </Box>
    </Box>
  );
};

export default AddRemoteIpContent;
