import * as React from 'react';
import TextInput from '@components/common/TextInput';
import Select, { SelectValue } from '@components/common/Select';
import { Box } from '@mui/material';
import { CheckCircleOutline, CloseOutlined } from '@mui/icons-material';
import Label from '@components/common/Label';
import IconButton from '@components/common/IconButton';
import CheckBox from '@components/common/CheckBox';
import Button from '@components/common/Button';
import { EsimType, StockStatus } from '@models/stock';
import {
  StockUpsertRequest,
  createStock,
  getStockByStockCode,
} from '@apis/stock';
import { useRegionList } from '@queries/regions/useRegionList';
import useAsyncEffect from '@hooks/useAsyncEffect';
import { useAllSupplierList } from '@queries/suppliers/useAllSupplierList';
import { ESIM_TYPE_OPTION_LIST } from '@constants/stocks';
import { alertMissingValues, dropEmptyValues } from '@utils/common';

type AddStockCodeModalContentProps = {
  onClose: (refetch: boolean) => void;
};

type StockCodeCheckStatus = 'NOT_CHECKED' | 'DUPLICATED' | 'OK';

const AddStockCodeModalContent = ({
  onClose,
}: AddStockCodeModalContentProps) => {
  const [form, setForm] = React.useState<StockUpsertRequest>({
    stockCode: '',
    stockName: '',
    regionId: 0,
    supplierId: 0,
    isUsingApi: false,
    status: StockStatus.ACTIVE,
    esimType: EsimType.LPA,
  });

  const [stockCodeStatus, setStockCodeStatus] =
    React.useState<StockCodeCheckStatus>('NOT_CHECKED');
  const [regionOptionList, setRegionOptionList] = React.useState<
    { value: SelectValue; label: string }[]
  >([]);
  const [supplierOptionList, setSupplierOptionList] = React.useState<
    { value: SelectValue; label: string }[]
  >([]);
  const { data: regionsData } = useRegionList();
  const { data: suppliersData } = useAllSupplierList();

  const handleChangeInput = (
    name: 'stockName' | 'topUpValidityDays',
    value: string | number | undefined
  ) => {
    setForm((prev) => ({ ...prev, [name]: value }));
  };

  const onStockCodeChanged = (stockCode: string) => {
    setStockCodeStatus('NOT_CHECKED');
    setForm((prev) => ({ ...prev, stockCode }));
  };

  const handleChangeSelect = (
    name: 'supplierId' | 'regionId' | 'esimType',
    value: SelectValue
  ) => {
    setForm((prev) => ({ ...prev, [name]: value }));
  };

  const handleChangeCheckBox = (name: 'isUsingApi', value: boolean) => {
    setForm((prev) => ({ ...prev, [name]: value }));
  };

  const handleChangeActive = (value: boolean) => {
    setForm((prev) => ({
      ...prev,
      status: value ? StockStatus.ACTIVE : StockStatus.INACTIVE,
    }));
  };

  const handleCheckDuplicate = async () => {
    const { data } = await getStockByStockCode(form.stockCode);
    if (data.length === 0) {
      setStockCodeStatus('OK');
      alert('Stock Code is not duplicated!\nYou can use this stock code!');
    } else {
      setStockCodeStatus('DUPLICATED');
      alert('Stock Code is duplicated!\nPlease use another stock code!');
    }
  };

  const handleOnCreate = async () => {
    if (stockCodeStatus === 'NOT_CHECKED') {
      alert('Please check if the stock code is duplicated');
      return;
    } else if (stockCodeStatus === 'DUPLICATED') {
      alert('stock code is duplicated');
      return;
    }

    // validate
    const _form = dropEmptyValues(form, ['topUpValidityDays']);

    const hasMissingValues = alertMissingValues(_form);

    if (hasMissingValues) return;

    await createStock(_form);
    onClose(true);
  };

  useAsyncEffect(async () => {
    if (!regionsData) return;
    setRegionOptionList(
      regionsData?.data.map((r) => ({
        label: r.regionCode,
        value: r.regionId,
      }))
    );
  }, [regionsData]);

  useAsyncEffect(async () => {
    if (!suppliersData) return;
    setSupplierOptionList(
      suppliersData?.data.map((s) => ({
        label: s.supplierName + ' (' + s.supplierCode + ')',
        value: s.supplierId,
      }))
    );
  }, [suppliersData]);

  return (
    <Box display={'flex'} flexDirection={'column'}>
      <Box
        display="flex"
        width={'100%'}
        flexDirection="row"
        justifyContent={'space-between'}
      >
        <Box display={'flex'}>
          <Label>New Stock Code</Label>
        </Box>
        <IconButton onClick={() => onClose(false)}>
          <CloseOutlined />
        </IconButton>
      </Box>
      <Box
        display={'flex'}
        alignItems={'flex-end'}
        justifyContent={'space-between'}
      >
        <TextInput
          label={'Stock Code'}
          value={form.stockCode}
          onChange={(e) => onStockCodeChanged(e.target.value)}
          inputWidth={320}
          placeholder="Enter the code and check for duplicates"
        />
        <IconButton onClick={handleCheckDuplicate}>
          <CheckCircleOutline />
        </IconButton>
      </Box>
      <TextInput
        label={'Stock Name'}
        value={form.stockName}
        onChange={(e) => handleChangeInput('stockName', e.target.value)}
        placeholder="Enter the stock name"
      />
      <Select
        label={'Region'}
        optionList={regionOptionList}
        value={form.regionId}
        setValue={(v) => handleChangeSelect('regionId', v)}
        placeholder="Select the region"
      />
      <Select
        label={'eSIM Type'}
        optionList={ESIM_TYPE_OPTION_LIST}
        value={form.esimType}
        setValue={(v) => handleChangeSelect('esimType', v)}
        placeholder="Select the type"
      />
      <Select
        label={'Supplier'}
        optionList={supplierOptionList}
        value={form.supplierId}
        setValue={(v) => handleChangeSelect('supplierId', v)}
        placeholder="Select the supplier"
      />
      <Box display={'flex'} alignItems={'center'} mt={2} gap={2}>
        <CheckBox
          label={'Use the API'}
          value={form.isUsingApi}
          onCheck={(checked) => {
            handleChangeCheckBox('isUsingApi', checked);
            if (!checked) handleChangeInput('topUpValidityDays', undefined);
          }}
        />
        <CheckBox
          label={'Active'}
          value={form.status === 'ACTIVE'}
          onCheck={handleChangeActive}
        />
      </Box>
      {!!form.isUsingApi && (
        <TextInput
          label={'Top Up Validity Days'}
          value={form.topUpValidityDays || 0}
          onChange={(e) => {
            if (!Number(e.target.value))
              handleChangeInput('topUpValidityDays', undefined);
            else handleChangeInput('topUpValidityDays', Number(e.target.value));
          }}
          placeholder="Enter the top up validity days"
          type="number"
        />
      )}
      <Box display={'flex'} mt={2} justifyContent={'flex-end'}>
        <Button text={'CREATE'} onClick={handleOnCreate} />
      </Box>
    </Box>
  );
};

export default AddStockCodeModalContent;
