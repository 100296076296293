import { refreshToken } from '@apis/auth';
import { getMe } from '@apis/users';
import { REFRESH_TOKEN_KEY, TOKEN_KEY } from '@constants/token';
import { redirect } from 'react-router-dom';

export const checkIfSignIn = async () => {
  try {
    const token = window.localStorage.getItem(TOKEN_KEY);
    const refresh = window.localStorage.getItem(REFRESH_TOKEN_KEY);
    if (!token) {
      return redirect('/login');
    }
    // 토큰 유효성 검사 시도
    try {
      const me = await getMe();
      return me;
    } catch (e) {
      console.log('Error during sign-in check:', e);
      if (refresh) {
        // If we receive a 403 error and have a refresh token, try to refresh
        try {
          const { data } = await refreshToken(refresh);
          if (data[0]) {
            window.localStorage.setItem(TOKEN_KEY, data[0]);
            return await getMe(); // Retry getMe() after refreshing the token
          } else {
            // If the token refresh didn't return data, redirect to login
            return redirect('/login');
          }
        } catch (refreshError) {
          // Handle potential errors from the refreshToken call
          return redirect('/login');
        }
      } else {
        // If there's another type of error or no refresh token, redirect to login
        return redirect('/login');
      }
    }
  } catch (error) {
    console.error('Error during sign-in check:', error);
    window.localStorage.removeItem(TOKEN_KEY);
    window.localStorage.removeItem(REFRESH_TOKEN_KEY);
    return redirect('/login');
  }
};
