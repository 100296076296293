import { User, UserGroup, UserResponse, UserStatus } from '@models/user';
import API from './API';
import { PageParams, PageResponse } from '@models/api';

export type SignUpRequest = {
  username: string;
  password: string;
  name: string;
  position: string;
  group: UserGroup;
  email: string;
  phoneNumber: string;
  status: UserStatus;
};

export type EditUserRequest = {
  userId: number;
  position: string;
  group: UserGroup;
  email: string;
  phoneNumber: string;
  status: UserStatus;
};

export type GetUsersParams = {
  username?: string;
  name?: string;
  group?: UserGroup;
  status?: UserStatus;
};

export type ChangePasswordRequest = {
  username: string;
  oldPassword: string;
  newPassword: string;
};

export type RecoverPasswordRequest = {
  username: string;
  email: string;
};

export const getMe = async () =>
  (await API.get<PageResponse<UserResponse>>('/v1/users/me')).data.data[0];

export const getUsersCount = async (params: GetUsersParams): Promise<number> =>
  (await API.get<PageResponse<number>>('/v1/users/count', { params: params }))
    .data.data[0] ?? 0;

export const getUsers = async (
  params: PageParams & GetUsersParams
): Promise<PageResponse<User>> =>
  (await API.get<PageResponse<User>>('/v1/users', { params: params })).data;

export const getUser = async (id: number): Promise<PageResponse<User>> =>
  (await API.get<PageResponse<User>>(`/v1/users/${id}`)).data;

export const getUserByUsername = async (
  username: string
): Promise<PageResponse<User>> =>
  (await API.get<PageResponse<User>>(`/v1/users/username/${username}`)).data;

export const getUserByEmail = async (
  email: string
): Promise<PageResponse<User>> =>
  (
    await API.get<PageResponse<User>>(`/v1/users/check-email`, {
      params: { email: email },
    })
  ).data;

export const signUp = async (
  data: SignUpRequest
): Promise<PageResponse<User>> =>
  (await API.post<PageResponse<User>>('/v1/users', data)).data;

export const updateUser = async (
  id: number,
  request: EditUserRequest
): Promise<PageResponse<User>> =>
  (await API.put(`/v1/users/${id}`, request)).data;

export const deleteUser = async (userId: number): Promise<void> =>
  API.delete(`/v1/users/${userId}`);

export const changePassword = async (
  userId: number,
  data: ChangePasswordRequest
): Promise<void> => API.post(`/v1/users/${userId}/change-password`, data);

export const recoverPassword = async (
  recoverPasswordData: RecoverPasswordRequest
) => {
  const { data } = await API.post<PageResponse<any>>(
    `/v1/users/recover-password`,
    recoverPasswordData
  );
  return data;
};

export const createUser = async (
  data: SignUpRequest
): Promise<PageResponse<User>> =>
  (await API.post<PageResponse<User>>('/v1/users', data)).data;
