import { EsimType } from './stock';
import { Continent } from '@models/continent';
import { Country } from '@models/country';
import { Operator } from '@models/operator';

export type ProductBase = {
  id: number;
  code: string;
  name: string;
  coverages: Coverage[];
  stockCode: string;
  price: number;
  shippedLastWeek: number;
  shippedTotal: number;
  refunded: number;
  status: ProductStatus;
};

export type Coverage = {
  continent: string;
  country: string;
  operator: string;
  apn: string;
};

export enum ProductStatus {
  ACTIVE = 'ACTIVE',
  OUT_OF_STOCK = 'OUT_OF_STOCK',
  DISCONTINUED = 'DISCONTINUED',
}

export type ProductForm = {
  description: string;
  esimType: EsimType;
  specification: {
    day: number;
    voice: boolean;
    data: number;
    dataUnit: string | null;
    fup: boolean;
  };
  required: {
    activationDate: boolean;
    days: boolean;
    imei: boolean;
    eid: boolean;
    userName: boolean;
  };
  active: boolean;
  apiParameter: string | null;
} & Pick<ProductBase, 'name' | 'price' | 'stockCode' | 'coverages'>;

export type ProductCoverageAggregate = {
  productCoverageId: number;
  productId: number;
  continent: Continent;
  country: Country;
  operator: Operator;
  apn: string;
};
export type DataCapacity = {
  quantity: number;
  unit: 'MB' | 'GB' | 'TB';
  refreshPeriodUnit: 'DAYS' | 'WEEKS' | 'MONTHS' | 'YEARS' | 'NEVER';
  refreshPeriodValue: number;
};
export type ProductSpecification = {
  dayCount: number;
  isVoiceCallSupported: boolean;
  isUnlimitedData: boolean;
  dataCapacity: DataCapacity;
  isFup: boolean;
};

export enum RequiredDataType {
  ACTIVATION_DATE = 'ACTIVATION_DATE',
  VALIDITY_DAYS = 'VALIDITY_DAYS',
  IMEI = 'IMEI',
  EID = 'EID',
  CUSTOMER_NAME = 'CUSTOMER_NAME',
  PASSPORT_DATA = 'PASSPORT_DATA',
}

export type ProductRequiredData = {
  requiredFields: RequiredDataType[];
};

export type ProductEditForm = ProductForm & Pick<ProductBase, 'code'>;
