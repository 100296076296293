import Button from '@components/common/Button';
import TextInput from '@components/common/TextInput';
import { Partner, PartnerForm } from '@models/partner';
import {
  Box,
  Pagination,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  IconButton,
} from '@mui/material';
import React, { useState } from 'react';
import { Divider } from '@material-ui/core';
import Modal from '@components/common/Modal';
import AddRemoteIpContent from '@components/partner/AddRemoteIpContent';
import { copyToClipboard } from '@utils/common';
import useAsyncEffect from '@hooks/useAsyncEffect';
import { useNavigate, useParams } from 'react-router-dom';
import { usePartnerDetail } from '@queries/partners/usePartnerDetail';
import { usePartnerTransactions } from '@queries/partners/usePartnerTransactions';
import { timestampToDate } from '@utils/time';
import { updateDeposit, updatePartner } from '@apis/partner';
import { v4 as uuid } from 'uuid';
import { Buffer } from 'buffer';
import { toUsdDisplay } from '../../utils/money';
import { toDisplayPartnerId } from './util';
import { usePartnerTransactionCount } from '../../queries/partners/usePartnerTransactionCount';
import { useRecoilValue } from 'recoil';
import { userState } from '@recoils/atoms';
import { UserGroup } from '@models/user';
import { RemoveFromQueueRounded } from '@mui/icons-material';

const PAGE_SIZE = 50;
const MyDetail = () => {
  const user = useRecoilValue(userState);
  const partnerId = user?.group === UserGroup.PARTNER ? user.partnerId : 0;
  const [form, setForm] = React.useState<Partner>({
    name: '',
    partnerId: 0,
    managerUsername: '',
    managerPassword: '',
    managerEmail: '',
    managerName: '',
    address: '',
    balance: 0,
    baseDiscountRate: 0,
    remoteIps: [],
    appId: '',
    appSecret: '',
    callbackUrl: '',
    createdAt: '',
    manualOrderEnabled: false,
  });
  const [addRemoteIp, setAddRemoteIp] = useState(false);
  const [deposit, setDeposit] = useState(0);
  const navigate = useNavigate();
  const { data: partnerDetailForm, refetch } = usePartnerDetail(
    Number(partnerId)
  );

  const [page, setPage] = useState(1);
  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPage(value);
  };
  const { data: partnerTransactions, refetch: refetchTransaction } =
    usePartnerTransactions({
      partnerId: Number(partnerId),
      page: page,
      pageSize: PAGE_SIZE,
    });

  const { data: partnerTransactionCount, refetch: refetchTransactionCount } =
    usePartnerTransactionCount(Number(partnerId));

  const handleOnCancel = () => {
    // cancel
    if (partnerDetailForm?.data[0] !== form) {
      if (!!window.confirm('Do you want to cancel?')) {
        navigate(-1);
      }
    } else {
      navigate(-1);
    }
  };

  const handleOnSave = async () => {
    // save
    await updatePartner(Number(partnerId), form);
    await refetch();
  };

  const handleChangeInput = (
    name: keyof PartnerForm,
    value: string | boolean | number | null
  ) => {
    setForm((prev) => ({ ...prev, [name]: value }));
  };

  const handleRegeneratePassword = () => {
    const base64Encoded = Buffer.from(uuid()).toString('base64');
    const shortenedPassword = base64Encoded.slice(0, 8);
    setForm((prev) => ({ ...prev, managerPassword: shortenedPassword }));
  };

  const handleRegenerateAppId = () => {
    const base64Encoded = Buffer.from(uuid()).toString('base64');
    setForm((prev) => ({ ...prev, appId: base64Encoded }));
  };

  const handleRegenerateAppSecret = () => {
    const base64Encoded = Buffer.from(uuid()).toString('base64');
    setForm((prev) => ({ ...prev, appSecret: base64Encoded }));
  };

  const copyPassword = () => {
    copyToClipboard(form.managerPassword);
  };

  const copyAppId = () => {
    copyToClipboard(form.appId);
  };

  const copyAppSecret = () => {
    copyToClipboard(form.appSecret);
  };

  const applyDeposit = async () => {
    await updateDeposit(Number(partnerId), deposit);
    setForm((prev) => ({ ...prev, balance: prev.balance + deposit }));
    refetchTransaction();
    refetchTransactionCount();
    setDeposit(0);
  };

  useAsyncEffect(async () => {
    if (!partnerId || !partnerDetailForm) return;
    setForm(partnerDetailForm?.data[0]);
  }, [partnerDetailForm, partnerId]);

  return (
    <>
      <Typography variant="h4" component="h4">
        {form.name} ({toDisplayPartnerId(form.partnerId)})
      </Typography>
      <Box
        display={'flex'}
        justifyContent={'flex-end'}
        flexDirection={'column'}
        gap={3}
        marginTop={2}
        marginBottom={2}
      >
        <TextInput
          label={'Admin. ID'}
          value={form.managerUsername}
          onChange={(e) => handleChangeInput('managerUsername', e.target.value)}
          placeholder="Enter ID"
          layout="horizontal"
          labelWidth={150}
          inputWidth={300}
          disabled
        />
        <Box display={'flex'} alignItems={'center'} gap={2}>
          <TextInput
            label={'Admin. PW'}
            value={form.managerPassword}
            onChange={(e) =>
              handleChangeInput('managerPassword', e.target.value)
            }
            placeholder="Enter password"
            layout="horizontal"
            labelWidth={150}
            inputWidth={300}
            type="password"
          />
          <Button text={'REGEN'} onClick={handleRegeneratePassword} />
          <Button text={'COPY'} color="gray" onClick={copyPassword} />
        </Box>
        <TextInput
          label={'Manager'}
          value={form.managerName}
          onChange={(e) => handleChangeInput('managerName', e.target.value)}
          placeholder="Enter Manager name"
          layout="horizontal"
          labelWidth={150}
          inputWidth={300}
        />
        <TextInput
          label={'Email'}
          value={form.managerEmail}
          onChange={(e) => handleChangeInput('managerEmail', e.target.value)}
          placeholder="Enter email address"
          layout="horizontal"
          labelWidth={150}
          inputWidth={300}
        />
        <TextInput
          label={'Callback URL'}
          value={form.callbackUrl}
          onChange={(e) => handleChangeInput('callbackUrl', e.target.value)}
          placeholder="Enter callback URL"
          layout="horizontal"
          labelWidth={150}
          inputWidth={300}
        />
      </Box>
      <Divider />
      <Typography variant="h5" component="h5" marginTop={2}>
        API Parameters
      </Typography>
      <Box
        display={'flex'}
        justifyContent={'flex-end'}
        flexDirection={'column'}
        gap={3}
        marginTop={2}
        marginBottom={2}
      >
        <Box display={'flex'} alignItems={'center'} gap={2}>
          <TextInput
            label={'App ID'}
            value={form.appId}
            onChange={(e) => handleChangeInput('appId', e.target.value)}
            placeholder="Generate App ID"
            layout="horizontal"
            labelWidth={150}
            inputWidth={300}
          />
          <Button text={'REGEN'} onClick={handleRegenerateAppId} />
          <Button text={'COPY'} color="gray" onClick={copyAppId} />
        </Box>
        <Box display={'flex'} alignItems={'center'} gap={2}>
          <TextInput
            label={'App Secret'}
            value={form.appSecret}
            onChange={(e) => handleChangeInput('appSecret', e.target.value)}
            placeholder="Generate App Secret"
            layout="horizontal"
            labelWidth={150}
            inputWidth={300}
          />
          <Button text={'REGEN'} onClick={handleRegenerateAppSecret} />
          <Button text={'COPY'} color="gray" onClick={copyAppSecret} />
        </Box>
      </Box>
      <Box
        display={'flex'}
        flexDirection={'row'}
        mb={2}
        mt={2}
        alignItems={'center'}
        gap={2}
      ></Box>
      <Divider />
      <Typography variant="h5" component="h5" marginTop={2}>
        Balance
      </Typography>
      <Box
        display={'flex'}
        justifyContent={'flex-end'}
        flexDirection={'column'}
        gap={3}
        marginTop={2}
        marginBottom={2}
      >
        <TextInput
          label={'Current (USD)'}
          value={toUsdDisplay(form.balance)}
          placeholder="Enter ID"
          layout="horizontal"
          labelWidth={150}
          inputWidth={300}
          disabled
        />
      </Box>
      <Box
        alignItems="center"
        justifyContent={'space-between'}
        display={'flex'}
        marginTop={2}
      >
        <Typography variant="h5" component="h5">
          Product Authorization and Pricing
          <IconButton onClick={() => navigate(`/my-detail/prices`)}>
            <RemoveFromQueueRounded />
          </IconButton>
        </Typography>
      </Box>
      <Divider />
      <Divider />
      <Box
        alignItems="center"
        justifyContent={'space-between'}
        display={'flex'}
        marginTop={2}
      >
        <Typography variant="h5" component="h5">
          Recent Transactions
        </Typography>
      </Box>

      <TableContainer
        component={Paper}
        sx={{
          marginTop: 2,
        }}
      >
        <Table>
          <TableHead
            sx={{
              backgroundColor: '#f5f5f5',
            }}
          >
            <TableRow>
              <StyledTableCell>Index</StyledTableCell>
              <StyledTableCell>Type</StyledTableCell>
              <StyledTableCell>Transaction ID</StyledTableCell>
              <StyledTableCell>Order Code</StyledTableCell>
              <StyledTableCell>Product Code</StyledTableCell>
              <StyledTableCell>Product</StyledTableCell>
              <StyledTableCell>Qty.</StyledTableCell>
              <StyledTableCell>Previous Balance</StyledTableCell>
              <StyledTableCell>Payment Amount</StyledTableCell>
              <StyledTableCell>Final Balance</StyledTableCell>
              <StyledTableCell>Date</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(partnerTransactions?.data || []).map((t, index) => (
              <StyledTableRow key={t.partnerTransactionId}>
                <StyledTableCell>{index + 1}</StyledTableCell>
                <StyledTableCell>{t.type}</StyledTableCell>
                <StyledTableCell>{t.partnerTransactionCode}</StyledTableCell>
                <StyledTableCell>{t.orderCode}</StyledTableCell>
                <StyledTableCell>{t.productCode}</StyledTableCell>
                <StyledTableCell>{t.productName}</StyledTableCell>
                <StyledTableCell>{t.quantity}</StyledTableCell>
                <StyledTableCell>
                  {toUsdDisplay(t.previousBalance)}
                </StyledTableCell>
                <StyledTableCell>{toUsdDisplay(t.addedAmount)}</StyledTableCell>
                <StyledTableCell>
                  {toUsdDisplay(t.previousBalance + t.addedAmount)}
                </StyledTableCell>
                <StyledTableCell>
                  {timestampToDate(t.createdAt)}
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {/* Pagination */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          mt: 2,
        }}
      >
        <Pagination
          count={Math.ceil((partnerTransactionCount ?? 0) / PAGE_SIZE)}
          page={page}
          onChange={handleChangePage}
        />
      </Box>

      <Box
        display={'flex'}
        flexDirection={'row'}
        justifyContent={'flex-end'}
        gap={2}
        marginTop={2}
      >
        <Button text={'CANCEL'} onClick={handleOnCancel} color={'error'} />
        <Button text={'SAVE'} onClick={handleOnSave} />
      </Box>

      <Modal
        open={addRemoteIp}
        onClose={() => {
          setAddRemoteIp(false);
        }}
      >
        <AddRemoteIpContent
          onClose={() => {
            setAddRemoteIp(false);
          }}
          onAdd={(ip) => {
            setForm((prev) => ({
              ...prev,
              remoteIps: [...prev.remoteIps, ip],
            }));
            setAddRemoteIp(false);
          }}
        />
      </Modal>
    </>
  );
};

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#000',
    color: '#fff',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(() => ({
  backgroundColor: '#FFFFFF',
  '&:hover': {
    backgroundColor: '#F3F6F9',
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export default MyDetail;
