import { recoverPassword } from '@apis/users';
import Button from '@components/common/Button';
import TextInput from '@components/common/TextInput';
import { Box, Typography } from '@mui/material';
import { useState } from 'react';
import Modal from '@components/common/Modal';
import { CheckOutlined, ErrorOutline } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

const ForgotPassword = () => {
  const [forgotPasswordInfo, setForgotPasswordInfo] = useState({
    username: '',
    email: '',
  });
  const navigate = useNavigate();
  const [success, setSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [error, setError] = useState(false);

  const handleChangeInput = (name: 'username' | 'email', value: string) => {
    setForgotPasswordInfo((prev) => ({ ...prev, [name]: value }));
  };

  const handleRecoverPassword = async () => {
    const { message } = await recoverPassword(forgotPasswordInfo);

    if (message !== 'OK') {
      setErrorMessage(message);
      setError(true);
    } else {
      return setSuccess(true);
    }
  };

  return (
    <>
      <Box
        display="flex"
        flexDirection={'column'}
        height={'100vh'}
        alignItems={'center'}
        justifyContent={'center'}
      >
        <Box display="flex" flexDirection={'row'} gap={20}>
          <Box bgcolor={'#ECECEC'} p={2}>
            <Typography variant="h6">Forgot your password!</Typography>
            <Box mt={4}>
              <TextInput
                label={'Username'}
                value={forgotPasswordInfo.username}
                onChange={(e) => handleChangeInput('username', e.target.value)}
                inputWidth={400}
              />
              <TextInput
                label={'email'}
                value={forgotPasswordInfo.email}
                onChange={(e) => handleChangeInput('email', e.target.value)}
                inputWidth={400}
              />
              <Box mt={2} display={'flex'} justifyContent={'flex-end'}>
                <Button
                  color="primary"
                  onClick={handleRecoverPassword}
                  text={'Recover Password'}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Modal
        open={success}
        onClose={() => {
          setSuccess(false);
        }}
      >
        <Box display={'flex'} flexDirection={'row'} gap={2}>
          <CheckOutlined color={'success'} />
          <Typography textAlign={'center'}>
            A temporary password has been sent to your email.
          </Typography>
        </Box>

        <Box display={'flex'} justifyContent={'flex-end'}>
          <Button
            color="primary"
            onClick={() => {
              return navigate('/login');
            }}
            text={'OK'}
          />
        </Box>
      </Modal>
      <Modal
        open={error}
        onClose={() => {
          setSuccess(false);
        }}
      >
        <Box display={'flex'} flexDirection={'row'} gap={2}>
          <ErrorOutline color={'error'} />
          <Typography textAlign={'center'}>{errorMessage}</Typography>
        </Box>
        <Box display={'flex'} justifyContent={'flex-end'}>
          <Button
            color="primary"
            onClick={() => {
              setError(false);
            }}
            text={'OK'}
          />
        </Box>
      </Modal>
    </>
  );
};

export default ForgotPassword;
