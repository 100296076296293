import { Box, CircularProgress, Typography } from '@mui/material';
import React from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useEsimExchangeDetail } from '../queries/exchange/useEsimExchangeDetail';
import QRCode from 'qrcode.react';
import { timestampToDate } from '../utils/time';
import { copyToClipboard } from '../utils/common';
import download from 'downloadjs';
import html2canvas from 'html2canvas';
import { domToJpeg, domToPng } from 'modern-screenshot';

import KoreanMessage from './messages-success-ko.json';
import JapaneseMessage from './messages-success-ja.json';
import SimplifiedChineseMessage from './messages-success-zh-CN.json';
import TraditionalChineseMessage from './messages-success-zh-TW.json';
import EnglishMessage from './messages-success-en.json';
import { get } from 'lodash';

type CountryCode = 'KR' | 'JP' | 'US' | 'CN' | 'TW';
type MessageKeys = keyof typeof KoreanMessage;

const getMessage = (lang: CountryCode, key: MessageKeys) => {
  switch (lang) {
    case 'KR':
      return KoreanMessage[key];
    case 'JP':
      return JapaneseMessage[key];
    case 'CN':
      return SimplifiedChineseMessage[key];
    case 'TW':
      return TraditionalChineseMessage[key];
    default:
      return EnglishMessage[key];
  }
};

export const ExchangeSuccess = (): React.ReactElement => {
  const { encryptedEsimId } = useParams();
  const { data, refetch } = useEsimExchangeDetail(encryptedEsimId ?? '');
  const esimDetail = data?.data[0];

  const lpaUrl = esimDetail?.lpaUrl;

  const urlParts = (esimDetail?.lpaUrl ?? '').split('$');
  const [searchParams] = useSearchParams();
  const email = searchParams.get('email');
  const country = searchParams.get('locale')
    ? (searchParams.get('locale') as CountryCode)
    : 'US';

  //alert after 1 minute when lpaUrl is still not loaded
  React.useEffect(() => {
    if (!!lpaUrl) {
      return;
    }
    const timer = setTimeout(() => {
      if (!lpaUrl) {
        alert('Failed to redeem the esim. Please try again later.');
      }
    }, 60000);
    return () => clearTimeout(timer);
  }, [lpaUrl]);

  //poll data every 5 seconds until lpaUrl is loaded or 5 minutes passed
  React.useEffect(() => {
    if (!!lpaUrl) {
      return;
    }
    const timer = setInterval(() => {
      if (!lpaUrl) {
        refetch();
      }
    }, 5000);
    setTimeout(() => {
      clearInterval(timer);
    }, 300000);
    return () => clearInterval(timer);
  }, [lpaUrl]);

  const onSaveClicked = async () => {
    const dataUrl = await domToPng(document.body);
    const link = document.createElement('a');
    link.download = 'esim.png';
    link.href = dataUrl;
    link.click();
  };

  return (
    <>
      <Box height="50px" />
      <Box
        width={600}
        height={1024}
        textAlign="center"
        bgcolor="#00728C"
        justifyContent="center"
        alignItems="center"
        margin="0 auto"
        borderRadius="25px"
      >
        <Box height="100px" paddingTop="24px">
          <Typography
            color="white"
            fontSize={41}
            fontFamily="Pretendard"
            fontWeight="800"
          >
            {getMessage(country, 'str_result_title')}
          </Typography>
        </Box>
        <Box
          width={550}
          height="800px"
          bgcolor="white"
          margin="0 auto"
          borderRadius="25px"
        >
          <Box height="25px" />
          {!lpaUrl ? (
            <CircularProgress />
          ) : (
            <QRCode
              value={esimDetail?.lpaUrl ?? ''}
              size={200}
              renderAs="svg"
              imageSettings={{
                src: 'https://admin.esimsta.com/resources/static/qr.png',
                height: 50,
                width: 50,
                excavate: true,
              }}
            />
          )}
          <Box margin="5px auto">
            <Typography
              color="#00728D"
              fontSize={20}
              fontFamily="Pretendard"
              fontWeight="800"
            >
              {getMessage(country, 'str_result_guide')}
            </Typography>
          </Box>
          <Box
            width="480px"
            height="165px"
            marginLeft="34px"
            borderRadius="5px"
            bgcolor={'#EEEDEC'}
            margin="20px auto 0"
            textAlign={'left'}
            paddingLeft={'20px'}
          >
            <Typography
              color="#787878"
              fontSize="18px"
              fontFamily="Pretendard"
              fontWeight="700"
              whiteSpace={'break-word'}
            >
              {getMessage(country, 'str_result_plan')}
            </Typography>
            <Typography
              color="black"
              fontSize="16px"
              fontFamily="Pretendard"
              fontWeight="700"
              whiteSpace={'break-word'}
            >
              {esimDetail?.productName ?? ''}
            </Typography>
            <Typography
              color="#787878"
              fontSize="18px"
              fontFamily="Pretendard"
              fontWeight="700"
              whiteSpace={'break-word'}
            >
              {getMessage(country, 'str_result_email')}
            </Typography>
            <Typography
              color="black"
              fontSize="16px"
              fontFamily="Pretendard"
              fontWeight="700"
              whiteSpace={'break-word'}
            >
              {email ?? ''}
            </Typography>
            <Typography
              color="#787878"
              fontSize="18px"
              fontFamily="Pretendard"
              fontWeight="700"
              whiteSpace={'break-word'}
            >
              {getMessage(country, 'str_result_validity')}
            </Typography>
            <Typography
              color="black"
              fontSize="16px"
              fontFamily="Pretendard"
              fontWeight="700"
              whiteSpace={'break-word'}
            >
              {getMessage(country, 'str_result_validity_guide').replace(
                '{{date}}',
                esimDetail?.expireAt ? timestampToDate(esimDetail.expireAt) : ''
              )}
            </Typography>
          </Box>
          <Box>
            <Box
              marginTop="25px"
              width="520px"
              display={'inline-flex'}
              textAlign={'left'}
              alignItems="center"
              marginLeft="34px"
            >
              <img
                src={'https://admin.esimsta.com/resources/static/ios.png'}
                width="30px"
                height="30px"
              />
              <Typography
                color="#00728C"
                fontSize={24}
                fontFamily="Pretendard"
                fontWeight={800}
                whiteSpace="break-word"
                marginLeft="5px"
              >
                {getMessage(country, 'str_result_iphone')}
              </Typography>
            </Box>
            <Box
              width="520px"
              display={'inline-flex'}
              textAlign={'left'}
              alignItems="center"
              justifyContent={'space-between'}
              marginLeft="34px"
            >
              <Box minWidth="200px">
                <Typography
                  color="#787878"
                  fontSize={24}
                  fontFamily="Pretendard"
                  fontWeight={800}
                  marginLeft="5px"
                >
                  {getMessage(country, 'str_result_smdp')}
                </Typography>
              </Box>
              <Box
                textAlign={'right'}
                display={'inline-flex'}
                alignItems="center"
                marginRight={'20px'}
              >
                {!lpaUrl ? (
                  <CircularProgress />
                ) : (
                  <>
                    <Typography
                      color="black"
                      fontSize={16}
                      fontFamily="Pretendard"
                      fontWeight={800}
                      whiteSpace="word-wrap"
                    >
                      {urlParts[1]}
                    </Typography>
                    <Box
                      marginLeft="10px"
                      style={{
                        cursor: 'pointer',
                      }}
                      onClick={() => copyToClipboard(urlParts[1] ?? '')}
                    >
                      <img
                        src={
                          'https://admin.esimsta.com/resources/static/copy.png'
                        }
                        width="30px"
                        height="30px"
                      />
                    </Box>
                  </>
                )}
              </Box>
            </Box>
            <Box
              width="520px"
              display={'inline-flex'}
              textAlign={'left'}
              alignItems="center"
              justifyContent={'space-between'}
              marginLeft="34px"
            >
              <Box minWidth="100px">
                <Typography
                  color="#787878"
                  fontSize={24}
                  fontFamily="Pretendard"
                  fontWeight={800}
                  marginLeft="5px"
                >
                  {getMessage(country, 'str_result_act_code')}
                </Typography>
              </Box>
              <Box
                textAlign={'right'}
                display={'inline-flex'}
                alignItems="center"
                marginRight={'20px'}
              >
                {!lpaUrl ? (
                  <CircularProgress />
                ) : (
                  <>
                    <Typography
                      color="black"
                      fontSize={16}
                      fontFamily="Pretendard"
                      fontWeight={800}
                      whiteSpace="word-wrap"
                    >
                      {urlParts[2]}
                    </Typography>
                    <Box
                      marginLeft="10px"
                      style={{
                        cursor: 'pointer',
                      }}
                      onClick={() => copyToClipboard(urlParts[2] ?? '')}
                    >
                      <img
                        src={
                          'https://admin.esimsta.com/resources/static/copy.png'
                        }
                        width="30px"
                        height="30px"
                      />
                    </Box>
                  </>
                )}
              </Box>
            </Box>
          </Box>
          <Box>
            <Box
              marginTop="25px"
              width="520px"
              display={'inline-flex'}
              textAlign={'left'}
              alignItems="center"
              marginLeft="34px"
            >
              <img
                src={'https://admin.esimsta.com/resources/static/android.png'}
                width="30px"
                height="30px"
              />
              <Typography
                color="#00728C"
                fontSize={24}
                fontFamily="Pretendard"
                fontWeight={800}
                whiteSpace="break-word"
                marginLeft="5px"
              >
                {getMessage(country, 'str_result_android')}
              </Typography>
            </Box>
            <Box
              width="520px"
              display={'inline-flex'}
              textAlign={'left'}
              alignItems="center"
              justifyContent={'space-between'}
              marginLeft="34px"
            >
              <Box minWidth="200px">
                <Typography
                  color="#787878"
                  fontSize={24}
                  fontFamily="Pretendard"
                  fontWeight={800}
                  marginLeft="5px"
                >
                  {getMessage(country, 'str_result_full_code')}
                </Typography>
              </Box>
              <Box
                textAlign={'right'}
                display={'inline-flex'}
                alignItems="center"
                marginRight={'20px'}
              >
                {!lpaUrl ? (
                  <CircularProgress />
                ) : (
                  <>
                    <Typography
                      color="black"
                      fontSize={16}
                      fontFamily="Pretendard"
                      fontWeight={800}
                      whiteSpace="pre-wrap"
                    >
                      {urlParts[0] + urlParts[1]}
                    </Typography>
                    <Box
                      marginLeft="10px"
                      style={{
                        cursor: 'pointer',
                      }}
                      onClick={() => copyToClipboard(esimDetail?.lpaUrl ?? '')}
                    >
                      <img
                        src={
                          'https://admin.esimsta.com/resources/static/copy.png'
                        }
                        width="30px"
                        height="30px"
                      />
                    </Box>
                  </>
                )}
              </Box>
            </Box>
            <Box width="520px" textAlign={'right'}>
              <Typography
                marginRight="27px"
                color="black"
                fontSize={16}
                fontFamily="Pretendard"
                fontWeight={800}
                whiteSpace="word-wrap"
              >
                {urlParts[2]}
              </Typography>
            </Box>
          </Box>
          <Box textAlign={'left'} marginLeft="28px" marginTop="15px">
            <Typography
              color="black"
              fontSize={16}
              fontFamily="Pretendard"
              fontWeight="500"
              whiteSpace="pre-wrap"
            >
              {'*' + getMessage(country, 'str_result_note_1')}
            </Typography>
            <Typography
              color="black"
              fontSize={16}
              fontFamily="Pretendard"
              fontWeight="500"
              whiteSpace="pre-wrap"
            >
              {'*' + getMessage(country, 'str_result_note_2')}
            </Typography>
          </Box>
        </Box>
        <Box
          width={350}
          height={70}
          bgcolor={'black'}
          borderRadius="10px"
          display={'inline-flex'}
          alignItems={'center'}
          justifyContent={'center'}
          marginTop="27px"
          onClick={onSaveClicked}
          style={{
            cursor: 'pointer',
          }}
        >
          <Typography
            color="white"
            fontSize={26}
            fontFamily="Pretendard"
            fontWeight="700"
          >
            {getMessage(country, 'str_result_save')}
          </Typography>
          <Box marginLeft="5px">
            <img
              src="https://admin.esimsta.com/resources/static/save.png"
              width={'30px'}
              height={'30px'}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};
