import { PeriodUnit } from '@apis/summary';
import Button from '@components/common/Button';
import RadioButtonGroup from '@components/common/RadioButtonGroup';
import DashBoard from '@components/dashboard/DashBoard';
import {
  Box,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from '@mui/material';
import {
  useSummaryThisWeek,
  useSummaryThisYear,
  useSummaryToday,
  useSummayThisMonth,
} from '@queries/summaries/useDashboardSummary';
import { useSummaryInventoryStatus } from '@queries/summaries/useSummaryInventoryStatus';
import { useSummarySalesByPartner } from '@queries/summaries/useSummaySalesByPartner';
import { useSummarySalesByProduct } from '@queries/summaries/useSummaySalesByProduct';
import { useState } from 'react';
import { utcToZonedTime } from 'date-fns-tz';
import { KOREAN_TIME_ZONE } from '@constants/timezone';

const Home = () => {
  const [productFilter, setProductFilter] = useState<PeriodUnit>('MONTHS');
  const [partnerFilter, setPartnerFilter] = useState<PeriodUnit>('MONTHS');

  const nowInUTC = new Date();
  const kstDate = utcToZonedTime(nowInUTC, KOREAN_TIME_ZONE);

  const [refreshTime, setRefreshTime] = useState(kstDate);

  // Today
  const { data: todayData, refetch: refetchToday } = useSummaryToday();

  // This week
  const { data: thisWeekData, refetch: refetchThisWeek } = useSummaryThisWeek();

  // This month
  const { data: thisMonthData, refetch: refetchThisMonth } =
    useSummayThisMonth();

  // This year
  const { data: thisYearData, refetch: refetchThisYear } = useSummaryThisYear();

  const { data: inventoryStatus } = useSummaryInventoryStatus();

  const { data: salesByProduct } = useSummarySalesByProduct(productFilter);

  const { data: salesByPartner } = useSummarySalesByPartner(partnerFilter);

  const handleRefresh = async () => {
    setRefreshTime(kstDate);
    await Promise.all([
      refetchToday(),
      refetchThisWeek(),
      refetchThisMonth(),
      refetchThisYear(),
    ]);
  };

  return (
    <>
      <Typography variant="h4" component="h4">
        Dash Board
      </Typography>
      {/* 요약 */}
      <Box
        mt={3}
        width={'100%'}
        display={'flex'}
        flex={1}
        flexDirection={'column'}
      >
        <Box
          width={'100%'}
          display={'flex'}
          gap={5}
          flexDirection={'row'}
          alignItems={'center'}
        >
          <SummaryText
            title={'Today'}
            value={todayData?.data[0].amount || 0}
            unit={'USD'}
          />
          <SummaryText
            title={'This week'}
            value={thisWeekData?.data[0].amount || 0}
            unit={'USD'}
          />
          <SummaryText
            title={'This month'}
            value={thisMonthData?.data[0].amount || 0}
            unit={'USD'}
          />
          <SummaryText
            title={'This year'}
            value={thisYearData?.data[0].amount || 0}
            unit={'USD'}
          />
          {/* updated time */}
          <Box
            display={'flex'}
            gap={5}
            flexDirection={'row'}
            ml={5}
            alignItems={'center'}
          >
            <Typography variant="h6" component="h6">
              {`Last updated: ${refreshTime.toLocaleString()}`}
            </Typography>
            <Button text={'REFRESH'} onClick={handleRefresh} />
          </Box>
        </Box>
      </Box>
      <Toolbar />
      <Box gap={3} width={'100%'} display={'flex'} flexDirection={'column'}>
        <Box
          width={'100%'}
          display={'flex'}
          flexDirection={'row'}
          alignItems={'center'}
          gap={3}
          height={HEIGHT}
        >
          {/* Sales by period (dashboard) */}
          <TableContainer
            component={Paper}
            elevation={0}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              height: HEIGHT,
            }}
          >
            <Typography variant="h6" component="h6" mb={2}>
              Sales by period
            </Typography>
            <Table
              sx={{
                height: '100%',
              }}
            >
              <DashBoard />
            </Table>
          </TableContainer>
          {/* Inventory status */}
          <TableContainer
            component={Paper}
            elevation={0}
            sx={{
              height: HEIGHT,
            }}
          >
            <Typography variant="h6" component="h6" mb={2}>
              Inventory status
            </Typography>
            <Table>
              <TableHead>
                <TableRow>
                  <StyledTableCell>Rank</StyledTableCell>
                  <StyledTableCell>Stock Code</StyledTableCell>
                  <StyledTableCell>Remained</StyledTableCell>
                  <StyledTableCell>Shipped (Last 1 week)</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(inventoryStatus?.data || []).map((row, index) => (
                  <TableRow
                    key={row.stockId}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.rank}
                    </TableCell>
                    <TableCell align="right">{row.stockCode}</TableCell>
                    <TableCell align="right">{row.remained}</TableCell>
                    <TableCell align="right">{row.shipped}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <Box
          width={'100%'}
          display={'flex'}
          flexDirection={'row'}
          gap={3}
          height={HEIGHT}
          alignItems={'flex-start'}
        >
          {/* Sales by product */}
          <TableContainer component={Paper} elevation={0}>
            <Box display="flex" justifyContent={'space-between'}>
              <Typography variant="h6" component="h6" mb={2}>
                Sales by product
              </Typography>
              <RadioButtonGroup
                value={productFilter}
                onChange={(value) => {
                  setProductFilter(value as PeriodUnit);
                }}
                options={[
                  { label: 'Day', value: 'DAYS' },
                  { label: 'Week', value: 'WEEKS' },
                  { label: 'Month', value: 'MONTHS' },
                ]}
                layout="horizontal"
              />
            </Box>

            <Table>
              <TableHead>
                <TableRow>
                  <StyledTableCell>Rank</StyledTableCell>
                  <StyledTableCell>Product Name</StyledTableCell>
                  <StyledTableCell>Qty.</StyledTableCell>
                  <StyledTableCell>Amount (USD)</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(salesByProduct?.data || []).map((row) => (
                  <TableRow
                    key={row.rank}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.rank}
                    </TableCell>
                    <TableCell align="right">{row.productName}</TableCell>
                    <TableCell align="right">{row.quantity}</TableCell>
                    <TableCell align="right">{row.amount}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {/* Sales by partner */}
          <TableContainer component={Paper} elevation={0}>
            <Box display="flex" justifyContent={'space-between'}>
              <Typography variant="h6" component="h6" mb={2}>
                Sales by partner
              </Typography>
              <RadioButtonGroup
                value={partnerFilter}
                onChange={(value) => {
                  setPartnerFilter(value as PeriodUnit);
                }}
                options={[
                  { label: 'Day', value: 'DAYS' },
                  { label: 'Week', value: 'WEEKS' },
                  { label: 'Month', value: 'MONTHS' },
                ]}
                layout="horizontal"
              />
            </Box>

            <Table>
              <TableHead>
                <TableRow>
                  <StyledTableCell>Rank</StyledTableCell>
                  <StyledTableCell>Partner</StyledTableCell>
                  <StyledTableCell>Purchase amount(USD)</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(salesByPartner?.data || []).map((row) => (
                  <TableRow
                    key={row.rank}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.rank}
                    </TableCell>
                    <TableCell align="right">{row.partnerName}</TableCell>
                    <TableCell align="right">{row.amount}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </>
  );
};

const HEIGHT = 400;

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#000',
    color: '#fff',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

type SummaryTextProps = {
  title: string;
  value: number;
  unit: string;
};

const SummaryText = ({ title, value, unit }: SummaryTextProps) => {
  return (
    <Box display={'flex'} gap={1} flexDirection={'column'}>
      <Typography variant="h6" component="h6">
        {title}
      </Typography>
      <Typography>
        {unit} {value.toLocaleString()}
      </Typography>
    </Box>
  );
};

export default Home;
