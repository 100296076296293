import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { dropEmptyValues } from '../../utils/common';
import { GetProductsParams, getProductsCount } from '@apis/product';

export const useProductCount = (searchParam: GetProductsParams) => {
  const queryOptions: UseQueryOptions<number, Error> = {
    queryKey: ['productsCount'],
    queryFn: () => {
      return getProductsCount(
        dropEmptyValues(searchParam, [
          'productCode',
          'productName',
          'stockId',
          'countryId',
          'productStatus',
        ])
      );
    },
  };
  return useQuery(queryOptions);
};
