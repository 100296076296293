import Button from '@components/common/Button';
import TextInput from '@components/common/TextInput';
import {
  Box,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
  tableCellClasses,
} from '@mui/material';
import { useState } from 'react';
import IconButton from '@components/common/IconButton';
import { DeleteOutline } from '@mui/icons-material';
import Modal from '@components/common/Modal';
import {
  PERMITTED_USER_GROUP,
  User,
  UserGroup,
  UserStatus,
} from '@models/user';
import Select, { SelectValue } from '@components/common/Select';
import AddUserModalContent from '@components/user/AddUserModalContent';
import EditUserContent from '@components/user/EditUserContent';
import { GetUsersParams, deleteUser } from '@apis/users';
import { useUserList } from '@queries/users/useUserList';
import { timestampToDate } from '@utils/time';
import { useUserCount } from '../../queries/users/useUserCount';
import { useSearchParams } from 'react-router-dom';
import { filterSearchParams } from '../../utils/search';
import SearchSelect from '../../components/common/SearchSelect';

export type UserSearchForm = GetUsersParams;

const PAGE_SIZE = 50;
const Users = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchForm, setSearchForm] = useState<UserSearchForm>({
    name: searchParams.get('name') || undefined,
    username: searchParams.get('username') || undefined,
    group: (searchParams.get('group') as UserGroup) || undefined,
    status: (searchParams.get('status') as UserStatus) || undefined,
  });
  const [selected, setSelected] = useState<User | null>(null);
  const [addMode, setAddMode] = useState(false);

  const [page, setPage] = useState(1);
  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPage(value);
    setSearchParams({
      ...filterSearchParams(
        Object.fromEntries(new URLSearchParams(searchForm as any).entries())
      ),
      page: value.toString(),
    });
  };

  const { data: users, refetch } = useUserList({
    page: page,
    pageSize: PAGE_SIZE,
    searchParam: searchForm,
  });
  const { data: totalCount, refetch: refetchCount } = useUserCount(searchForm);

  const handleChangeInput = (name: keyof UserSearchForm, value: string) => {
    setSearchForm((prev) => ({ ...prev, [name]: value }));
  };

  const handleOnSearch = () => {
    setSearchParams({
      ...filterSearchParams(
        Object.fromEntries(new URLSearchParams(searchForm as any).entries())
      ),
    });
    refetch();
    refetchCount();
  };

  const handleOnAdd = () => {
    setAddMode(true);
  };

  const handleCloseEditModal = (needsRefetch: boolean) => {
    setSelected(null);
    if (needsRefetch) {
      refetch();
      refetchCount();
    }
  };

  const handleCloseAddModal = (needsRefetch: boolean) => {
    setAddMode(false);
    if (needsRefetch) {
      refetch();
      refetchCount();
    }
  };

  const handleChangeSelect = (name: 'group' | 'status', value: SelectValue) => {
    setSearchForm((prev) => ({ ...prev, [name]: value }));
  };

  const handleDeleteUser = async (userId: number) => {
    if (!!window.confirm('Are you sure to delete this user?')) {
      await deleteUser(userId);
    }

    refetch();
    refetchCount();
  };

  return (
    <>
      <Typography variant="h4" component="h4">
        Users
      </Typography>
      <Box display={'flex'} justifyContent={'flex-end'}>
        <Button text={'Add'} onClick={handleOnAdd} />
      </Box>
      {/* Search Section */}
      <Box
        mt={2}
        mb={3}
        gap={2}
        display={'flex'}
        flexDirection={'column'}
        alignItems={'flex-end'}
      >
        <Box
          width={'100%'}
          display={'flex'}
          alignItems={'flex-end'}
          gap={2}
          flexDirection={'row'}
        >
          <TextInput
            label={'ID'}
            value={searchForm.name}
            onChange={(e) => handleChangeInput('username', e.target.value)}
            inputWidth={200}
            placeholder="Enter user ID"
          />
          <TextInput
            label={'Name'}
            value={searchForm.name}
            onChange={(e) => handleChangeInput('name', e.target.value)}
            inputWidth={200}
            placeholder="Enter user name"
          />
          <SearchSelect
            disablePortal
            label={'Group'}
            options={PERMITTED_USER_GROUP.map((v) => ({
              label: v,
              value: v,
            }))}
            value={
              PERMITTED_USER_GROUP.map((v) => ({
                label: v,
                value: v,
              })).find((v) => v.value === searchForm?.group) || null
            }
            setValue={(v) =>
              handleChangeSelect('group', (v?.value as UserGroup) || null)
            }
            placeholder="Select the group"
          />
          <SearchSelect
            disablePortal
            label={'Status'}
            options={Object.keys(UserStatus).map((v) => ({
              label: v,
              value: v,
            }))}
            value={
              Object.keys(UserStatus)
                .map((v) => ({
                  label: v,
                  value: v,
                }))
                .find((v) => v.value === searchForm?.status) || null
            }
            setValue={(v) =>
              handleChangeSelect('status', (v?.value as UserStatus) || null)
            }
            placeholder="Select the status"
          />
          <Button text={'Search'} onClick={handleOnSearch} />
        </Box>
      </Box>

      {/* List Section */}
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }}>
          <TableHead>
            <TableRow>
              <StyledTableCell>Index</StyledTableCell>
              <StyledTableCell>ID</StyledTableCell>
              <StyledTableCell>Name</StyledTableCell>
              <StyledTableCell>Position</StyledTableCell>
              <StyledTableCell>Group</StyledTableCell>
              <StyledTableCell>E-mail</StyledTableCell>
              <StyledTableCell>Phone</StyledTableCell>
              <StyledTableCell>Recent login time</StyledTableCell>
              <StyledTableCell>Status</StyledTableCell>
              <StyledTableCell>Remove</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(users?.data || []).map((row, index) => (
              <StyledTableRow
                key={row.userId}
                onClick={() => {
                  setSelected(row);
                }}
              >
                <StyledTableCell component="th" scope="row">
                  {(page - 1) * 10 + index + 1}
                </StyledTableCell>
                <StyledTableCell>{row.username}</StyledTableCell>
                <StyledTableCell>{row.name}</StyledTableCell>
                <StyledTableCell>{row.position}</StyledTableCell>
                <StyledTableCell>{row.group}</StyledTableCell>
                <StyledTableCell>{row.email}</StyledTableCell>
                <StyledTableCell>{row.phoneNumber}</StyledTableCell>
                <StyledTableCell>
                  {timestampToDate(row.recentLoginTime || '') || '-'}
                </StyledTableCell>
                <StyledTableCell>{row.status}</StyledTableCell>
                <StyledTableCell>
                  <IconButton
                    onClick={() => {
                      handleDeleteUser(row.userId);
                    }}
                  >
                    <DeleteOutline />
                  </IconButton>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {/* Pagination */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          mt: 2,
        }}
      >
        <Pagination
          count={Math.ceil((totalCount ?? 0) / PAGE_SIZE)}
          page={page}
          onChange={handleChangePage}
        />
      </Box>

      <Modal open={!!selected} onClose={() => handleCloseEditModal(false)}>
        {!!selected && (
          <EditUserContent onClose={handleCloseEditModal} selected={selected} />
        )}
      </Modal>
      <Modal open={addMode} onClose={() => handleCloseAddModal(false)}>
        <AddUserModalContent onClose={handleCloseAddModal} />
      </Modal>
    </>
  );
};

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#000',
    color: '#fff',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(() => ({
  backgroundColor: '#FFFFFF',
  '&:hover': {
    backgroundColor: '#F3F6F9',
    cursor: 'pointer',
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export default Users;
