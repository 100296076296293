import API from './API';
import { PageParams, PageResponse } from '@models/api';

export type GetSuppliersParams = {
  supplierName?: string;
  managerName?: string;
  email?: string;
  phoneNumber?: string;
};

export type SupplierResponse = {
  supplierId: number;
  supplierCode: string;
  supplierName: string;
  managerName: string;
  email: string;
  phoneNumber: string;
  isDeleted: boolean;
  createdAt: string;
};

export type ListSupplierResponse = {
  supplierId: number;
  supplierCode: string;
  supplierName: string;
};

export type SupplierUpsertRequest = {
  supplierName: string;
  managerName: string;
  email?: string;
  phoneNumber?: string;
  isDeleted: boolean;
};

export const getSuppliersCount = async (
  params: GetSuppliersParams
): Promise<number> => {
  const { data } = await API.get<PageResponse<number>>('/v1/suppliers/count', {
    params,
  });
  return data.data[0];
};

export const getSuppliers = async (
  params: GetSuppliersParams & PageParams
): Promise<PageResponse<SupplierResponse>> =>
  (await API.get<PageResponse<SupplierResponse>>('/v1/suppliers', { params }))
    .data;

export const getAllSuppliers = async () => {
  const { data } =
    await API.get<PageResponse<ListSupplierResponse>>('/v1/suppliers/all');
  return data;
};

export const getSupplier = async (
  supplierId: number
): Promise<PageResponse<SupplierResponse>> =>
  (await API.get<PageResponse<SupplierResponse>>(`/v1/suppliers/${supplierId}`))
    .data;

export const createSupplier = async (
  request: SupplierUpsertRequest
): Promise<SupplierResponse> =>
  (await API.post<SupplierResponse>(`/v1/suppliers`, request)).data;

export const editSupplier = async (
  supplierId: number,
  request: SupplierUpsertRequest
): Promise<SupplierResponse> =>
  (await API.put<SupplierResponse>(`/v1/suppliers/${supplierId}`, request))
    .data;
