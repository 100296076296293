export enum StockCodeRegion {
  US = 'US',
  HK = 'HK',
  CN = 'CN',
}

export enum StockCodeStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  REORDER_REQUIRED = 'REORDER_REQUIRED',
}

export enum StockStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export type StockSupplier = {
  id: number;
  code: string;
  name: string;
  managerName: string;
  email: string;
  phone: string;
  createdAt: string;
};

export type StockCode = {
  id: number;
  code: string;
  name: string;
  supplier: StockSupplier;
  region: StockCodeRegion;
  remained: number;
  shippedLastWeek: number;
  shippedTotal: number;
  refunded: number; // 취소
  expired: number; // 만료
  status: StockCodeStatus;
};

export enum EsimType {
  LPA = 'LPA',
  REDEMPTION = 'REDEMPTION',
  DISCOVERY = 'DISCOVERY',
}

export enum EsimEventType {
  UPLOADED = 'UPLOADED',
  STATE_CHANGED = 'STATE_CHANGED',
  USER_MEMO = 'USER_MEMO',
  EXPIRE_DATE_CHANGED = 'EXPIRE_DATE_CHANGED',
}

export enum EsimStatus {
  SHIPPED = 'SHIPPED',
  EXPIRED = 'EXPIRED',
  READY = 'READY',
  CANCELED = 'CANCELED',
}

export enum ParameterType {
  URI = 'URI',
  ICCID = 'ICCID',
  MSISDN = 'MSISDN',
}

export type EsimInventory = {
  id: number;
  stockCode: string;
  type: EsimType;
  uri: string;
  iccid: string;
  msisdn: string;
  state: EsimStatus;
  expireDate: string;
};

export type EsimInventoryDetail = {
  imsi: string;
  serialNumber: string;
  pin: string;
  puk: string;
  redeem: string;
  memoList: EsimInventoryMemo[];
  // esim discovery
  esimDiscoveryInfo?: {
    activationDate: string;
    validity: number;
    imei: string;
    eid: string;
    customerName: string;
  };
} & EsimInventory;

export type EsimInventoryMemo = {
  id: number;
  event: string;
  owner: string;
  description: string;
  orderId: string;
  createdAt: string;
};

export type EsimInventoryForm = Pick<
  EsimInventoryDetail,
  | 'uri'
  | 'msisdn'
  | 'iccid'
  | 'imsi'
  | 'serialNumber'
  | 'pin'
  | 'puk'
  | 'expireDate'
  | 'state'
>;

export type UploadHistory = {
  id: number;
  stockCode: string;
  stockName: string;
  quantity: number;
  fileName: string;
  createdAt: string;
};

export type EsimInventoryUploadInfo = {
  success: boolean;
  errorType?: 'PAST' | 'DATE ERROR' | 'ALREADY EXIST' | 'INVALID';
} & EsimInventoryDetail;

export type UploadHistoryDetail = {
  total: number;
  ok: number;
  fail: number;
  esimInventoryList: EsimInventoryUploadInfo[];
} & UploadHistory;

export type UpdateDetail = {
  fileName: string;
  total: number;
  ok: number;
  fail: number;
  esimInventoryList: EsimInventoryUploadInfo[];
};
