import * as React from 'react';
import TextInput from '@components/common/TextInput';
import { Box, Divider } from '@mui/material';
import { CloseOutlined } from '@mui/icons-material';
import Label from '@components/common/Label';
import IconButton from '@components/common/IconButton';
import Button from '@components/common/Button';
import { RefundForm } from '@models/order';
import { OrderEsimResponse, OrderResponse, refundOrder } from '@apis/order';

type RefundEsimContentProps = {
  onClose: (refresh: boolean) => void;
  order: OrderResponse;
  selectedEsim: OrderEsimResponse | null;
};

const RefundEsimContent = ({
  onClose,
  order,
  selectedEsim,
}: RefundEsimContentProps) => {
  const [form, setForm] = React.useState<RefundForm>({
    refundFee: 0,
  });

  const remainingPaymentAmount = order.paymentAmount - order.refundAmount;
  const pricePerEsim = order.paymentAmount / order.quantity;

  const handleOnConfirm = async () => {
    if (!selectedEsim) return;
    if (Math.min(remainingPaymentAmount, pricePerEsim) - form.refundFee <= 0) {
      alert(
        'refundFee must be less than ' +
          Math.min(remainingPaymentAmount, pricePerEsim).toFixed(2)
      );
      return;
    }
    if (
      !!window.confirm(
        `Would you like to proceed with a refund of $${(
          Math.min(remainingPaymentAmount, pricePerEsim) - form.refundFee
        ).toFixed(2)}?`
      )
    ) {
      // refund API
      await refundOrder(order.orderId, {
        esimType: selectedEsim.esimType,
        refundType: 'PARTIAL_REFUND',
        esimIds: [selectedEsim.esimId],
        refundFee: form.refundFee,
      });
      onClose(true);
    }
  };

  return (
    <Box display={'flex'} flexDirection={'column'}>
      <Box
        display="flex"
        width={'100%'}
        flexDirection="row"
        justifyContent={'space-between'}
      >
        <Box display={'flex'}>
          <Label>Refund eSIM</Label>
        </Box>
        <IconButton onClick={() => onClose(false)}>
          <CloseOutlined />
        </IconButton>
      </Box>
      <Box display="flex" width={'100%'} flexDirection="column" gap={1}>
        {/* order */}
        <TextInput label={'Order Code'} value={order.orderCode} disabled />
        <Divider />
        {/* product */}
        <TextInput
          label={'Product Code'}
          value={order.product.productCode}
          disabled
        />
        <TextInput
          label={'Product Name'}
          value={order.product.productName}
          disabled
        />
        <Divider />
        {/* esim */}
        <TextInput
          label={'Stock Code'}
          value={order.stock.stockCode}
          disabled
        />
        {/* 아래 정보 TBD */}
        <TextInput
          label={'Product Name'}
          value={order.product.productName}
          disabled
        />
        <Divider />
        {/* refund form */}
        <Box width={'100%'} display="flex" flexDirection="column">
          <TextInput
            label={'Amount (USD)'}
            layout="horizontal"
            value={order.paymentAmount.toFixed(2)}
            inputWidth={270}
            labelWidth={150}
            disabled
          />
          <TextInput
            label={'Refund Fee (USD)'}
            layout="horizontal"
            value={form.refundFee.toString()}
            inputWidth={270}
            labelWidth={150}
            onChange={(e) => {
              if (/(?!0\d)^[0-9]*\.?[0-9]*$/.test(e.target.value)) {
                const newValue = Math.round(Number(e.target.value) * 100) / 100;
                setForm({
                  ...form,
                  refundFee: newValue,
                });
              }
            }}
            type="number"
            unit="USD"
          />
          <TextInput
            label={'Refund Amount (USD)'}
            layout="horizontal"
            value={(
              Math.min(pricePerEsim, remainingPaymentAmount) - form.refundFee
            ).toFixed(2)}
            inputWidth={270}
            labelWidth={150}
            disabled
          />
        </Box>
      </Box>
      <Box display={'flex'} justifyContent={'flex-end'} mt={2}>
        <Button
          text={'OK'}
          onClick={handleOnConfirm}
          disabled={!selectedEsim || form.refundFee >= remainingPaymentAmount}
        />
      </Box>
    </Box>
  );
};

export default RefundEsimContent;
