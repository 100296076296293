import * as React from 'react';
import { FormControlProps } from '@mui/base/FormControl';
import { Input, inputClasses } from '@mui/base/Input';
import { styled } from '@mui/system';
import { blue, grey } from './colors';
import InputWrapper, { InputWrapperBaseProps } from './InputWrapper';
import { Box } from '@mui/material';

type TextInputProps = {
  type?: 'password' | 'text' | 'date' | 'file' | 'number' | 'email';
  placeholder?: string;
} & FormControlProps &
  InputWrapperBaseProps;

const TextInput = ({
  type = 'text',
  placeholder,
  ...wrapperProps
}: TextInputProps) => {
  return (
    <InputWrapper {...wrapperProps}>
      <Box
        display={wrapperProps.layout === 'horizontal' ? 'flex' : undefined}
        width="100%"
      >
        <StyledInput placeholder={placeholder} type={type} />
      </Box>
    </InputWrapper>
  );
};

const StyledInput = styled(Input)(
  () =>
    `
    display: flex;
    width: 100%;
    
  .${inputClasses.input} {
    width: 100%;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 8px 12px;
    border-radius: 8px;
    color: ${grey[900]};
    background: #ffffff;
    border: 1px solid ${grey[200]};
    box-shadow: 0px 2px 2px ${grey[50]};

    &:hover {
      border-color: ${grey[300]};
    }

    &:focus {
      outline: 0;
      border-color: ${blue[500]};
      box-shadow: 0 0 0 3px ${blue[200]};
    }

    &:disabled {
      background: ${grey[100]};
      border-color: ${grey[200]};
    }
  }
`
);

export default TextInput;
