import { Box } from '@mui/material';
import TextInput from '@components/common/TextInput';
import Chip from '@mui/material/Chip';

type APIInputProps = {
  value: string | null;
  isDisabled: boolean;
  setValue: (value: string | null) => void;
};

const APIInput = ({ value, isDisabled, setValue }: APIInputProps) => {
  return (
    <Box display={'flex'} flexDirection={'row'} alignItems={'center'} gap={3}>
      <Chip
        size="medium"
        label={'API'}
        color={isDisabled ? 'default' : 'primary'}
        variant="outlined"
      />
      <TextInput
        label="Parameter"
        value={value}
        layout="horizontal"
        labelWidth={80}
        disabled={isDisabled}
        onChange={(e) => {
          setValue(e.target.value);
        }}
        inputWidth={300}
      />
    </Box>
  );
};

export default APIInput;
