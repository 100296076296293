import * as React from 'react';
import TextInput from '@components/common/TextInput';
import { Box } from '@mui/material';
import { CloseOutlined } from '@mui/icons-material';
import Label from '@components/common/Label';
import IconButton from '@components/common/IconButton';
import Button from '@components/common/Button';
import {
  PERMITTED_USER_GROUP,
  User,
  UserGroup,
  UserStatus,
} from '@models/user';
import CheckBox from '@components/common/CheckBox';
import Select, { SelectValue } from '@components/common/Select';
import { deleteUser, recoverPassword, updateUser } from '@apis/users';
import { alertMissingValues } from '@utils/common';

type UserForm = Omit<User, 'recentLoginTime'>;

type EditUserContentProps = {
  onClose: (refetch: boolean) => void;
  selected: User;
};

const EditUserContent = ({ onClose, selected }: EditUserContentProps) => {
  const [form, setForm] = React.useState<UserForm>({
    userId: selected.userId,
    name: selected.name,
    username: selected.username,
    position: selected.position,
    group: selected.group,
    email: selected.email,
    phoneNumber: selected.phoneNumber,
    status: selected.status,
  });

  const handleChangeInput = (name: keyof UserForm, value: string) => {
    setForm((prev) => ({ ...prev, [name]: value }));
  };

  const handleChangeCheckBox = (name: string, value: boolean) => {
    setForm((prev) => ({
      ...prev,
      status: value ? UserStatus.ACTIVE : UserStatus.INACTIVE,
    }));
  };

  const handleChangeSelect = (name: 'group', value: SelectValue) => {
    setForm((prev) => ({ ...prev, [name]: value as UserGroup }));
  };

  const handleOnEdit = async () => {
    //validation
    alertMissingValues(form);

    await updateUser(Number(selected.userId), {
      userId: form.userId,
      position: form.position,
      group: form.group,
      email: form.email,
      phoneNumber: form.phoneNumber,
      status: form.status,
    });
    onClose(true);
  };

  const handleOnDelete = async () => {
    if (window.confirm('Are you sure to delete this user?')) {
      await deleteUser(Number(selected.userId));
    }
    onClose(true);
  };

  const handleResetPassword = async () => {
    const { message } = await recoverPassword({
      username: selected.username,
      email: selected.email,
    });

    if (message === 'OK') {
      window.confirm('Password reset email has been sent to the user');
    } else {
      window.confirm('Failed to reset password\n' + message);
    }

    onClose(false);
  };

  return (
    <Box display={'flex'} flexDirection={'column'} width={'100%'}>
      <Box
        display="flex"
        width={'100%'}
        flexDirection="row"
        justifyContent={'space-between'}
      >
        <Box display={'flex'}>
          <Label>User ({selected.username})</Label>
        </Box>
        <IconButton onClick={() => onClose(false)}>
          <CloseOutlined />
        </IconButton>
      </Box>
      <Box display="flex" width={'100%'} flexDirection="column">
        <Box
          display={'flex'}
          flexDirection={'row'}
          width={'100%'}
          justifyContent={'space-between'}
          mt={2}
          alignItems={'flex-end'}
        >
          <TextInput
            label={'ID'}
            value={form.username}
            onChange={(e) => handleChangeInput('userId', e.target.value)}
            disabled
          />
          <Button
            text={'PW RESET'}
            color="secondary"
            onClick={handleResetPassword}
          />
        </Box>

        <TextInput
          label={'Name'}
          value={form.name}
          onChange={(e) => handleChangeInput('name', e.target.value)}
          disabled
        />
        <TextInput
          label={'Position'}
          value={form.position}
          onChange={(e) => handleChangeInput('position', e.target.value)}
          placeholder="Enter the position"
        />
        <Select
          label={'Group'}
          value={form.group}
          setValue={(v) => handleChangeSelect('group', v)}
          optionList={PERMITTED_USER_GROUP.map((v) => ({
            label: v,
            value: v,
          }))}
          placeholder="Select group"
        />
        <TextInput
          label={'E-mail'}
          value={form.email}
          onChange={(e) => handleChangeInput('email', e.target.value)}
          placeholder="Enter the e-mail address"
        />
        <TextInput
          label={'Contact'}
          value={form.phoneNumber}
          onChange={(e) => handleChangeInput('phoneNumber', e.target.value)}
          placeholder="Enter the contact"
        />
        <CheckBox
          label={'Active'}
          value={form.status === 'ACTIVE'}
          onCheck={(checked) => handleChangeCheckBox('status', checked)}
        />
      </Box>
      <Box
        display={'flex'}
        flexDirection={'row'}
        width={'100%'}
        justifyContent={'space-between'}
        mt={2}
      >
        <Button text={'DELETE'} onClick={handleOnDelete} color={'error'} />
        <Button text={'OK'} onClick={handleOnEdit} />
      </Box>
    </Box>
  );
};

export default EditUserContent;
