export const copyToClipboard = (text: string) => {
  const el = document.createElement('textarea');
  el.value = text;
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
};

export const dropEmptyValues = <T extends { [key: string]: unknown }>(
  obj: T,
  optionalKeys: (keyof T)[]
): T => {
  const copied: T = { ...obj };
  for (const key of optionalKeys) {
    if (obj[key] === undefined || obj[key] === null || obj[key] === '') {
      delete copied[key];
    }
  }
  return copied;
};

export function findKeysOfNullValues<T>(obj: T): (keyof T)[] {
  const nullValues: (string | number | null)[] = [0, -1, '', null];
  const keys: (keyof T)[] = [];

  for (const key in obj) {
    if (
      obj[key] !== undefined &&
      nullValues.includes(obj[key] as string | number | null)
    ) {
      keys.push(key);
    }
  }

  return keys;
}

export function alertMissingValues<T>(obj: T): boolean {
  const missingKeys = findKeysOfNullValues(obj);

  if (missingKeys.length > 0) {
    const missingKeysString = missingKeys.join(', ');
    alert(
      `The following fields are missing or have invalid values:\n\n${missingKeysString}`
    );
    return true;
  }

  return false;
}

export function isNullOrValidJson(key: string, value: string | undefined | null): boolean {
  if(value === null || value === undefined) {
    return true
  } else {
    try {
      JSON.parse(value);
      return true
    } catch (e) {
      alert(`Invalid json format for ${key}`)
      return false
    }
  }
}