import * as React from 'react';
import Stack from '@mui/material/Stack';
import { Chip as BaseChip } from '@mui/material';

type ChipProps = {
  label: string;
  onDelete?: () => void;
  onClick?: () => void;
};

export default function Chip({ label, onDelete, onClick }: ChipProps) {
  return (
    <Stack direction="row" spacing={1}>
      <BaseChip
        color="primary"
        label={label}
        onClick={onClick}
        onDelete={onDelete}
      />
    </Stack>
  );
}
