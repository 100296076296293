import Button from '@components/common/Button';
import Introduction from '@components/common/Introduction';
import TextInput from '@components/common/TextInput';
import useAuth from '@hooks/useAuth';
import { Box, Typography } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { GoogleReCaptcha, useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { verifyCaptchaToken } from '@apis/auth';
import { useRecoilState, useRecoilValue } from 'recoil';
import { userState } from '@recoils/atoms';
import { UserGroup } from '@models/user';
import useAsyncEffect from '@hooks/useAsyncEffect';
import { getMe } from '@apis/users';

const Login = () => {
  const [loginInfo, setLoginInfo] = useState({
    username: '',
    password: '',
  });
  const { executeRecaptcha } = useGoogleReCaptcha();

  const navigate = useNavigate();
  const { login, isAuthenticated } = useAuth();
  const [user, setUser] = useRecoilState(userState);

  const handleChangeInput = (name: 'username' | 'password', value: string) => {
    setLoginInfo((prev) => ({ ...prev, [name]: value }));
  };

  const handleLogin = async () => {
    await login(loginInfo);
    navigate('/');
  };

  const handleForgotPassword = () => {
    navigate('/forgot-password');
  };

  const handleVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return;
    }
    const token = await executeRecaptcha('login');
    verifyCaptchaToken(token);
  }, [executeRecaptcha]);

  useAsyncEffect(async () => {
    if (!!isAuthenticated) {
      const _user = await getMe();
      if (!_user) {
        return;
      }
      if (_user.group === UserGroup.PARTNER) {
        navigate('/my-detail');
      } else {
        navigate('/home');
      }
      setUser(_user);
    }
  }, [isAuthenticated]);

  return (
    <Box
      display="flex"
      flexDirection={'column'}
      height={'100vh'}
      alignItems={'center'}
      justifyContent={'center'}
      padding={'0 20px'}
    >
      <Box display="flex" flexDirection={'row'} gap={20}>
        <Introduction />
        <Box bgcolor={'#ECECEC'} p={2}>
          <Typography variant="h6">Login to your account</Typography>
          <Box mt={4}>
            <TextInput
              label={'Username'}
              value={loginInfo.username}
              onChange={(e) => handleChangeInput('username', e.target.value)}
              inputWidth={400}
            />
            <TextInput
              label={'Password'}
              value={loginInfo.password}
              onChange={(e) => handleChangeInput('password', e.target.value)}
              type="password"
              inputWidth={400}
            />
            <Box mt={2} bgcolor={'red'}>
              <GoogleReCaptcha onVerify={handleVerify} action="login" />
            </Box>
            <Box
              mt={2}
              display={'flex'}
              justifyContent={'flex-end'}
              onClick={handleForgotPassword}
            >
              <Typography
                style={{
                  textDecoration: 'underline',
                  cursor: 'pointer',
                }}
              >
                Forgot password?
              </Typography>
            </Box>
            <Box mt={2} display={'flex'} justifyContent={'flex-end'}>
              <Button color="primary" onClick={handleLogin} text={'Login'} />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Login;
