import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import Header from '@components/common/Header';
import LeftSideBar from '@components/common/LeftSideBar';
import { Outlet, useNavigate } from 'react-router-dom';
import useAsyncEffect from '@hooks/useAsyncEffect';
import { getMe } from '@apis/users';
import { userState } from '@recoils/atoms';
import { useRecoilState } from 'recoil';
import useAuth from '@hooks/useAuth';
import { useEffect } from 'react';

const Root = () => {
  const [user, _setUser] = useRecoilState(userState);
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();

  useAsyncEffect(async () => {
    if (!!isAuthenticated) {
      try {
        const _user = await getMe();
        if (!_user) {
          return;
        }
        _setUser(_user);
      } catch (error) {
        console.error(error);
      }
    }
  }, [isAuthenticated]);

  // redirect from root to home or my-detail
  useEffect(() => {
    if (!user || !isAuthenticated) return;
    if (window.location.pathname === '/') {
      if (user.group === 'PARTNER') {
        navigate('/my-detail');
      } else {
        navigate('/home');
      }
    }
  }, [user, navigate, isAuthenticated]);

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Header />
      <LeftSideBar />
      <Box component="main" sx={{ flexGrow: 1, p: 3, overflowY: 'scroll' }}>
        <Toolbar />
        <Outlet />
      </Box>
    </Box>
  );
};

export default Root;
