import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { PageResponse } from '@models/api';
import { getAllOperators, OperatorResponse } from '@apis/operator';

export const useAllOperatorList = () => {
  const queryOptions: UseQueryOptions<PageResponse<OperatorResponse>, Error> = {
    queryKey: ['operators'],
    queryFn: () => {
      return getAllOperators();
    },
  };
  return useQuery(queryOptions);
};
