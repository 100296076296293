import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { PageResponse } from '@models/api';

import { PartnerProductPriceBulkUploadDetailResponse } from '../../models/partnerBulk';
import {
  GetPriceUploadHistoryDetailsParams,
  getPriceUploadHistoryDetails,
} from '../../apis/partnerBulk';

export const usePriceUploadDetails = ({
  partnerId,
  page,
  pageSize,
  uploadId,
  params,
}: {
  partnerId: number;
  page: number;
  pageSize: number;
  uploadId: number;
  params: GetPriceUploadHistoryDetailsParams;
}) => {
  const queryOptions: UseQueryOptions<
    PageResponse<PartnerProductPriceBulkUploadDetailResponse>,
    Error
  > = {
    queryKey: [
      'uploadPartnerHistoryDetails',
      partnerId,
      page,
      uploadId,
      !!params.onlyFailed,
    ],
    queryFn: () => {
      if (!params.onlyFailed) delete params.onlyFailed;
      return getPriceUploadHistoryDetails(partnerId, uploadId, {
        pageNo: page,
        pageSize: pageSize,
        ...params,
      });
    },
  };
  return useQuery(queryOptions);
};
