import { PageParams, PageResponse } from '@models/api';
import API from './API';
import {
  Partner,
  PartnerProductPriceResponse,
  PartnerProductPriceUpsertRequest,
  PartnerTransaction,
  PartnersSearchForm,
} from '@models/partner';
import { GetProductsParams } from './product';

export type PartnerCreateRequest = {
  managerUsername: string;
  managerPassword: string;
  name: string;
  managerName: string;
  managerEmail: string;
  managerTelephone?: string;
  address?: string;
  appId: string;
  appSecret: string;
  remoteIps: string[];
  callbackUrl?: string;
  manualOrderEnabled: boolean;
};

export const getPartnersCount = async (params: PartnersSearchForm) => {
  const { data } = await API.get<PageResponse<number>>('/v1/partners/count', {
    params,
  });
  return data.data[0];
};

export const getPartners = async (params: PageParams & PartnersSearchForm) => {
  const { data } = await API.get<PageResponse<Partner>>('/v1/partners', {
    params,
  });
  return data;
};

export const getPartner = async (partnerId: number) => {
  const { data } = await API.get<PageResponse<Partner>>(
    `/v1/partners/${partnerId}/detail`
  );
  return data;
};

export const getAllPartners = async () => {
  const { data } = await API.get<PageResponse<Partner>>(`/v1/partners/all`);
  return data;
};

export const createPartner = (data: PartnerCreateRequest) =>
  API.post('/v1/partners', data);

export const updatePartner = (partnerId: number, data: Partial<Partner>) =>
  API.put(`/v1/partners/${partnerId}`, data);

export const updateDeposit = (partnerId: number, amount: number) =>
  API.post(`/v1/partners/${partnerId}/deposit`, { depositBalance: amount });

export const getPartnerTransactions = async (
  partnerId: number,
  params: PageParams
) => {
  const { data } = await API.get<PageResponse<PartnerTransaction>>(
    `/v1/partners/${partnerId}/transactions`,
    { params }
  );
  return data;
};

export const getPartnerTransactionCount = async (partnerId: number) => {
  const { data } = await API.get<PageResponse<number>>(
    `/v1/partners/${partnerId}/transactions/count`
  );
  return data.data[0];
};

export const getPartnerProductPrices = async (
  partnerId: number,
  params: PageParams & GetProductsParams
) => {
  const { data } = await API.get<PageResponse<PartnerProductPriceResponse>>(
    `/v1/partners/${partnerId}/prices`,
    { params }
  );
  return data;
};

export const getActivePartnerProductPrices = async (
  partnerId: number,
  params: PageParams & GetProductsParams
) => {
  const { data } = await API.get<PageResponse<PartnerProductPriceResponse>>(
    `/v1/partners/${partnerId}/active/prices`,
    { params }
  );
  return data;
};

export const getPartnerProductPricesCount = async (
  partnerId: number,
  params: GetProductsParams
) => {
  const { data } = await API.get<PageResponse<number>>(
    `/v1/partners/${partnerId}/prices/count`,
    { params }
  );
  return data.data[0];
};

export const getActivePartnerProductPricesCount = async (
  partnerId: number,
  params: GetProductsParams
) => {
  const { data } = await API.get<PageResponse<number>>(
    `/v1/partners/${partnerId}/prices/active/count`,
    { params }
  );
  return data.data[0];
};

export const updatePartnerProductPrice = async (
  partnerPrice: PartnerProductPriceUpsertRequest
) => {
  await API.put(
    `/v1/partners/${partnerPrice.partnerId}/prices/${partnerPrice.productId}`,
    partnerPrice
  );
};

export const exportPartnerPricesExcel = async (
  partnerId: number,
  params: GetProductsParams
) => {
  const { data } = await API.get(
    `/v1/partners/${partnerId}/prices/export-excel`,
    {
      params,
      responseType: 'blob',
    }
  );
  return data;
};

export const exportActivePartnerPricesExcel = async (
  partnerId: number,
  params: GetProductsParams
) => {
  const { data } = await API.get(
    `/v1/partners/${partnerId}/prices/active/export-excel`,
    {
      params,
      responseType: 'blob',
    }
  );
  return data;
};
