import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { PageResponse } from '@models/api';
import { ListStockResponse, getAllStocks } from '@apis/stock';

export const useAllStockList = () => {
  const queryOptions: UseQueryOptions<
    PageResponse<ListStockResponse>,
    Error
  > = {
    queryKey: ['stocksAll'],
    queryFn: () => {
      return getAllStocks();
    },
  };
  return useQuery(queryOptions);
};
