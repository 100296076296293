import { PeriodUnit } from '@apis/summary';
import { KOREAN_TIME_ZONE } from '@constants/timezone';
import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

export const timestampToDate = (
  timestamp: string | number,
  format?:
    | 'YYYY-MM-DD'
    | 'YYYY/MM/DD'
    | 'DD-MM-YYYY'
    | 'DD/MM/YYYY'
    | 'YYYYMMDD'
): string => {
  if (!timestamp) return '';
  let date = new Date(timestamp);
  if (typeof timestamp !== 'string') {
    // add 9 hours for timezone
    date = new Date(date.getTime() + 1000 * 60 * 60 * 9);
  }
  let formattedDate = '';

  switch (format) {
    case 'YYYY-MM-DD':
      formattedDate = date.toISOString().slice(0, 10);
      break;
    case 'YYYY/MM/DD':
      formattedDate = date.toISOString().slice(0, 10).replace(/-/g, '/');
      break;
    case 'DD-MM-YYYY':
      formattedDate =
        date.toISOString().slice(8, 10) +
        '-' +
        date.toISOString().slice(5, 7) +
        '-' +
        date.toISOString().slice(0, 4);
      break;
    case 'DD/MM/YYYY':
      formattedDate =
        date.toISOString().slice(8, 10) +
        '/' +
        date.toISOString().slice(5, 7) +
        '/' +
        date.toISOString().slice(0, 4);
      break;
    case 'YYYYMMDD':
      formattedDate =
        date.toISOString().slice(0, 4) +
        date.toISOString().slice(5, 7) +
        date.toISOString().slice(8, 10);
      break;
    default:
      formattedDate = date.toISOString().slice(0, 19).replace('T', ' ');
  }

  return formattedDate;
};

export const dateToTimestamp = (
  date: string,
  endOfDay: boolean = false,
  truncate = false
): number => {
  // Determine the time portion based on the endOfDay flag
  const timePortion = endOfDay ? 'T23:59:59' : '';

  // Append the time portion to the date
  const dateWithTime = `${date}${timePortion}`;

  // Convert the date (and optionally time) to KST
  let zonedDate = utcToZonedTime(dateWithTime, KOREAN_TIME_ZONE);
  // set to UTC
  if (truncate) {
    zonedDate = new Date(zonedDate.getTime() - 9 * 60 * 60 * 1000);
  }

  // If endOfDay is true, format the zonedDate to 'yyyy-MM-dd HH:mm:ss' in KST,
  // otherwise, use the zonedDate as is for formatting.
  // This step ensures that if endOfDay is true, the formatted date is the end of the day.
  const formattedDate = format(
    zonedDate,
    `yyyy-MM-dd${endOfDay ? ' HH:mm:ss' : ''}`
  );

  // Convert the formattedDate back to a Date object to get the timestamp.
  // Add 'Z' to treat the formatted time as UTC, which is necessary for correct timestamp conversion.
  const finalDate = new Date(`${formattedDate}${endOfDay ? 'Z' : ''}`);

  return finalDate.getTime();
};

export const getTimestampRangeByPeriod = (
  period: PeriodUnit
): { from: number; to: number } => {
  let from = new Date();
  let to = new Date();

  if (period === 'DAYS') {
    from.setHours(0, 0, 0, 0);
    to.setHours(23, 59, 59, 999);
  }

  if (period === 'WEEKS') {
    from = new Date(from.setDate(from.getDate() - from.getDay()));
    from.setHours(0, 0, 0, 0);
    to = new Date(to.setDate(to.getDate() + (6 - to.getDay())));
    to.setHours(23, 59, 59, 999);
  }

  if (period === 'MONTHS') {
    from.setDate(1);
    from.setHours(0, 0, 0, 0);
    to.setMonth(to.getMonth() + 1);
    to.setDate(0);
    to.setHours(23, 59, 59, 999);
  }

  if (period === 'YEARS') {
    from.setMonth(0);
    from.setDate(1);
    from.setHours(0, 0, 0, 0);
    to.setMonth(11);
    to.setDate(31);
    to.setHours(23, 59, 59, 999);
  }

  return {
    from: from.getTime(),
    to: to.getTime(),
  };
};
