import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { PageResponse } from '@models/api';
import { getAllPartners } from '@apis/partner';
import { Partner } from '@models/partner';

export const useAllPartnerList = () => {
  const queryOptions: UseQueryOptions<PageResponse<Partner>, Error> = {
    queryKey: ['allPartners'],
    queryFn: () => {
      return getAllPartners();
    },
  };
  return useQuery(queryOptions);
};
