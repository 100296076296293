import {
  Box,
  Pagination,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import Button from '@components/common/Button';
import { useNavigate, useSearchParams } from 'react-router-dom';
import TextInput from '@components/common/TextInput';
import { OrderStatus } from '@models/order';
import DateRangeInput from '@components/common/DateRangeInput';
import { toUsdDisplay } from '../../utils/money';
import { useOrderList } from '@queries/orders/useOrderList';
import { exportOrderEsims, exportOrders, GetOrdersParams } from '@apis/order';
import { useAllStockList } from '@queries/stocks/useAllStockList';
import useAsyncEffect from '@hooks/useAsyncEffect';
import { SelectValue } from '@components/common/Select';
import { useAllPartnerList } from '@queries/partners/useAllPartnerList';
import { dateToTimestamp, timestampToDate } from '@utils/time';
import { useOrdersCount } from '@queries/orders/useOrdersCount';
import { userState } from '@recoils/atoms';
import { useRecoilValue } from 'recoil';
import { UserGroup } from '@models/user';
import { downloadBlob } from '@utils/file';
import { usePartnerDetail } from '@queries/partners/usePartnerDetail';
import { utcToZonedTime } from 'date-fns-tz';
import { KOREAN_TIME_ZONE } from '@constants/timezone';
import { filterSearchParams } from '@utils/search';
import SearchSelect from '../../components/common/SearchSelect';
import { ProductSaleType } from '../../apis/product';

type OrdersSearchForm = GetOrdersParams;
const PAGE_SIZE = 50;
const Orders = () => {
  const user = useRecoilValue(userState);
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchForm, setSearchForm] = useState<OrdersSearchForm>({
    orderCode: searchParams.get('orderCode') || '',
    productCode: searchParams.get('productCode') || '',
    productSaleType:
      (searchParams.get('productSaleType') as ProductSaleType) || undefined,
    productName: searchParams.get('productName') || '',
    stockId: searchParams.get('stockId')
      ? Number(searchParams.get('stockId'))
      : undefined,
    partnerId: searchParams.get('partnerId')
      ? Number(searchParams.get('partnerId'))
      : user?.partnerId,
    orderStatus: (searchParams.get('orderStatus') as OrderStatus) || undefined,
    createdAtFrom: searchParams.get('createdAtFrom')
      ? Number(searchParams.get('createdAtFrom'))
      : undefined,
    createdAtTo: searchParams.get('createdAtTo')
      ? Number(searchParams.get('createdAtTo'))
      : undefined,
    reservedAtFrom: searchParams.get('reservedAtFrom')
      ? Number(searchParams.get('reservedFrom'))
      : undefined,
    reservedAtTo: searchParams.get('reservedAtTo')
      ? Number(searchParams.get('reservedAtTo'))
      : undefined,
  });

  useEffect(() => {
    if (user?.partnerId) {
      setSearchForm({
        ...searchForm,
        partnerId: user.partnerId,
      });
    }
  }, [searchForm, user?.partnerId]);

  const [page, setPage] = useState(
    searchParams.get('page') ? Number(searchParams.get('page')) : 1
  );
  const navigate = useNavigate();

  const { data: partnerDetailForm } = usePartnerDetail(Number(user?.partnerId));

  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPage(value);

    setSearchParams({
      ...Object.fromEntries(searchParams.entries()),
      page: value.toString(),
    });
  };

  const { data, refetch, isLoading } = useOrderList({
    page,
    searchParam: searchForm,
    user,
  });

  const {
    data: totalCount,
    refetch: refetchCount,
    isLoading: isLoadingCount,
  } = useOrdersCount(searchForm, user);

  const handleChangeInput = (
    name: keyof OrdersSearchForm,
    value: string | number | null
  ) => {
    setSearchForm((prev) => ({ ...prev, [name]: value }));
  };

  const handleOnSearch = () => {
    refetch();
    refetchCount();

    setSearchParams({
      ...filterSearchParams(
        Object.fromEntries(new URLSearchParams(searchForm as any).entries())
      ),
    });
  };

  const handleOnExport = async () => {
    const res = await exportOrders(searchForm);
    const nowInUTC = new Date();
    const kstDate = utcToZonedTime(nowInUTC, KOREAN_TIME_ZONE);
    const fileName = `orders-${kstDate.toISOString()}.xlsx`;
    downloadBlob(res, fileName);
  };

  const handleOnExportReport = async () => {
    const res = await exportOrderEsims(searchForm);
    const nowInUTC = new Date();
    const kstDate = utcToZonedTime(nowInUTC, KOREAN_TIME_ZONE);
    const fileName = `orders-report-${kstDate.toISOString()}.xlsx`;
    downloadBlob(res, fileName);
  };

  const [stockOptionList, setStockOptionList] = useState<
    { value: SelectValue; label: string }[]
  >([]);
  const [partnerOptionList, setPartnerOptionList] = useState<
    { value: SelectValue; label: string }[]
  >([]);
  const [needsRefetch, setNeedsRefetch] = useState(false);

  const { data: stocksData } = useAllStockList();
  const { data: partnersData } = useAllPartnerList();

  useAsyncEffect(async () => {
    if (!stocksData) return;
    setStockOptionList(
      stocksData?.data.map((s) => ({
        label: s.stockCode,
        value: s.stockId,
      }))
    );
  }, [stocksData, user]);

  useAsyncEffect(async () => {
    if (!partnersData) return;
    if (user?.group === UserGroup.PARTNER) {
      setPartnerOptionList([
        {
          label: partnerDetailForm?.data[0].name || '',
          value: user?.partnerId || 0,
        },
      ]);
      if (user.partnerId) {
        setSearchForm((prev) => ({
          ...prev,
          partnerId: user.partnerId,
        }));
      }
    } else {
      setPartnerOptionList(
        partnersData?.data.map((s) => ({
          label: s.name,
          value: s.partnerId,
        }))
      );
    }
  }, [partnersData, user, partnerDetailForm]);

  useEffect(() => {
    if (!searchParams.size) {
      setSearchForm({});
      setPage(1);
      setNeedsRefetch(true);
    }
  }, [searchParams]);

  useEffect(() => {
    if (!user) return;
    if (needsRefetch && !isLoading && !isLoadingCount) {
      refetch();
      refetchCount();
      setNeedsRefetch(false);
    }
  }, [needsRefetch, refetch, refetchCount, isLoading, isLoadingCount, user]);

  useEffect(() => {
    if (!user) return;
    if (user.group === UserGroup.PARTNER && !!searchForm.partnerId) {
      refetch();
      refetchCount();
    }
  }, [user, refetch, refetchCount, searchForm.partnerId]);

  return (
    <>
      <Typography variant="h4" component="h4">
        Orders
      </Typography>
      {/* Search Section */}
      <Box
        mb={3}
        gap={2}
        mt={2}
        display={'flex'}
        flexDirection={'column'}
        alignItems={'flex-end'}
      >
        <Box width={'100%'} display={'flex'} gap={1} flexDirection={'row'}>
          <TextInput
            label={'Order Code'}
            value={searchForm.orderCode || ''}
            onChange={(e) => handleChangeInput('orderCode', e.target.value)}
            inputWidth={400}
            placeholder="Enter Order Code"
          />
          <TextInput
            label={'Product Code'}
            value={searchForm.productCode || ''}
            onChange={(e) => handleChangeInput('productCode', e.target.value)}
            inputWidth={400}
            placeholder="Enter product code"
          />
          <TextInput
            label={'Product Name'}
            value={searchForm.productName || ''}
            onChange={(e) => handleChangeInput('productName', e.target.value)}
            inputWidth={400}
            placeholder="Enter product name"
          />
          {user?.group !== UserGroup.PARTNER && (
            <SearchSelect
              disablePortal
              label={'Stock Code'}
              options={stockOptionList}
              placeholder="Enter the Stock Code"
              value={
                stockOptionList.find((c) => c.value === searchForm.stockId) ||
                null
              }
              setValue={(value) =>
                handleChangeInput('stockId', value?.value || null)
              }
            />
          )}
          <SearchSelect
            disablePortal
            label={'Sale Type'}
            options={Object.values(ProductSaleType).map((saleType) => ({
              label: saleType,
              value: saleType,
            }))}
            placeholder="Enter the Product Sale Type"
            value={
              Object.values(ProductSaleType)
                .map((saleType) => ({
                  label: saleType,
                  value: saleType,
                }))
                .find(
                  (option) => option.value === searchForm.productSaleType
                ) || null
            }
            setValue={(value) => {
              handleChangeInput('productSaleType', value?.value || null);
            }}
          />
        </Box>
        <Box
          width={'100%'}
          display={'flex'}
          gap={2}
          flexDirection={'row'}
          justifyContent={'space-between'}
          alignItems={'flex-end'}
        >
          <Box display={'flex'} gap={2} flexDirection={'row'}>
            <SearchSelect
              disablePortal
              label={'Partner'}
              options={partnerOptionList}
              placeholder="Select partner"
              value={
                partnerOptionList.find(
                  (p) =>
                    p.value ===
                    (user?.group === UserGroup.PARTNER
                      ? user?.partnerId
                      : searchForm.partnerId)
                ) || null
              }
              setValue={(value) => {
                handleChangeInput('partnerId', value?.value || null);
              }}
              disabled={user?.group === UserGroup.PARTNER}
            />
            <SearchSelect
              disablePortal
              label={'State'}
              options={Object.values(OrderStatus).map((s) => ({
                label: s,
                value: s,
              }))}
              placeholder="Select state"
              value={
                Object.values(OrderStatus)
                  .map((s) => ({
                    label: s,
                    value: s,
                  }))
                  .find((s) => s.value === searchForm.orderStatus) || null
              }
              setValue={(value) =>
                handleChangeInput('orderStatus', value?.value || null)
              }
            />

            <DateRangeInput
              startDate={timestampToDate(
                searchForm?.createdAtFrom || '',
                'YYYY-MM-DD'
              )}
              endDate={timestampToDate(
                searchForm?.createdAtTo || '',
                'YYYY-MM-DD'
              )}
              handleSelectStartDate={(date) => {
                if (!date) {
                  handleChangeInput('createdAtFrom', null);
                  setSearchForm((prev) => ({
                    ...prev,
                    createdAtFrom: undefined,
                  }));
                } else {
                  const createdAtFrom = dateToTimestamp(date);
                  handleChangeInput('createdAtFrom', createdAtFrom);
                }
              }}
              handleSelectEndDate={(date) => {
                if (!date) {
                  handleChangeInput('createdAtTo', null);
                  setSearchForm((prev) => ({
                    ...prev,
                    createdAtTo: undefined,
                  }));
                } else {
                  const createdAtTo = dateToTimestamp(date, true, true);
                  handleChangeInput('createdAtTo', createdAtTo);
                }
              }}
              label="Order Date"
            />
            <DateRangeInput
              startDate={timestampToDate(
                searchForm?.reservedAtFrom || '',
                'YYYY-MM-DD'
              )}
              endDate={timestampToDate(
                searchForm?.reservedAtTo || '',
                'YYYY-MM-DD'
              )}
              handleSelectStartDate={(date) => {
                if (!date) {
                  handleChangeInput('reservedAtFrom', null);
                  setSearchForm((prev) => ({
                    ...prev,
                    reservedAtFrom: undefined,
                  }));
                } else {
                  const reservedAtFrom = dateToTimestamp(date);
                  handleChangeInput('reservedAtFrom', reservedAtFrom);
                }
              }}
              handleSelectEndDate={(date) => {
                if (!date) {
                  handleChangeInput('reservedAtTo', null);
                  setSearchForm((prev) => ({
                    ...prev,
                    reservedAtTo: undefined,
                  }));
                } else {
                  const reservedAtTo = dateToTimestamp(date, true, true);
                  handleChangeInput('reservedAtTo', reservedAtTo);
                }
              }}
              label="Reserved Date"
            />
          </Box>
          <Box display={'flex'} gap={2} flexDirection={'row'}>
            <Button text={'Search'} onClick={handleOnSearch} />
            <Button
              text={'Export'}
              onClick={handleOnExport}
              color={'secondary'}
            />
            <Button
              text={'Export Report'}
              onClick={handleOnExportReport}
              color={'secondary'}
            />

            {user?.manualOrderEnabled && (
              <Button
                text={'Create'}
                onClick={() => navigate('/orders/add')}
                color={'error'}
              />
            )}
          </Box>
        </Box>
      </Box>
      <Toolbar />
      <TableContainer component={Paper}>
        <Table>
          <TableHead
            sx={{
              backgroundColor: '#f5f5f5',
            }}
          >
            <TableRow>
              <StyledTableCell>Index</StyledTableCell>
              <StyledTableCell>Order Code</StyledTableCell>
              <StyledTableCell>Product Code</StyledTableCell>
              <StyledTableCell>Product Name</StyledTableCell>
              {user?.group !== UserGroup.PARTNER && (
                <StyledTableCell>Stock Code</StyledTableCell>
              )}
              <StyledTableCell>Qty.</StyledTableCell>
              <StyledTableCell>Amount (USD)</StyledTableCell>
              <StyledTableCell>Partner</StyledTableCell>
              <StyledTableCell>Reserved Date</StyledTableCell>
              <StyledTableCell>Order Date</StyledTableCell>
              <StyledTableCell>State</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(data?.data || []).map((order, index) => (
              <StyledTableRow
                key={order.orderId}
                onClick={() => {
                  navigate(`/orders/${order.orderId}`);
                }}
              >
                <StyledTableCell>{index + 1}</StyledTableCell>
                <StyledTableCell>{order.orderCode}</StyledTableCell>
                <StyledTableCell>{order.product.productCode}</StyledTableCell>
                <StyledTableCell>{order.product.productName}</StyledTableCell>
                {user?.group !== UserGroup.PARTNER && (
                  <StyledTableCell>{order.stock.stockCode}</StyledTableCell>
                )}
                <StyledTableCell>{order.quantity}</StyledTableCell>
                <StyledTableCell>
                  {toUsdDisplay(order.paymentAmount)}
                </StyledTableCell>
                <StyledTableCell>{order.partner.name}</StyledTableCell>
                <StyledTableCell>
                  {order.reservedAt ? timestampToDate(order.reservedAt) : 'N/A'}
                </StyledTableCell>
                <StyledTableCell>
                  {timestampToDate(order.createdAt)}
                </StyledTableCell>
                <StyledTableCell>{order.orderStatus}</StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          mt: 2,
        }}
      >
        <Pagination
          count={Math.ceil((totalCount ?? 0) / PAGE_SIZE)}
          page={page}
          onChange={handleChangePage}
        />
      </Box>
    </>
  );
};

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#000',
    color: '#fff',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(() => ({
  backgroundColor: '#FFFFFF',
  '&:hover': {
    backgroundColor: '#F3F6F9',
    cursor: 'pointer',
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export default Orders;
