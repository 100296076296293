import Button from '@components/common/Button';
import TextInput from '@components/common/TextInput';
import { PartnerForm } from '@models/partner';
import { Box, Typography } from '@mui/material';
import React, { useState } from 'react';
import { Divider } from '@material-ui/core';
import Modal from '@components/common/Modal';
import AddRemoteIpContent from '@components/partner/AddRemoteIpContent';
import { alertMissingValues, copyToClipboard } from '@utils/common';
import { useNavigate } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import { Buffer } from 'buffer';
import { PartnerCreateRequest, createPartner } from '@apis/partner';
import { getUserByEmail, getUserByUsername } from '../../apis/users';
import CheckBox from '../../components/common/CheckBox';

type DuplicateCheckStatus = 'NOT_CHECKED' | 'DUPLICATED' | 'OK';
const AddPartner = () => {
  const [form, setForm] = React.useState<PartnerCreateRequest>({
    name: '',
    managerUsername: '',
    managerPassword: '',
    managerEmail: '',
    managerName: '',
    remoteIps: [],
    appId: Buffer.from(uuid()).toString('base64'),
    appSecret: Buffer.from(uuid()).toString('base64'),
    callbackUrl: '',
    manualOrderEnabled: false,
  });
  const [usernameStatus, setUsernameStatus] =
    React.useState<DuplicateCheckStatus>('NOT_CHECKED');
  const [emailStatus, setEmailStatus] =
    React.useState<DuplicateCheckStatus>('NOT_CHECKED');
  const navigate = useNavigate();
  const [addRemoteIp, setAddRemoteIp] = useState(false);

  const handleOnCancel = () => {
    if (!!window.confirm('Do you want to cancel?')) {
      navigate(-1);
    }
  };

  const handleOnSave = async () => {
    if (usernameStatus === 'NOT_CHECKED') {
      alert('Please check if the manager username is duplicated');
      return;
    } else if (usernameStatus === 'DUPLICATED') {
      alert('Username is duplicated');
      return;
    }

    if (emailStatus === 'NOT_CHECKED') {
      alert('Please check if the manager email is duplicated');
      return;
    } else if (emailStatus === 'DUPLICATED') {
      alert('Email is duplicated');
      return;
    }

    // validate
    const hasMissingValues = alertMissingValues(form);

    if (hasMissingValues) return;

    await createPartner(form);
    navigate('/partners');
  };

  const handleCheckUsernameDuplicate = async () => {
    const { data } = await getUserByUsername(form.managerUsername);
    if (data.length === 0) {
      setUsernameStatus('OK');
      alert('Username is not duplicated!\nYou can use this username!');
    } else {
      setUsernameStatus('DUPLICATED');
      alert('Username is duplicated!\nPlease use another username!');
    }
  };

  const handleCheckEmailDuplicate = async () => {
    const { data } = await getUserByEmail(form.managerEmail);
    if (data.length === 0) {
      setEmailStatus('OK');
      alert('Email is not duplicated!\nYou can use this email!');
    } else {
      setEmailStatus('DUPLICATED');
      alert('Email is duplicated!\nPlease use another email!');
    }
  };

  const handleChangeInput = (
    name: keyof PartnerForm,
    value: string | boolean | number | null
  ) => {
    setForm((prev) => ({ ...prev, [name]: value }));
  };

  const onUsernameChanged = (username: string) => {
    setUsernameStatus('NOT_CHECKED');
    setForm((prev) => ({ ...prev, managerUsername: username }));
  };

  const onEmailChanged = (email: string) => {
    setEmailStatus('NOT_CHECKED');
    setForm((prev) => ({ ...prev, managerEmail: email }));
  };

  const handleDeleteRemoteIp = (index: number) => {
    setForm((prev) => ({
      ...prev,
      remoteIps: prev.remoteIps.filter((_, i) => i !== index),
    }));
  };

  const handleClickAddRemoteIp = () => {
    setAddRemoteIp(true);
  };

  const handleRegeneratePassword = () => {
    // regenerate password
    const base64Encoded = Buffer.from(uuid()).toString('base64');
    const shortenedPassword = base64Encoded.slice(0, 8);
    setForm((prev) => ({ ...prev, managerPassword: shortenedPassword }));
  };

  const handleRegenerateAppId = () => {
    // regenerate app id
    const base64Encoded = Buffer.from(uuid()).toString('base64');
    setForm((prev) => ({ ...prev, appId: base64Encoded }));
  };

  const handleRegenerateAppSecret = () => {
    // regenerate app secret
    const base64Encoded = Buffer.from(uuid()).toString('base64');
    setForm((prev) => ({ ...prev, appSecret: base64Encoded }));
  };

  const copyPassword = () => {
    // copy to clipboard
    copyToClipboard(form.managerPassword);
  };

  const copyAppId = () => {
    // copy to clipboard
    copyToClipboard(form.appId);
  };

  const copyAppSecret = () => {
    // copy to clipboard
    copyToClipboard(form.appSecret);
  };

  return (
    <>
      <Typography variant="h4" component="h4">
        New Partner
      </Typography>
      <Box
        display={'flex'}
        justifyContent={'flex-end'}
        flexDirection={'column'}
        gap={3}
        marginTop={2}
        marginBottom={2}
      >
        <TextInput
          label={'Partner'}
          value={form.name}
          onChange={(e) => handleChangeInput('name', e.target.value)}
          placeholder="Enter partner name"
          layout="horizontal"
          labelWidth={150}
          inputWidth={300}
        />
        <Box
          display={'flex'}
          flexDirection={'row'}
          width={'100%'}
          alignItems={'center'}
          gap={2}
        >
          <TextInput
            label={'Admin. ID'}
            value={form.managerUsername}
            onChange={(e) => onUsernameChanged(e.target.value)}
            placeholder="Enter ID"
            layout="horizontal"
            labelWidth={150}
            inputWidth={300}
          />
          <Button
            text={'CHECK'}
            color="secondary"
            onClick={handleCheckUsernameDuplicate}
          />
        </Box>
        <Box display={'flex'} alignItems={'center'} gap={2}>
          <TextInput
            label={'Admin. PW'}
            value={form.managerPassword}
            onChange={(e) =>
              handleChangeInput('managerPassword', e.target.value)
            }
            placeholder="Enter password"
            layout="horizontal"
            labelWidth={150}
            inputWidth={300}
          />
          <Button text={'REGEN'} onClick={handleRegeneratePassword} />
          <Button text={'COPY'} color="gray" onClick={copyPassword} />
        </Box>
        <TextInput
          label={'Manager'}
          value={form.managerName}
          onChange={(e) => handleChangeInput('managerName', e.target.value)}
          placeholder="Enter Manager name"
          layout="horizontal"
          labelWidth={150}
          inputWidth={300}
        />
        <Box
          display={'flex'}
          flexDirection={'row'}
          width={'100%'}
          alignItems={'center'}
          gap={2}
        >
          <TextInput
            label={'Email'}
            value={form.managerEmail}
            onChange={(e) => onEmailChanged(e.target.value)}
            placeholder="Enter email address"
            layout="horizontal"
            labelWidth={150}
            inputWidth={300}
          />
          <Button
            text={'CHECK'}
            color="secondary"
            onClick={handleCheckEmailDuplicate}
          />
        </Box>
        <Box
          display={'flex'}
          flexDirection={'row'}
          width={'100%'}
          alignItems={'center'}
          gap={2}
        >
          <TextInput
            label={'Telephone'}
            value={form.managerTelephone}
            onChange={(e) =>
              handleChangeInput('managerTelephone', e.target.value)
            }
            placeholder="Enter telephone number"
            layout="horizontal"
            labelWidth={150}
            inputWidth={300}
          />
        </Box>
        <TextInput
          label={'Callback URL'}
          value={form.callbackUrl}
          onChange={(e) => handleChangeInput('callbackUrl', e.target.value)}
          placeholder="Enter callback URL"
          layout="horizontal"
          labelWidth={150}
          inputWidth={300}
        />
        <CheckBox
          preLabel={'manualOrder'}
          value={form.manualOrderEnabled}
          onCheck={(e) => handleChangeInput('manualOrderEnabled', e)}
        />
      </Box>
      <Divider />
      <Typography variant="h5" component="h5" marginTop={2}>
        API Parameters
      </Typography>
      <Box
        display={'flex'}
        justifyContent={'flex-end'}
        flexDirection={'column'}
        gap={3}
        marginTop={2}
        marginBottom={2}
      >
        <Box display={'flex'} alignItems={'center'} gap={2}>
          <TextInput
            label={'App ID'}
            value={form.appId}
            onChange={(e) => handleChangeInput('appId', e.target.value)}
            placeholder="Generate App ID"
            layout="horizontal"
            labelWidth={150}
            inputWidth={300}
          />
          <Button text={'REGEN'} onClick={handleRegenerateAppId} />
          <Button text={'COPY'} color="gray" onClick={copyAppId} />
        </Box>
        <Box display={'flex'} alignItems={'center'} gap={2}>
          <TextInput
            label={'App Secret'}
            value={form.appSecret}
            onChange={(e) => handleChangeInput('appSecret', e.target.value)}
            placeholder="Generate App Secret"
            layout="horizontal"
            labelWidth={150}
            inputWidth={300}
          />
          <Button text={'REGEN'} onClick={handleRegenerateAppSecret} />
          <Button text={'COPY'} color="gray" onClick={copyAppSecret} />
        </Box>
      </Box>
      <Box
        display={'flex'}
        flexDirection={'row'}
        mb={4}
        mt={2}
        alignItems={'center'}
        gap={2}
      >
        {/* <FormControl>
          <Box
            display={'flex'}
            flexDirection={'row'}
            gap={2}
            alignItems={'center'}
          >
            <Box width={150} display={'flex'}>
              <Label>Remote IP</Label>
            </Box>
            {form.remoteIps.map((ip) => (
              <Chip
                label={ip}
                key={ip}
                onDelete={() =>
                  handleDeleteRemoteIp(form.remoteIps.indexOf(ip))
                }
              />
            ))}
            <Box display={'flex'} flexDirection={'row'} gap={5}>
              <IconButton onClick={handleClickAddRemoteIp}>
                <AddCircleOutline color="primary" />
              </IconButton>
            </Box>
          </Box>
        </FormControl> */}
      </Box>
      <Box
        display={'flex'}
        flexDirection={'row'}
        justifyContent={'flex-end'}
        gap={2}
      >
        <Button text={'CANCEL'} onClick={handleOnCancel} color={'error'} />
        <Button text={'SAVE'} onClick={handleOnSave} />
      </Box>
      <Modal
        open={addRemoteIp}
        onClose={() => {
          setAddRemoteIp(false);
        }}
      >
        <AddRemoteIpContent
          onClose={() => {
            setAddRemoteIp(false);
          }}
          onAdd={(ip) => {
            setForm((prev) => ({
              ...prev,
              remoteIps: [...prev.remoteIps, ip],
            }));
            setAddRemoteIp(false);
          }}
        />
      </Modal>
    </>
  );
};

export default AddPartner;
