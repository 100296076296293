import { Box } from '@mui/material';
import TextInput from '@components/common/TextInput';
import Select, { SelectValue } from '@components/common/Select';

const UNIT_OPTION_LIST = [
  { value: 'MB', label: 'MB' },
  { value: 'GB', label: 'GB' },
  { value: 'TB', label: 'TB' },
];

type DataInputProps = {
  value: number;
  unit: string | null;
  onChangeValue: (value: number) => void;
  onChangeUnit: (unit: string) => void;
  isDisabled: boolean;
};

const DataInput = ({
  value,
  onChangeValue,
  onChangeUnit,
  unit,
  isDisabled,
}: DataInputProps) => {
  return (
    <Box display={'flex'} flexDirection={'row'} alignItems={'center'} gap={3}>
      <TextInput
        value={value}
        layout="horizontal"
        labelWidth={80}
        type="number"
        disabled={isDisabled}
        onChange={(e) => onChangeValue(Number(e.target.value))}
        inputWidth={80}
      />
      <Select
        value={unit}
        optionList={UNIT_OPTION_LIST}
        setValue={(value: SelectValue) => {
          onChangeUnit(value as string);
        }}
        disabled={isDisabled}
      />
    </Box>
  );
};

export default DataInput;
