import { EsimType } from '@models/stock';
import API from '../API';
import { PageParams, PageResponse } from '@models/api';
import { Status, UploadStatus } from '@models/esim';

export const uploadFile = async (file: File) => {
  const formData = new FormData();

  formData.append('file', file, file.name);

  const { data } = await API.post<PageResponse<any>>(
    '/v1/esim-bulk/upload',
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  );
  return data;
};

export const getUploadHistoriesCount = async () => {
  const { data } = await API.get<PageResponse<number>>(
    '/v1/esim-bulk/upload/count'
  );
  return data.data[0];
};

export type GetUploadHistoriesResponse = {
  esimInventoryBulkUploadId: number;
  createdAt: number;
  filename: string;
  isDeleted: boolean;
  stock: {
    stockCode: string;
    stockName: string;
  };
  updatedAt: number;
  okayCount: number;
  failedCount: number;
  totalCount: number;
  status: Status;
};

export const getUploadHistories = async (params: PageParams) => {
  const { data } = await API.get<PageResponse<GetUploadHistoriesResponse>>(
    '/v1/esim-bulk/upload',
    { params }
  );
  return data;
};

export const getUploadHistory = async (uploadId: number) => {
  const { data } = await API.get<PageResponse<GetUploadHistoriesResponse>>(
    `/v1/esim-bulk/upload/${uploadId}`
  );
  return data;
};

export const deleteUploadHistory = async (uploadId: number) => {
  const { data } = await API.delete(`/v1/esim-bulk/upload/${uploadId}`);
  return data;
};

export const getUploadHistoryDetailsCount = async (uploadId: number) => {
  const { data } = await API.get<PageResponse<number>>(
    `/v1/esim-bulk/upload/${uploadId}/details/count`
  );
  return data.data[0];
};

export type GetUploadHistoryDetailsResponse = {
  createdAt: number;
  esimInventoryBulkUploadDetailId: number;
  esimInventoryBulkUploadId: number;
  esimType: EsimType;
  expireAt: number;
  iccid: string;
  imsi: string;
  lpaUrl: string;
  msisdn: string;
  pin: string;
  puk: string;
  redemptionCode: null;
  serialNo: string;
  stockId: number;
  updatedAt: number;
  uploadStatus: UploadStatus;
};

export type GetUploadHistoryDetailsParams = {
  onlyFailed?: boolean;
};

export const getUploadHistoryDetails = async (
  uploadId: number,
  params: PageParams & GetUploadHistoryDetailsParams
) => {
  const { data } = await API.get<PageResponse<GetUploadHistoryDetailsResponse>>(
    `/v1/esim-bulk/upload/${uploadId}/details`,
    { params }
  );
  return data;
};

export const confirmUpload = async (uploadId: number) => {
  const { data } = await API.post(`/v1/esim-bulk/upload/${uploadId}/confirm`);
  return data;
};
