import { TOKEN_KEY } from '@constants/token';
import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const API = axios.create({
  baseURL: API_BASE_URL,
});

API.interceptors.request.use((config) => {
  const token = window?.localStorage.getItem(TOKEN_KEY);
  if (!token) return config;
  Object.assign(config.headers, { Authorization: `Bearer ${token}` });
  return config;
});

API.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return error.response;
  }
);

export default API;
