import { UserResponse } from '@models/user';
import { atom } from 'recoil';

export const authState = atom<boolean | null>({
  key: 'authState',
  default: null,
});

export const tokenState = atom<string | null>({
  key: 'tokenState',
  default: null,
});

export const userState = atom<UserResponse | null>({
  key: 'userState',
  default: null,
});
