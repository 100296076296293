export function filterSearchParams(obj: Object) {
  const filteredEntries = Object.entries(obj).filter(([key, value]) => {
    return (
      value !== 'undefined' &&
      value !== null &&
      value !== '' &&
      value !== 'NaN' &&
      value !== 'null'
    );
  });
  return Object.fromEntries(filteredEntries);
}
