import Button from '@components/common/Button';
import TextInput from '@components/common/TextInput';
import {
  Box,
  Pagination,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import CheckBox from '@components/common/CheckBox';
import { useNavigate, useParams } from 'react-router-dom';
import useAsyncEffect from '@hooks/useAsyncEffect';
import {
  PartnerProductPriceBulkUpload,
  PartnerProductPriceBulkUploadDetailStatus,
  PartnerProductPriceBulkUploadStatus,
} from '../../models/partnerBulk';
import { usePriceUploadHistory } from '../../queries/partners/usePriceUploadHistory';
import { usePriceUploadDetails } from '../../queries/partners/usePriceUploadDetails';
import { usePriceUploadDetailsCount } from '../../queries/partners/usePriceUploadDetailsCount';
import { confirmPriceUpload } from '../../apis/partnerBulk';

const PAGE_SIZE = 50;

export const PartnerPriceUploadDetail = (): React.ReactElement | null => {
  const { partnerId: partnerIdParam, uploadId: uploadIdParam } = useParams();

  const partnerId = Number(partnerIdParam);
  const uploadId = Number(uploadIdParam);

  const [onlyFailed, setOnlyFailed] = useState(false);
  const [data, setData] = useState<PartnerProductPriceBulkUpload>({
    partnerProductPriceBulkUploadId: 0,
    filename: '',
    isDeleted: false,
    partnerId: 0,
    successCount: 0,
    failedCount: 0,
    totalCount: 0,
    status: PartnerProductPriceBulkUploadStatus.PENDING,
    createdAt: 0,
    updatedAt: 0,
  });

  const [page, setPage] = useState(1);
  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPage(value);
  };
  const navigate = useNavigate();

  const { data: upload, isLoading } = usePriceUploadHistory(
    partnerId,
    uploadId
  );

  const { data: uploadDetailsData } = usePriceUploadDetails({
    partnerId: partnerId,
    uploadId: uploadId,
    page: page,
    pageSize: PAGE_SIZE,
    params: {
      onlyFailed,
    },
  });

  const { data: totalCount } = usePriceUploadDetailsCount(partnerId, uploadId);

  const handleToggleOnlyFailure = () => {
    setOnlyFailed((prev) => !prev);
  };

  const handleOnCancel = () => {
    return navigate(-1);
  };

  const handleOnUpload = async () => {
    const { message } = await confirmPriceUpload(partnerId, uploadId);

    if (message !== 'OK') {
      alert(message);
    } else {
      navigate(`/partners/${partnerId}/prices`);
    }
  };

  useAsyncEffect(async () => {
    if (!uploadId || !upload) return;
    setData(upload?.data[0]);
  }, [upload, uploadId]);

  if (isLoading) return null;
  return (
    <>
      <Typography variant="h4" component="h4">
        Bulk Product Update
      </Typography>
      <Box
        mt={2}
        mb={3}
        gap={2}
        display={'flex'}
        flexDirection={'column'}
        alignItems={'flex-end'}
      >
        <Box
          width={'100%'}
          display={'flex'}
          alignItems={'flex-end'}
          gap={2}
          flexDirection={'row'}
        >
          <TextInput
            label={'File'}
            value={data.filename}
            disabled
            inputWidth={500}
          />
          <TextInput
            label={'Total'}
            disabled
            value={data.totalCount.toLocaleString()}
            inputWidth={200}
          />
          <TextInput
            label={'OK'}
            disabled
            value={data.successCount.toLocaleString()}
            inputWidth={200}
          />
          <TextInput
            label={'Fail'}
            disabled
            value={data.failedCount.toLocaleString()}
            inputWidth={200}
          />
        </Box>
      </Box>

      {/* List Section */}
      <CheckBox
        label={'Only Failure'}
        value={onlyFailed}
        onCheck={handleToggleOnlyFailure}
      />
      <TableContainer
        component={Paper}
        sx={{
          marginTop: 1,
        }}
      >
        <Table sx={{ minWidth: 700 }}>
          <TableHead>
            <TableRow>
              <StyledTableCell>Index</StyledTableCell>
              <StyledTableCell>productCode</StyledTableCell>
              <StyledTableCell>PriceStatus</StyledTableCell>
              <StyledTableCell>DiscountRate</StyledTableCell>
              <StyledTableCell>State</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(uploadDetailsData?.data || []).map((row, index) => (
              <StyledTableRow
                key={row.partnerProductPriceBulkUploadDetailId}
                isError={
                  row.uploadStatus !==
                  PartnerProductPriceBulkUploadDetailStatus.OK
                }
              >
                <StyledTableCell component="th" scope="row">
                  {index + 1}
                </StyledTableCell>
                <StyledTableCell>{row.productCode}</StyledTableCell>
                <StyledTableCell>{row.priceStatus}</StyledTableCell>
                <StyledTableCell>{row.discountRate * 100}</StyledTableCell>
                <StyledTableCell>{row.uploadStatus}</StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {/* Pagination */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          mt: 2,
        }}
      >
        <Pagination
          count={Math.ceil((totalCount ?? 0) / PAGE_SIZE)}
          page={page}
          onChange={handleChangePage}
        />
      </Box>
      {data.status === PartnerProductPriceBulkUploadStatus.PENDING && (
        <Box
          display={'flex'}
          flexDirection={'row'}
          justifyContent={'flex-end'}
          gap={2}
        >
          <Button text={'CANCEL'} onClick={handleOnCancel} color={'error'} />
          <Button text={'UPLOAD'} onClick={handleOnUpload} />
        </Box>
      )}
    </>
  );
};

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#000',
    color: '#fff',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
  },
}));

const StyledTableRow = styled(TableRow)(
  ({ isError }: { isError: boolean }) => ({
    backgroundColor: isError ? '#FDE7E9' : '#fff',
    '&:hover': {
      backgroundColor: isError ? '#FDE7E9' : '#F5F5F5',
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  })
);
