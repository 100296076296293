export enum UserGroup {
  ADMINISTRATOR = 'ADMINISTRATOR',
  PRODUCT_MANAGER = 'PRODUCT_MANAGER',
  MANAGER = 'MANAGER',
  PARTNER = 'PARTNER',
}

export const PERMITTED_USER_GROUP: UserGroup[] = [
  UserGroup.ADMINISTRATOR,
  UserGroup.PRODUCT_MANAGER,
  UserGroup.MANAGER,
];

export enum UserStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export type User = {
  userId: number;
  username: string;
  name: string;
  group: UserGroup;
  status: UserStatus;
  position: string;
  email: string;
  phoneNumber: string;
  recentLoginTime?: string;
  partnerId?: number;
};

export type UserResponse = User & {
  manualOrderEnabled: boolean;
};
