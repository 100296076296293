import { EsimStatus, EsimType } from '@models/stock';
import React, { useState } from 'react';
import {
  Box,
  Pagination,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import Button from '@components/common/Button';
import TextInput from '@components/common/TextInput';
import Select from '@components/common/Select';
import IconButton from '@components/common/IconButton';
import { RemoveFromQueueRounded } from '@mui/icons-material';
import Modal from '@components/common/Modal';
import RefundEsimContent from './RefundEsimContent';
import FullRefundEsimContent from './FullRefundEsimContent';
import { useOrderEsims } from '@queries/orders/useOrderEsims';
import {
  exportOrderDetailEsimsExcel,
  GetOrderEsimsParams,
  OrderEsimResponse,
  OrderParameterType,
  OrderResponse,
  PassportData,
} from '@apis/order';
import { useOrderEsimsCount } from '@queries/orders/useOrderEsimsCount';
import { downloadBlob } from '@utils/file';
import { timestampToDate } from '@utils/time';
import { utcToZonedTime } from 'date-fns-tz';
import { KOREAN_TIME_ZONE } from '@constants/timezone';
import { useRecoilValue } from 'recoil';
import { userState } from '@recoils/atoms';
import { UserGroup } from '@models/user';

const PAGE_SIZE = 50;

type EsimListTableProps = {
  isRefundable: boolean;
  order: OrderResponse;
  fullRefund: boolean;
  setFullRefund: React.Dispatch<React.SetStateAction<boolean>>;
  onRefund: () => void;
};

const SEARCH_TYPE: Record<EsimType, OrderParameterType[]> = {
  [EsimType.LPA]: ['LPA_URL', 'ICCID', 'MSISDN'],
  [EsimType.REDEMPTION]: ['REDEMPTION_CODE', 'LPA_URL', 'ICCID', 'MSISDN'],
  [EsimType.DISCOVERY]: ['IMEI', 'EID', 'ACTIVATION_DATE'],
};

const EsimListTable = ({
  isRefundable,
  order,
  fullRefund,
  setFullRefund,
  onRefund,
}: EsimListTableProps) => {
  const [searchForm, setSearchForm] = useState<GetOrderEsimsParams>({
    esimType: order.stock.esimType,
    parameterType: SEARCH_TYPE[order.stock.esimType][0],
    parameterValue: '',
  });
  const user = useRecoilValue(userState);
  const [partialRefund, setPartialRefund] = useState(false);
  const [page, setPage] = useState(1);
  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPage(value);
  };

  const { data, refetch } = useOrderEsims({
    orderId: order.orderId,
    page,
    searchParams: searchForm,
  });

  const { data: totalCount, refetch: refetchCount } = useOrderEsimsCount(
    order.orderId,
    searchForm
  );

  const [selectedEsim, setSelectedEsim] = useState<OrderEsimResponse | null>(
    null
  );

  const handleOnSearch = () => {
    refetch();
    refetchCount();
  };

  const handleOnExport = async () => {
    const res = await exportOrderDetailEsimsExcel(order.orderId, searchForm);
    const nowInUTC = new Date();
    const kstDate = utcToZonedTime(nowInUTC, KOREAN_TIME_ZONE);
    const fileName = `esim-inventories-${kstDate.toISOString()}.xlsx`;
    downloadBlob(res, fileName);
  };

  const handleRefund = (esim: OrderEsimResponse) => {
    setSelectedEsim(esim);
    setPartialRefund(true);
  };

  const hasPassport = (data?.data ?? [])
    .map((esim) => esim.customerDetail?.passportData)
    .filter((passport): passport is PassportData => !!passport)
    .find(
      (passport) =>
        !!passport.passportBirthdate ||
        !!passport.passportCountry ||
        !!passport.passportGender ||
        !!passport.passportName ||
        !!passport.passportNumber
    );
  return (
    <>
      {/* List Section */}
      <Typography variant="h4" component="h4">
        eSIM List ({totalCount} in total)
      </Typography>
      {/* Search Section */}
      <Box
        width={'100%'}
        display={'flex'}
        gap={2}
        flexDirection={'row'}
        alignItems={'flex-end'}
        mt={2}
      >
        {/* search type  */}
        <Select
          value={searchForm.parameterType}
          setValue={(value) => {
            setSearchForm((prev) => ({
              ...prev,
              parameterType: value as OrderParameterType,
            }));
          }}
          optionList={SEARCH_TYPE[searchForm.esimType].map((type) => ({
            value: type,
            label: type,
          }))}
          placeholder="Select type"
        />
        {/* search keyword  */}
        <TextInput
          value={searchForm.parameterValue}
          onChange={(e) => {
            setSearchForm((prev) => ({
              ...prev,
              parameterValue: e.target.value,
            }));
          }}
          inputWidth={400}
          placeholder="Enter keyword"
        />
        <Button text={'Search'} onClick={handleOnSearch} />
        <Button text={'Export'} onClick={handleOnExport} color={'secondary'} />
      </Box>
      {/* EsimType에 따라서 달라짐 */}
      <TableContainer
        component={Paper}
        sx={{
          marginTop: 2,
        }}
      >
        <Table sx={{ minWidth: 700 }}>
          <TableHead>
            <TableRow>
              <StyledTableCell>Index</StyledTableCell>
              {searchForm.esimType === EsimType.LPA && (
                <>
                  <StyledTableCell>LPA URI</StyledTableCell>
                  <StyledTableCell>ICCID</StyledTableCell>
                  <StyledTableCell>IMSI</StyledTableCell>
                  <StyledTableCell>Serial No.</StyledTableCell>
                  <StyledTableCell>MSISDN</StyledTableCell>
                  <StyledTableCell>PIN</StyledTableCell>
                  <StyledTableCell>PUK</StyledTableCell>
                  <StyledTableCell>Exp. date</StyledTableCell>
                  {hasPassport && (
                    <>
                      <StyledTableCell>PassportCountry</StyledTableCell>
                      <StyledTableCell>PassportName</StyledTableCell>
                      <StyledTableCell>PassportBirthdate</StyledTableCell>
                      <StyledTableCell>PassportGender</StyledTableCell>
                      <StyledTableCell>PassportNumber</StyledTableCell>
                    </>
                  )}
                </>
              )}
              {searchForm.esimType === EsimType.REDEMPTION && (
                <>
                  <StyledTableCell>Redemption Code</StyledTableCell>
                  <StyledTableCell>LPA URI</StyledTableCell>
                  <StyledTableCell>ICCID</StyledTableCell>
                  <StyledTableCell>IMSI</StyledTableCell>
                  <StyledTableCell>Serial No.</StyledTableCell>
                  <StyledTableCell>MSISDN</StyledTableCell>
                  <StyledTableCell>PIN</StyledTableCell>
                  <StyledTableCell>PUK</StyledTableCell>
                  <StyledTableCell>Exp. date</StyledTableCell>
                  {hasPassport && (
                    <>
                      <StyledTableCell>PassportCountry</StyledTableCell>
                      <StyledTableCell>PassportName</StyledTableCell>
                      <StyledTableCell>PassportBirthdate</StyledTableCell>
                      <StyledTableCell>PassportGender</StyledTableCell>
                      <StyledTableCell>PassportNumber</StyledTableCell>
                    </>
                  )}
                </>
              )}
              {searchForm.esimType === EsimType.DISCOVERY && (
                <>
                  <StyledTableCell>Activation Date</StyledTableCell>
                  <StyledTableCell>Validity (Days)</StyledTableCell>
                  <StyledTableCell>IMEI</StyledTableCell>
                  <StyledTableCell>EID</StyledTableCell>
                  {hasPassport && (
                    <>
                      <StyledTableCell>PassportCountry</StyledTableCell>
                      <StyledTableCell>PassportName</StyledTableCell>
                      <StyledTableCell>PassportBirthdate</StyledTableCell>
                      <StyledTableCell>PassportGender</StyledTableCell>
                      <StyledTableCell>PassportNumber</StyledTableCell>
                    </>
                  )}
                </>
              )}
              {user?.group !== UserGroup.PARTNER && (
                <StyledTableCell>Refund</StyledTableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {(data?.data || []).map((esim, index) => (
              <StyledTableRow key={esim.esimId}>
                <StyledTableCell component="th" scope="row">
                  {index + 1}
                </StyledTableCell>
                {esim.esimType === EsimType.LPA && (
                  <>
                    <StyledTableCell component="th" scope="row">
                      {esim.lpaUrl}
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row">
                      {esim.iccid}
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row">
                      {esim.imei}
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row">
                      {esim.serialNo}
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row">
                      {esim.msisdn}
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row">
                      {esim.pin}
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row">
                      {esim.puk}
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row">
                      {timestampToDate(esim.expireAt || '', 'YYYY-MM-DD')}
                    </StyledTableCell>
                    {hasPassport && (
                      <>
                        <StyledTableCell component="th" scope="row">
                          {esim.customerDetail?.passportData?.passportCountry}
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                          {esim.customerDetail?.passportData?.passportName}
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                          {esim.customerDetail?.passportData?.passportBirthdate}
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                          {esim.customerDetail?.passportData?.passportGender}
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                          {esim.customerDetail?.passportData?.passportNumber}
                        </StyledTableCell>
                      </>
                    )}
                  </>
                )}
                {esim.esimType === EsimType.REDEMPTION && (
                  <>
                    <StyledTableCell component="th" scope="row">
                      {esim.redemptionCode}
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row">
                      {esim.lpaUrl}
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row">
                      {esim.iccid}
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row">
                      {esim.imei}
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row">
                      {esim.serialNo}
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row">
                      {esim.msisdn}
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row">
                      {esim.pin}
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row">
                      {esim.puk}
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row">
                      {timestampToDate(esim.expireAt || '', 'YYYY-MM-DD')}
                    </StyledTableCell>
                    {hasPassport && (
                      <>
                        <StyledTableCell component="th" scope="row">
                          {esim.customerDetail?.passportData?.passportCountry}
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                          {esim.customerDetail?.passportData?.passportName}
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                          {esim.customerDetail?.passportData?.passportBirthdate}
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                          {esim.customerDetail?.passportData?.passportGender}
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                          {esim.customerDetail?.passportData?.passportNumber}
                        </StyledTableCell>
                      </>
                    )}
                  </>
                )}
                {esim.esimType === EsimType.DISCOVERY && (
                  <>
                    <StyledTableCell component="th" scope="row">
                      {esim.activationDate
                        ? timestampToDate(esim.activationDate)
                        : '-'}
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row">
                      {esim.validDays}
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row">
                      {esim.imei}
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row">
                      {esim.eid}
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row">
                      {esim.customerName}
                    </StyledTableCell>
                    {hasPassport && (
                      <>
                        <StyledTableCell component="th" scope="row">
                          {esim.customerDetail?.passportData?.passportCountry}
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                          {esim.customerDetail?.passportData?.passportName}
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                          {esim.customerDetail?.passportData?.passportBirthdate}
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                          {esim.customerDetail?.passportData?.passportGender}
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                          {esim.customerDetail?.passportData?.passportNumber}
                        </StyledTableCell>
                      </>
                    )}
                  </>
                )}
                {user?.group !== UserGroup.PARTNER &&
                  isRefundable &&
                  esim.esimStatus !== EsimStatus.CANCELED && (
                    <StyledTableCell component="th" scope="row">
                      <IconButton onClick={() => handleRefund(esim)}>
                        <RemoveFromQueueRounded />
                      </IconButton>
                    </StyledTableCell>
                  )}
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          mt: 2,
        }}
      >
        <Pagination
          count={Math.ceil((totalCount ?? 0) / PAGE_SIZE)}
          page={page}
          onChange={handleChangePage}
        />
      </Box>
      <Modal
        open={partialRefund}
        onClose={() => {
          setPartialRefund(false);
        }}
      >
        <RefundEsimContent
          onClose={(refresh: boolean) => {
            setPartialRefund(false);
            if (refresh) {
              onRefund();
              refetch();
            }
          }}
          selectedEsim={selectedEsim}
          order={order}
        />
      </Modal>
      <Modal
        open={fullRefund}
        onClose={() => {
          setFullRefund(false);
        }}
      >
        <FullRefundEsimContent
          onClose={(refresh: boolean) => {
            setFullRefund(false);
            if (refresh) {
              onRefund();
              refetch();
            }
          }}
          order={order}
          totalCount={order.reservedAt ? order.quantity : totalCount || 0}
        />
      </Modal>
    </>
  );
};

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#000',
    color: '#fff',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(() => ({
  backgroundColor: '#FFFFFF',
  '&:hover': {
    backgroundColor: '#F3F6F9',
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export default EsimListTable;
