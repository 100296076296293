import Button from '@components/common/Button';
import TextInput from '@components/common/TextInput';
import {
  Box,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
  tableCellClasses,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ProductStatus } from '@models/product';
import { toUsdDisplay } from '../../utils/money';
import { useProductList } from '@queries/products/useProductList';
import { GetProductsParams, ProductSaleType,  } from '@apis/product';
import { useCountryList } from '@queries/countries/useCountryList';
import { SelectValue } from '@components/common/Select';
import useAsyncEffect from '@hooks/useAsyncEffect';
import { useAllStockList } from '@queries/stocks/useAllStockList';
import { useProductCount } from '@queries/products/useProductCount';
import { filterSearchParams } from '@utils/search';
import _ from 'lodash';
import SearchSelect from '../../components/common/SearchSelect';
type ProductSearchForm = GetProductsParams;
const PAGE_SIZE = 50;

const Products = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchForm, setSearchForm] = useState<ProductSearchForm>({
    productCode: searchParams.get('productCode') || '',
    productSaleType:
      (searchParams.get('productSaleType') as ProductSaleType) || undefined,
    productName: searchParams.get('productName') || '',
    countryId: searchParams.get('countryId')
      ? Number(searchParams.get('countryId'))
      : undefined,
    stockId: searchParams.get('stockId')
      ? Number(searchParams.get('stockId'))
      : undefined,
    productStatus:
      (searchParams.get('productStatus') as ProductStatus) || undefined,
  });

  const [needsRefetch, setNeedsRefetch] = useState(false);

  const [page, setPage] = useState(
    searchParams.get('page') ? Number(searchParams.get('page')) : 1
  );
  const navigate = useNavigate();

  const handleOnAdd = () => {
    navigate('/products/add');
  };

  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPage(value);
    setSearchParams({
      ...filterSearchParams(
        Object.fromEntries(new URLSearchParams(searchForm as any).entries())
      ),
      page: value.toString(),
    });
  };

  const { data, refetch, isLoading } = useProductList({
    page,
    searchParam: searchForm,
  });

  const {
    data: totalCount,
    refetch: refetchCount,
    isLoading: isLoadingCount,
  } = useProductCount(searchForm);

  const { data: countriesData } = useCountryList();
  const { data: stocksData } = useAllStockList();
  const [countryOptionList, setCountryOptionList] = useState<
    { value: SelectValue; label: string }[]
  >([]);
  const [stockOptionList, setStockOptionList] = useState<
    { value: SelectValue; label: string }[]
  >([]);

  const handleOnSearch = () => {
    refetch();
    refetchCount();
    setSearchParams({
      ...filterSearchParams(
        Object.fromEntries(new URLSearchParams(searchForm as any).entries())
      ),
    });
  };

  const handleChangeInput = (
    name: keyof ProductSearchForm,
    value: SelectValue
  ) => {
    setSearchForm((prev) => ({ ...prev, [name]: value }));
  };

  useAsyncEffect(async () => {
    if (!countriesData) return;
    setCountryOptionList(
      _.sortBy(
        countriesData?.data.map((c) => ({
          label: c.englishName,
          value: c.countryId,
          englishName: c.englishName,
        })),
        (data) => data.label
      )
    );
  }, [countriesData]);

  useAsyncEffect(async () => {
    if (!stocksData) return;
    setStockOptionList(
      stocksData?.data.map((s) => ({
        label: s.stockCode,
        value: s.stockId,
      }))
    );
  }, [stocksData]);

  useEffect(() => {
    if (!searchParams.size) {
      setSearchForm({});
      setPage(1);
      setNeedsRefetch(true);
    }
  }, [searchParams]);

  useEffect(() => {
    if (needsRefetch && !isLoading && !isLoadingCount) {
      refetch();
      refetchCount();
      setNeedsRefetch(false);
    }
  }, [needsRefetch, refetch, refetchCount, isLoading, isLoadingCount]);

  return (
    <>
      <Typography variant="h4" component="h4">
        Products
      </Typography>
      <Box display={'flex'} justifyContent={'flex-end'}>
        <Button text={'Add'} onClick={handleOnAdd} />
      </Box>
      {/* Search Section */}
      <Box mt={2} mb={3} display={'flex'} alignItems={'flex-end'} gap={2}>
        <TextInput
          label={'Product Code'}
          value={searchForm.productCode || ''}
          onChange={(e) => handleChangeInput('productCode', e.target.value)}
          placeholder="Enter the product code"
        />
        <TextInput
          label={'Product Name'}
          value={searchForm.productName || ''}
          onChange={(e) => handleChangeInput('productName', e.target.value)}
          placeholder="Enter the product name"
        />
        <SearchSelect
          disablePortal
          label="Country"
          options={countryOptionList}
          value={
            countryOptionList.find(
              (c) =>
                c.value ===
                (Number(searchParams.get('countryId')) || searchForm.countryId)
            ) || null
          }
          setValue={(value) => {
            handleChangeInput('countryId', value?.value as number);
          }}
          placeholder="Select the country"
        />
        <SearchSelect
          disablePortal
          label={'Stock Code'}
          options={stockOptionList}
          placeholder="Enter the Stock Code"
          value={
            stockOptionList.find(
              (c) =>
                c.value ===
                (searchForm.stockId || Number(searchParams.get('stockId')))
            ) || null
          }
          setValue={(value) =>
            handleChangeInput('stockId', value?.value as number)
          }
        />
        <SearchSelect
          disablePortal
          label={'State'}
          options={Object.values(ProductStatus).map((s) => ({
            label: s,
            value: s,
          }))}
          placeholder="Enter the state"
          value={
            Object.values(ProductStatus)
              .map((s) => ({
                label: s,
                value: s,
              }))
              .find((s) => s.value === searchForm.productStatus) || null
          }
          setValue={(value) =>
            handleChangeInput('productStatus', value?.value || null)
          }
        />
        <SearchSelect
          disablePortal
          label={'Sale Type'}
          options={Object.values(ProductSaleType).map((s) => ({
            label: s,
            value: s,
          }))}
          placeholder="Enter the Product Sale Type"
          value={
            Object.values(ProductSaleType)
              .map((s) => ({
                label: s,
                value: s,
              }))
              .find((s) => s.value === searchForm.productSaleType) || null
          }
          setValue={(value) =>
            handleChangeInput('productSaleType', value?.value || null)
          }
        />
        <Button text={'Search'} onClick={handleOnSearch} />
      </Box>

      {/* List Section */}
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }}>
          <TableHead>
            <TableRow>
              <StyledTableCell>Index</StyledTableCell>
              <StyledTableCell>Product Code</StyledTableCell>
              <StyledTableCell>Product Name</StyledTableCell>
              <StyledTableCell>Stock Code</StyledTableCell>
              <StyledTableCell>Product Sale Type</StyledTableCell>
              <StyledTableCell>Price (USD)</StyledTableCell>
              <StyledTableCell>Shipped (Last 1 week)</StyledTableCell>
              <StyledTableCell>Shipped (Total)</StyledTableCell>
              <StyledTableCell>Refunded</StyledTableCell>
              <StyledTableCell>Status</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(data?.data || []).map((row, index) => (
              <StyledTableRow
                key={row.productId}
                onClick={() => {
                  navigate(`/products/${row.productId}`);
                }}
              >
                <StyledTableCell component="th" scope="row">
                  {index + 1}
                </StyledTableCell>
                <StyledTableCell>{row.productCode}</StyledTableCell>
                <StyledTableCell>{row.productName}</StyledTableCell>
                <StyledTableCell>{row.stock.stockCode}</StyledTableCell>
                <StyledTableCell>{row.productSaleType}</StyledTableCell>
                <StyledTableCell>{toUsdDisplay(row.price)}</StyledTableCell>
                <StyledTableCell>{row.lastWeekShippedCount}</StyledTableCell>
                <StyledTableCell>{row.totalShippedCount}</StyledTableCell>
                <StyledTableCell>{row.refundedCount}</StyledTableCell>
                <StyledTableCell>{row.productStatus}</StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {/* Pagination */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          mt: 2,
        }}
      >
        <Pagination
          count={Math.ceil((totalCount ?? 0) / PAGE_SIZE)}
          page={page}
          onChange={handleChangePage}
        />
      </Box>
    </>
  );
};

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#000',
    color: '#fff',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(() => ({
  backgroundColor: '#FFFFFF',
  '&:hover': {
    backgroundColor: '#F3F6F9',
    cursor: 'pointer',
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export default Products;
