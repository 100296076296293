import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { getPartnerTransactionCount } from '@apis/partner';

export const usePartnerTransactionCount = (partnerId: number) => {
  const queryOptions: UseQueryOptions<number, Error> = {
    queryKey: ['partnerTransactionsCount', partnerId],
    queryFn: () => {
      return getPartnerTransactionCount(partnerId);
    },
    enabled: !!partnerId,
  };
  return useQuery(queryOptions);
};
