import Button from '@components/common/Button';
import TextInput from '@components/common/TextInput';
import { PartnerProductPriceResponse } from '@models/partner';
import { styled, TableCell, tableCellClasses, TableRow } from '@mui/material';
import React, { useState } from 'react';
import { toUsdDisplay } from '../../utils/money';
import { CheckBox } from '@components/common/CheckBox';
import _ from 'lodash';
import { Decimal } from 'decimal.js';

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#000',
    color: '#fff',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(() => ({
  backgroundColor: '#FFFFFF',
  '&:hover': {
    backgroundColor: '#F3F6F9',
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export const PartnerPriceRow = ({
  baseDiscountRate,
  price,
  setPrice,
  applyPrice,
}: {
  baseDiscountRate: number;
  price: PartnerProductPriceResponse;
  setPrice: (price: PartnerProductPriceResponse) => void;
  applyPrice: (productId: number) => Promise<void>;
}): React.ReactElement => {
  const [discountRate, setDiscountRate] = useState<string | undefined>(
    (100 * (price.discountRate ?? 0)).toString()
  );

  const updateDiscountRate = async (discountRate: number) => {
    setPrice({
      ...price,
      discountRate,
    });
  };

  const updateVisibility = async (visible: boolean) => {
    setPrice({
      ...price,
      status: visible ? 'VISIBLE' : 'HIDDEN',
    });
  };

  return (
    <>
      <StyledTableRow key={price.productCode}>
        <StyledTableCell>{`[${price.productCode}] ${price.productName}`}</StyledTableCell>
        <StyledTableCell>{toUsdDisplay(price.originalPrice)}</StyledTableCell>
        <StyledTableCell>
          {toUsdDisplay(
            price.originalPrice * (1 - (price.discountRate || baseDiscountRate))
          )}
        </StyledTableCell>
        <StyledTableCell>
          <TextInput
            value={discountRate}
            type="number"
            layout="horizontal"
            onChange={(e) => {
              if (e.target.value.length === 0 || e.target.value === '-') {
                setDiscountRate(e.target.value);
                return;
              }
              const newValue = new Decimal(e.target.value)
                .mul(100)
                .floor()
                .div(100);
              if (newValue.toNumber() > 100) {
                return;
              }
              updateDiscountRate(newValue.div(100).toNumber());
              setDiscountRate(newValue.toString());
            }}
            inputWidth={100}
          />
        </StyledTableCell>
        <StyledTableCell>
          <CheckBox
            value={price.status === 'VISIBLE'}
            onCheck={(checked) => {
              updateVisibility(checked);
            }}
          />
        </StyledTableCell>
        <StyledTableCell>{price.productSaleType}</StyledTableCell>
        <StyledTableCell>{price.productStatus}</StyledTableCell>
        <StyledTableCell>{price.stockCode}</StyledTableCell>
        <StyledTableCell>
          <Button text={'APPLY'} onClick={() => applyPrice(price.productId)} />
        </StyledTableCell>
      </StyledTableRow>
    </>
  );
};
