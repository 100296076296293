import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { getPriceUploadHistoriesCount } from '../../apis/partnerBulk';

export const usePriceUploadHistoriesCount = (partnerId: number) => {
  const queryOptions: UseQueryOptions<number, Error> = {
    queryKey: ['priceUploadHistoriesCount', partnerId],
    queryFn: () => {
      return getPriceUploadHistoriesCount(partnerId);
    },
  };
  return useQuery(queryOptions);
};
