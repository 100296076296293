import * as React from 'react';
import { useState } from 'react';
import { Box } from '@mui/material';
import { CloseOutlined } from '@mui/icons-material';
import Label from '@components/common/Label';
import IconButton from '@components/common/IconButton';
import Button from '@components/common/Button';
import { useCountryList } from '@queries/countries/useCountryList';
import useAsyncEffect from '@hooks/useAsyncEffect';
import _ from 'lodash';
import SearchSelect, { SearchSelectValue } from '../common/SearchSelect';

type Country = {
  countryId: number;
  englishName: string;
};

type AddCoverageContentProps = {
  onClose: () => void;
  onAdd: (data: Country) => void;
};

const AddCoverageContent = ({ onClose, onAdd }: AddCoverageContentProps) => {
  const [form, setForm] = React.useState<Country>({
    countryId: 0,
    englishName: '',
  });
  const { data: countriesList } = useCountryList();

  const [countryOptionList, setCountryOptionList] = useState<
    (SearchSelectValue & { englishName: string })[]
  >([]);

  const onCountrySelected = (value: number | null) => {
    if (!value) {
      return;
    }
    const country = countryOptionList.find((c) => c.value === value);
    if (!country) {
      return;
    }
    setForm({
      countryId: value,
      englishName: country?.englishName,
    });
  };

  const handleOnAdd = () => {
    if (!form) return;
    onAdd(form);
  };

  useAsyncEffect(async () => {
    if (!countriesList) return;
    setCountryOptionList(
      _.sortBy(
        countriesList?.data.map((c) => ({
          label: c.englishName,
          value: c.countryId,
          englishName: c.englishName,
        })),
        (data) => data.englishName
      )
    );
  }, [countriesList]);

  return (
    <Box display={'flex'} flexDirection={'column'} minHeight={300}>
      <Box
        display="flex"
        width={'100%'}
        flexDirection="row"
        justifyContent={'space-between'}
      >
        <Box display={'flex'}>
          <Label>Add Coverage</Label>
        </Box>
        <IconButton onClick={onClose}>
          <CloseOutlined />
        </IconButton>
      </Box>
      <Box display="flex" width={'100%'} flexDirection="column" gap={2}>
        <SearchSelect
          disablePortal
          label="Country"
          options={countryOptionList}
          value={
            countryOptionList.find((c) => c.value === form.countryId) || null
          }
          setValue={(value) => onCountrySelected(value?.value as number)}
          placeholder="Select the country"
        />
      </Box>

      <Box display={'flex'} mt={3}>
        <Button text={'ADD'} onClick={handleOnAdd} />
      </Box>
    </Box>
  );
};

export default AddCoverageContent;
