import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { GetStocksParams, getStockCount } from '@apis/stock';
import { dropEmptyValues } from '../../utils/common';

export const useStockCount = (searchParam: GetStocksParams) => {
  const queryOptions: UseQueryOptions<number, Error> = {
    queryKey: ['stocksCount', searchParam.ordering],
    queryFn: () => {
      return getStockCount(
        dropEmptyValues(searchParam, [
          'stockCode',
          'stockName',
          'regionId',
          'supplierId',
          'status',
          'ordering',
        ])
      );
    },
  };
  return useQuery(queryOptions);
};
