import { Box, Typography } from '@mui/material';

import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import KoreanMessage from './messages-error-ko.json';
import EnglishMessage from './messages-error-en.json';
import JapaneseMessage from './messages-error-ja.json';
import SimplifiedChineseMessage from './messages-error-zh-CN.json';
import TraditionalChineseMessage from './messages-error-zh-TW.json';

type CountryCode = 'KR' | 'JP' | 'US' | 'CN' | 'TW';
type MessageKeys = keyof typeof KoreanMessage;

const getMessage = (lang: CountryCode, key: MessageKeys) => {
  switch (lang) {
    case 'KR':
      return KoreanMessage[key];
    case 'JP':
      return JapaneseMessage[key];
    case 'CN':
      return SimplifiedChineseMessage[key];
    case 'TW':
      return TraditionalChineseMessage[key];
    default:
      return EnglishMessage[key];
  }
};

export const ExchangeError = (): React.ReactElement => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const countryCode = searchParams.get('locale')
    ? (searchParams.get('locale') as CountryCode)
    : 'US';
  return (
    <>
      <Box
        width={600}
        height={1024}
        border="1px black solid"
        textAlign="center"
        margin="0 auto"
      >
        <Box marginTop="100px" marginLeft={'60px'}>
          <img
            src="https://admin.esimsta.com/resources/static/error_symbol.png"
            width={240}
            height={240}
          />
        </Box>
        <Box>
          <Typography
            color="black"
            fontSize={41}
            fontFamily="Pretendard"
            fontWeight="800"
          >
            {getMessage(countryCode, 'str_error_title')}
          </Typography>
        </Box>
        <Box>
          <Typography
            color="black"
            fontSize={18}
            fontFamily="Pretendard"
            fontWeight="600"
            whiteSpace={'pre-wrap'}
          >
            {getMessage(countryCode, 'str_error_desctiption')}
          </Typography>
        </Box>

        <Box
          width={350}
          height={70}
          bgcolor={'#00728C'}
          borderRadius="10px"
          display={'inline-flex'}
          alignItems={'center'}
          justifyContent={'center'}
          marginTop="70px"
          onClick={() => navigate('/exchange')}
          style={{
            cursor: 'pointer',
          }}
        >
          <Typography
            color="white"
            fontSize={26}
            fontFamily="Pretendard"
            fontWeight="700"
            whiteSpace={'break-word'}
          >
            {getMessage(countryCode, 'str_error_reload')}
          </Typography>
          <img
            src="https://admin.esimsta.com/resources/static/refresh.png"
            width={'30px'}
            height={'30px'}
          />
        </Box>
      </Box>
    </>
  );
};
