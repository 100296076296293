import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { PageResponse } from '@models/api';
import { getSales, GetSalesResponse } from '@apis/summary';
import { getTimestampRangeByPeriod } from '@utils/time';

export const useSummarySalesByPeriod = () => {
  const queryOptions: UseQueryOptions<PageResponse<GetSalesResponse>, Error> = {
    queryKey: ['dashboardSummaySalesByPeriod'],
    queryFn: () => {
      const { from, to } = getTimestampRangeByPeriod('WEEKS');
      return getSales({ periodUnit: 'DAYS', from, to });
    },
  };
  return useQuery(queryOptions);
};
