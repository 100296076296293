import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { PageResponse } from '@models/api';
import { SupplierResponse, getSupplier } from '@apis/supplier';

export const useSupplierDetail = (supplierId: number) => {
  const queryOptions: UseQueryOptions<PageResponse<SupplierResponse>, Error> = {
    queryKey: ['supplierDetail', supplierId],
    queryFn: () => {
      return getSupplier(supplierId);
    },
    refetchOnMount: true,
  };
  return useQuery(queryOptions);
};
