import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { PageResponse } from '@models/api';
import { EsimInventoryEventResponse, getEsimInventoryEvents } from '@apis/esim';

export const useInventoryEvents = (esimInventoryId: number) => {
  const queryOptions: UseQueryOptions<
    PageResponse<EsimInventoryEventResponse>,
    Error
  > = {
    queryKey: ['inventoryEvents', esimInventoryId],
    queryFn: () => {
      return getEsimInventoryEvents(esimInventoryId);
    },
  };
  return useQuery(queryOptions);
};
