import API from '../API';
import { PageParams, PageResponse } from '@models/api';
import { Status, UpdateStatus } from '@models/esim';

export const updateFile = async (file: File) => {
  const formData = new FormData();

  formData.append('file', file, file.name);

  const { data } = await API.post<PageResponse<any>>(
    '/v1/esim-bulk/update',
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  );
  return data;
};

export const getUpdateHistoriesCount = async () => {
  const { data } = await API.get<PageResponse<number>>(
    '/v1/esim-bulk/update/count'
  );
  return data.data[0];
};

export type GetUpdateHistoriesResponse = {
  esimInventoryBulkUpdateId: number;
  createdAt: number;
  filename: string;
  isDeleted: boolean;
  stock: {
    stockCode: string;
    stockName: string;
  };
  updatedAt: number;
  okayCount: number;
  failedCount: number;
  totalCount: number;
  status: Status;
};

export const getUpdateHistories = async (params: PageParams) => {
  const { data } = await API.get<PageResponse<GetUpdateHistoriesResponse>>(
    '/v1/esim-bulk/update',
    { params }
  );
  return data;
};

export const getUpdateHistory = async (updateId: number) => {
  const { data } = await API.get<PageResponse<GetUpdateHistoriesResponse>>(
    `/v1/esim-bulk/update/${updateId}`
  );
  return data;
};

export const deleteUpdateHistory = async (updateId: number) => {
  const { data } = await API.delete(`/v1/esim-bulk/update/${updateId}`);
  return data;
};

export const getUpdateHistoryDetailsCount = async (updateId: number) => {
  const { data } = await API.get<PageResponse<number>>(
    `/v1/esim-bulk/update/${updateId}/details/count`
  );
  return data.data[0];
};

export type GetUpdateDetailsResponse = {
  createdAt: number;
  esimInventoryBulkUpdateDetailId: number;
  esimInventoryBulkUpdateId: number;
  esimInventoryId: number;
  esimStatusFrom?: string;
  esimStatusTo: string;
  expireAtFrom?: number;
  expireAtTo: number;
  lpaUrl: string;
  redemptionCode: null;
  stockId: number;
  updatedAt: number;
  updateStatus: UpdateStatus;
};

export type GetUpdateHistoryDetailsParams = {
  onlyFailed?: boolean;
};

export const getUpdateHistoryDetails = async (
  updateId: number,
  params: PageParams & GetUpdateHistoryDetailsParams
) => {
  const { data } = await API.get<PageResponse<GetUpdateDetailsResponse>>(
    `/v1/esim-bulk/update/${updateId}/details`,
    { params }
  );
  return data;
};

export const confirmUpdate = async (updateId: number) => {
  const { data } = await API.post(`/v1/esim-bulk/update/${updateId}/confirm`);
  return data;
};
