import { Divider, ListItem, ListItemText } from '@material-ui/core';
import Drawer from '@mui/material/Drawer';
import { useNavigate } from 'react-router-dom';
import Toolbar from '@mui/material/Toolbar';
import ListItemButton from '@mui/material/ListItemButton';
import React, { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { userState } from '@recoils/atoms';
import { UserGroup } from '@models/user';
import {
  ADMIN_MENU_LIST,
  PARTNER_MENU_LIST_WITH_MANUAL_ORDER,
  PARTNER_MENU_LIST,
} from '@constants/menu';
import useAuth from '@hooks/useAuth';

const LeftSideBar = () => {
  const navigate = useNavigate();
  const user = useRecoilValue(userState);
  const { isAuthenticated } = useAuth();

  const menuList = useMemo(() => {
    if (!user || !isAuthenticated) return [];
    const { group } = user;
    if (
      group === UserGroup.ADMINISTRATOR ||
      group === UserGroup.PRODUCT_MANAGER ||
      group === UserGroup.MANAGER
    ) {
      return ADMIN_MENU_LIST;
    }
    if (group === UserGroup.PARTNER) {
      if (user.manualOrderEnabled) {
        return PARTNER_MENU_LIST_WITH_MANUAL_ORDER;
      } else {
        return PARTNER_MENU_LIST;
      }
    } else {
      return [];
    }
  }, [user, isAuthenticated]);

  const handleClick = (index: number) => {
    navigate(menuList[index].subMenu[0].path);
  };

  return (
    <Drawer
      variant="permanent"
      PaperProps={{
        sx: { width: WIDTH },
      }}
      sx={{
        width: WIDTH,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: {
          width: WIDTH,
          boxSizing: 'border-box',
        },
      }}
    >
      <Toolbar />
      {menuList.map((item, index) => (
        <React.Fragment key={item.menu}>
          <ListItem
            style={{
              backgroundColor: '#f5f5f5',
            }}
          >
            <ListItemButton onClick={() => handleClick(index)}>
              <ListItemText primary={item.menu} />
            </ListItemButton>
          </ListItem>
          {item.subMenu.map((text, index) => (
            <ListItem key={text.name}>
              <ListItemButton
                onClick={() => {
                  navigate(text.path);
                }}
              >
                <ListItemText primary={text.name} />
              </ListItemButton>
            </ListItem>
          ))}
        </React.Fragment>
      ))}
      <Divider />
    </Drawer>
  );
};

const WIDTH = 240;

export default LeftSideBar;
