import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { getUpdateHistoryDetailsCount } from '@apis/esimBulk/update';

export const useUpdateDetailsCount = (updateId: number) => {
  const queryOptions: UseQueryOptions<number, Error> = {
    queryKey: ['updateDetailsCount', updateId],
    queryFn: () => {
      return getUpdateHistoryDetailsCount(updateId);
    },
  };
  return useQuery(queryOptions);
};
