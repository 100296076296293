import { useState } from 'react';
import {
  Box,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from '@mui/material';
import TextInput from '@components/common/TextInput';
import { DeleteOutline } from '@mui/icons-material';
import Button from '@components/common/Button';
import IconButton from '@components/common/IconButton';
import OrderBaseTable from '@components/order/OrderBaseTable';
import EsimListTable from '@components/order/EsimListTable';
import { useParams } from 'react-router-dom';
import { useOrderDetail } from '@queries/orders/useOrderDetail';
import useAsyncEffect from '@hooks/useAsyncEffect';
import {
  addOrderMemo,
  deleteOrderMemo,
  OrderEventResponse,
  OrderResponse,
} from '@apis/order';
import { useOrderEvents } from '@queries/orders/useOrderEvents';
import { timestampToDate } from '@utils/time';
import { useRecoilValue } from 'recoil';
import { userState } from '@recoils/atoms';
import { UserGroup } from '@models/user';
import { useOrderReservationItems } from '../../queries/orders/useOrderReservationItems';
import { ReservationItemTable } from '../../components/order/ReservationItemTable';

const OrderDetail = () => {
  const { orderId } = useParams();
  const [orderDetail, setOrderDetail] = useState<OrderResponse | null>(null);
  const [memo, setMemo] = useState('');
  const [fullRefundEsim, setFullRefundEsim] = useState(false);
  const [eventList, setEventList] = useState<OrderEventResponse[]>([]);
  const user = useRecoilValue(userState);
  const { data: orderDetailData, refetch } = useOrderDetail(Number(orderId));
  const { data: orderEventsData, refetch: refetchOrderEventsData } =
    useOrderEvents(Number(orderId));
  const { data: reservationItems, refetch: refetchItems } =
    useOrderReservationItems(Number(orderId));

  const handleFullRefund = () => {
    setFullRefundEsim(true);
  };

  const handleOnAddMemo = async () => {
    await addOrderMemo(Number(orderId), memo);

    refetchOrderEventsData();
    setMemo('');
  };

  const handleDeleteMemo = async (orderEventId: number) => {
    if (!!window.confirm('Are you sure to delete this event?')) {
      await deleteOrderMemo(Number(orderId), orderEventId);
    }

    refetchOrderEventsData();
  };

  useAsyncEffect(async () => {
    if (!orderDetailData) return;
    setOrderDetail(orderDetailData?.data[0]);
  }, [orderDetailData]);

  useAsyncEffect(async () => {
    if (!orderEventsData) return;
    setEventList(orderEventsData?.data || []);
  }, [orderEventsData]);

  const onRefund = () => {
    refetch();
    refetchItems();
    refetchOrderEventsData();
  };

  const isRefundable =
    !!orderDetail && orderDetail.paymentAmount > orderDetail.refundAmount;
  if (!orderDetail) return <></>;
  return (
    <>
      <Box display="flex" justifyContent={'space-between'} width={'100%'}>
        <Typography variant="h4" component="h4">
          Order ({orderDetail.orderCode})
        </Typography>
        {user?.group !== UserGroup.PARTNER && isRefundable && (
          <Button
            text={'FULL REFUND'}
            onClick={handleFullRefund}
            color={'error'}
          />
        )}
      </Box>
      <Toolbar />
      {/* Order Base Info */}
      <OrderBaseTable order={orderDetail} />

      <Toolbar />

      {/* eSIM List */}
      <EsimListTable
        isRefundable={isRefundable}
        order={orderDetail}
        fullRefund={fullRefundEsim}
        setFullRefund={setFullRefundEsim}
        onRefund={onRefund}
      />
      <ReservationItemTable
        reservationItems={reservationItems?.data || []}
        orderId={orderDetail.orderId}
      />

      <Toolbar />
      {/* History (memoList) */}
      <Typography variant="h4" component="h4">
        History
      </Typography>
      <TableContainer
        component={Paper}
        sx={{
          marginTop: 2,
        }}
      >
        <Table sx={{ minWidth: 700 }}>
          <TableHead>
            <TableRow>
              <StyledTableCell>Index</StyledTableCell>
              <StyledTableCell>Event</StyledTableCell>
              <StyledTableCell>Owner</StyledTableCell>
              <StyledTableCell>Description</StyledTableCell>
              <StyledTableCell>Date & Time</StyledTableCell>
              <StyledTableCell>Delete</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {eventList.map((memo, index) => (
              <StyledTableRow key={memo.orderEventId}>
                <StyledTableCell component="th" scope="row">
                  {index + 1}
                </StyledTableCell>
                <StyledTableCell>{memo.eventType}</StyledTableCell>
                <StyledTableCell>{memo.owner}</StyledTableCell>
                <StyledTableCell>{memo.description}</StyledTableCell>
                <StyledTableCell>
                  {timestampToDate(memo.createdAt, 'YYYY-MM-DD')}
                </StyledTableCell>
                <StyledTableCell>
                  <IconButton
                    onClick={() => {
                      handleDeleteMemo(memo.orderEventId);
                    }}
                  >
                    <DeleteOutline />
                  </IconButton>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box
        display={'flex'}
        flexDirection={'row'}
        gap={3}
        mt={4}
        alignItems={'center'}
      >
        <TextInput
          label={'Memo'}
          value={memo}
          onChange={(e) => setMemo(e.target.value)}
          layout="horizontal"
          labelWidth={100}
          inputWidth={800}
        />
        <Button text={'ADD'} onClick={handleOnAddMemo} />
      </Box>
    </>
  );
};

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#000',
    color: '#fff',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(() => ({
  backgroundColor: '#FFFFFF',
  '&:hover': {
    cursor: 'pointer',
    backgroundColor: '#F3F6F9',
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export default OrderDetail;
