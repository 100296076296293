import { PropsWithChildren } from 'react';
import { IconButton as BaseIconButton } from '@mui/material';

type IconButtonProps = {
  onClick: () => void;
};

const IconButton = ({
  onClick,
  children,
}: PropsWithChildren<IconButtonProps>) => {
  return (
    <BaseIconButton
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onClick();
      }}
      size={'medium'}
      style={{
        backgroundColor: 'transparent',
      }}
    >
      {children}
    </BaseIconButton>
  );
};

export default IconButton;
