import { userState } from '@recoils/atoms';
import { Navigate, Outlet } from 'react-router-dom';
import { useRecoilValueLoadable } from 'recoil';

function AdminRoute() {
  const user = useRecoilValueLoadable(userState);

  switch (user.state) {
    case 'hasValue':
      return <Outlet />;
    case 'loading':
      return <div>Loading...</div>;
    case 'hasError':
      return <Navigate to="/login" />;
  }
}

export default AdminRoute;
