import Button from '@components/common/Button';
import TextInput from '@components/common/TextInput';
import {
  Box,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
  tableCellClasses,
} from '@mui/material';
import { useEffect, useState } from 'react';
import IconButton from '@components/common/IconButton';
import { DeleteOutline } from '@mui/icons-material';
import Modal from '@components/common/Modal';
import EditSupplierContent from '@components/stock/EditSupplierContent';
import AddSupplierContent from '@components/stock/AddSupplierContent';
import { useSupplierList } from '@queries/suppliers/useSupplierList';
import {
  GetSuppliersParams,
  SupplierResponse,
  SupplierUpsertRequest,
  editSupplier,
} from '@apis/supplier';
import { timestampToDate } from '@utils/time';
import { useSupplierCount } from '@queries/suppliers/useSupplierCount';
import { useSearchParams } from 'react-router-dom';
import { filterSearchParams } from '../../utils/search';

const PAGE_SIZE = 50;
const Suppliers = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchForm, setSearchForm] = useState<GetSuppliersParams>({
    supplierName: searchParams.get('supplierName') || '',
    managerName: searchParams.get('managerName') || '',
    email: searchParams.get('email') || '',
    phoneNumber: searchParams.get('phoneNumber') || '',
  });
  const [selected, setSelected] = useState<SupplierResponse | null>(null);
  const [addMode, setAddMode] = useState(false);

  const [page, setPage] = useState(
    searchParams.get('page') ? Number(searchParams.get('page')) : 1
  );
  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPage(value);
    setSearchParams({
      ...filterSearchParams(
        Object.fromEntries(new URLSearchParams(searchForm as any).entries())
      ),
      page: value.toString(),
    });
  };

  const { data, refetch, isLoading } = useSupplierList({
    page,
    searchParam: searchForm,
  });

  const {
    data: totalCount,
    refetch: refetchCount,
    isLoading: isLoadingCount,
  } = useSupplierCount(searchForm);

  const handleChangeInput = (name: keyof GetSuppliersParams, value: string) => {
    setSearchForm((prev) => ({ ...prev, [name]: value }));
  };

  const handleOnSearch = () => {
    setSearchParams({
      ...filterSearchParams(
        Object.fromEntries(new URLSearchParams(searchForm as any).entries())
      ),
    });
    refetch();
    refetchCount();
  };

  const handleOnAdd = () => {
    setAddMode(true);
  };

  const handleCloseEditModal = (needsRefetch: boolean) => {
    setSelected(null);
    if (needsRefetch) {
      refetch();
      refetchCount();
    }
  };

  const handleCloseAddModal = (needsRefetch: boolean) => {
    setAddMode(false);
    if (needsRefetch) {
      refetch();
      refetchCount();
    }
  };

  const handleDeleteSupplier = async (
    supplierId: number,
    supplier: SupplierUpsertRequest
  ) => {
    if (!!window.confirm('Are you sure to delete this supplier?')) {
      await editSupplier(supplierId, {
        ...supplier,
        isDeleted: true,
      });

      refetch();
      refetchCount();
    }
  };

  useEffect(() => {
    if (isLoading || isLoadingCount) {
      return;
    }
    refetchCount();
    refetch();
  }, [refetch, refetchCount, isLoading, isLoadingCount]);

  return (
    <>
      <Typography variant="h4" component="h4">
        eSIM Suppliers
      </Typography>
      <Box display={'flex'} justifyContent={'flex-end'}>
        <Button text={'Add'} onClick={handleOnAdd} />
      </Box>
      {/* Search Section */}
      <Box
        mt={2}
        mb={3}
        gap={2}
        display={'flex'}
        flexDirection={'column'}
        alignItems={'flex-end'}
      >
        <Box
          width={'100%'}
          display={'flex'}
          alignItems={'flex-end'}
          gap={2}
          flexDirection={'row'}
        >
          <TextInput
            label={'Supplier'}
            value={searchForm.supplierName}
            onChange={(e) => handleChangeInput('supplierName', e.target.value)}
            inputWidth={200}
            placeholder="Enter the supplier name"
          />
          <TextInput
            label={'Manager'}
            value={searchForm.managerName}
            onChange={(e) => handleChangeInput('managerName', e.target.value)}
            inputWidth={200}
            placeholder="Enter the manager name"
          />
          <TextInput
            label={'E-mail'}
            value={searchForm.email}
            onChange={(e) => handleChangeInput('email', e.target.value)}
            inputWidth={200}
            placeholder="Enter the e-mail address"
          />
          <TextInput
            label={'Phone number'}
            value={searchForm.phoneNumber}
            onChange={(e) => handleChangeInput('phoneNumber', e.target.value)}
            inputWidth={200}
            placeholder="Enter the phone number"
          />
          <Button text={'Search'} onClick={handleOnSearch} />
        </Box>
      </Box>

      {/* List Section */}
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }}>
          <TableHead>
            <TableRow>
              <StyledTableCell>Index</StyledTableCell>
              <StyledTableCell>Supplier Code</StyledTableCell>
              <StyledTableCell>Supplier</StyledTableCell>
              <StyledTableCell>Manager</StyledTableCell>
              <StyledTableCell>E-mail</StyledTableCell>
              <StyledTableCell>Phone number</StyledTableCell>
              <StyledTableCell>Created</StyledTableCell>
              <StyledTableCell>Remove</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(data?.data || []).map((row, index) => (
              <StyledTableRow
                key={row.supplierId}
                onClick={(e) => {
                  e.stopPropagation();
                  setSelected(row);
                }}
              >
                <StyledTableCell component="th" scope="row">
                  {/* page 로직까지 */}
                  {(page - 1) * 10 + index + 1}
                </StyledTableCell>
                <StyledTableCell>{row.supplierCode}</StyledTableCell>
                <StyledTableCell>{row.supplierName}</StyledTableCell>
                <StyledTableCell>{row.managerName}</StyledTableCell>
                <StyledTableCell>{row.email}</StyledTableCell>
                <StyledTableCell>{row.phoneNumber}</StyledTableCell>
                <StyledTableCell>
                  {timestampToDate(row.createdAt)}
                </StyledTableCell>
                <StyledTableCell>
                  <IconButton
                    onClick={() => {
                      handleDeleteSupplier(row.supplierId, row);
                    }}
                  >
                    <DeleteOutline />
                  </IconButton>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {/* Pagination */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          mt: 2,
        }}
      >
        <Pagination
          count={Math.ceil((totalCount ?? 0) / PAGE_SIZE)}
          page={page}
          onChange={handleChangePage}
        />
      </Box>
      <Modal open={!!selected} onClose={() => handleCloseEditModal(false)}>
        {!!selected && (
          <EditSupplierContent
            onClose={handleCloseEditModal}
            supplierId={selected.supplierId}
          />
        )}
      </Modal>
      <Modal open={addMode} onClose={() => handleCloseAddModal(false)}>
        <AddSupplierContent onClose={handleCloseAddModal} />
      </Modal>
    </>
  );
};

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#000',
    color: '#fff',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(() => ({
  backgroundColor: '#FFFFFF',
  '&:hover': {
    backgroundColor: '#F3F6F9',
    cursor: 'pointer',
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export default Suppliers;
