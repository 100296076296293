import Button from '@components/common/Button';
import Select, { SelectValue } from '@components/common/Select';
import TextInput from '@components/common/TextInput';

import {
  Box,
  Pagination,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import DateRangeInput from '@components/common/DateRangeInput';
import { ESIM_TYPE_OPTION_LIST } from '@constants/stocks';
import { useInventoryList } from '@queries/inventories/useInventoryList';
import {
  EsimInventoryParameterType,
  exportEsimInventoriesExcel,
  GetEsimInventoriesParams,
} from '@apis/esim';
import { dateToTimestamp, timestampToDate } from '@utils/time';
import { useInventoryCount } from '@queries/inventories/useInventoryCount';
import { EsimStatus, EsimType } from '@models/stock';
import { downloadBlob } from '@utils/file';
import { filterSearchParams } from '../../utils/search';
import SearchSelect from '../../components/common/SearchSelect';
import { useAllStockList } from '../../queries/stocks/useAllStockList';
import useAsyncEffect from '../../hooks/useAsyncEffect';

const PAGE_SIZE = 50;

const EsimInventories = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchForm, setSearchForm] = useState<GetEsimInventoriesParams>({
    stockId: searchParams.get('stockId')
      ? Number(searchParams.get('stockId'))
      : undefined,
    esimType: (searchParams.get('esimType') as EsimType) || undefined,
    esimStatus: (searchParams.get('esimStatus') as EsimStatus) || undefined,
    expireAtFrom: searchParams.get('expireAtFrom') || undefined,
    expireAtTo: searchParams.get('expireAtTo') || undefined,
    parameterType:
      (searchParams.get('parameterType') as EsimInventoryParameterType) ||
      EsimInventoryParameterType.LPA_URL,
    parameterValue: searchParams.get('parameterValue') || '',
  });

  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPage(value);
    setSearchParams({
      ...filterSearchParams(
        Object.fromEntries(new URLSearchParams(searchForm as any).entries())
      ),
      page: value.toString(),
    });
  };

  const { data, refetch, isLoading } = useInventoryList({
    page: page,
    pageSize: PAGE_SIZE,
    searchParam: searchForm,
  });

  const {
    data: totalCount,
    refetch: refetchCount,
    isLoading: isLoadingCount,
  } = useInventoryCount(searchForm);

  const handleOnSearch = () => {
    setSearchParams({
      ...filterSearchParams(
        Object.fromEntries(new URLSearchParams(searchForm as any).entries())
      ),
    });
    refetch();
    refetchCount();
  };

  const handleOnExport = async () => {
    const res = await exportEsimInventoriesExcel(searchForm);
    const currentDate = new Date();
    const fileName = `esim-inventories-${currentDate.toISOString()}.xlsx`;
    downloadBlob(res, fileName);
  };

  const handleChangeInput = (
    name: 'stockId' | 'parameterValue' | 'expireAtFrom' | 'expireAtTo',
    value: string | number | null
  ) => {
    setSearchForm((prev) => ({ ...prev, [name]: value }));
  };

  const handleChangeSelect = (
    name: 'esimType' | 'esimStatus' | 'parameterType',
    value: SelectValue
  ) => {
    setSearchForm((prev) => ({ ...prev, [name]: value }));
  };

  useEffect(() => {
    if (!isLoading) {
      refetch();
    }
    if (!isLoadingCount) {
      refetchCount();
    }
  }, [refetch, refetchCount, isLoading, isLoadingCount]);
  const { data: stocksData } = useAllStockList();
  const [stockOptionList, setStockOptionList] = useState<
    { value: SelectValue; label: string }[]
  >([]);
  useAsyncEffect(async () => {
    if (!stocksData) return;
    setStockOptionList(
      stocksData?.data.map((s) => ({
        label: s.stockCode,
        value: s.stockId,
      }))
    );
  }, [stocksData]);
  return (
    <>
      <Typography variant="h4" component="h4">
        eSIM Inventory
      </Typography>
      {/* Search Section */}
      <Box
        mt={2}
        mb={3}
        gap={2}
        display={'flex'}
        flexDirection={'column'}
        alignItems={'flex-end'}
      >
        <Box width={'100%'} display={'flex'} gap={2} flexDirection={'row'}>
          <SearchSelect
            disablePortal
            label={'Stock Code'}
            options={stockOptionList}
            placeholder="Enter the Stock Code"
            value={
              stockOptionList.find(
                (c) =>
                  c.value ===
                  (searchForm.stockId || Number(searchParams.get('stockId')))
              ) || null
            }
            setValue={(value) =>
              handleChangeInput('stockId', value?.value as number)
            }
          />
          <SearchSelect
            disablePortal
            label={'eSIM Type'}
            value={
              ESIM_TYPE_OPTION_LIST.find(
                (op) => op.value === searchForm.esimType
              ) || null
            }
            setValue={(value) =>
              handleChangeSelect('esimType', value?.value || null)
            }
            options={ESIM_TYPE_OPTION_LIST}
            placeholder="Select eSIM type"
          />
          <SearchSelect
            disablePortal
            label={'eSIM state'}
            value={
              Object.keys(EsimStatus)
                .map((s) => ({
                  label: s,
                  value: s,
                }))
                .find((s) => s.value === searchForm.esimStatus) || null
            }
            setValue={(value) =>
              handleChangeSelect('esimStatus', value?.value || null)
            }
            options={Object.keys(EsimStatus).map((s) => ({
              label: s,
              value: s,
            }))}
            placeholder="Select eSIM state"
          />
          <DateRangeInput
            startDate={timestampToDate(
              searchForm.expireAtFrom || '',
              'YYYY-MM-DD'
            )}
            endDate={timestampToDate(searchForm.expireAtTo || '', 'YYYY-MM-DD')}
            handleSelectStartDate={(date) => {
              if (!date) {
                handleChangeInput('expireAtFrom', null);
                setSearchForm((prev) => ({
                  ...prev,
                  expireAtFrom: undefined,
                }));
              } else {
                const expireAtFrom = dateToTimestamp(date);
                handleChangeInput('expireAtFrom', expireAtFrom);
              }
            }}
            handleSelectEndDate={(date) => {
              if (!date) {
                handleChangeInput('expireAtTo', null);
                setSearchForm((prev) => ({
                  ...prev,
                  expireAtTo: undefined,
                }));
              } else {
                const expireAtTo = dateToTimestamp(date, true);
                handleChangeInput('expireAtTo', expireAtTo);
              }
            }}
            label="Expiry Date"
          />
        </Box>
        <Box
          width={'100%'}
          display={'flex'}
          gap={2}
          flexDirection={'row'}
          alignItems="flex-end"
        >
          <SearchSelect
            disablePortal
            label={'Parameter Type'}
            value={
              Object.keys(EsimInventoryParameterType)
                .map((s) => ({
                  label: s,
                  value: s,
                }))
                .find((s) => s.value === searchForm.parameterType) || null
            }
            setValue={(value) =>
              handleChangeSelect('parameterType', value?.value || null)
            }
            options={Object.keys(EsimInventoryParameterType).map((s) => ({
              label: s,
              value: s,
            }))}
            placeholder="LPA_URL / REDEMPTION_CODE / ICCID / MSISDN"
          />
          <TextInput
            label={'Keyword'}
            value={searchForm.parameterValue}
            onChange={(e) =>
              handleChangeInput('parameterValue', e.target.value)
            }
            inputWidth={500}
            placeholder="Keyword"
          />
          <Button text={'Search'} onClick={handleOnSearch} />
          <Button
            text={'Export'}
            onClick={handleOnExport}
            color={'secondary'}
          />
        </Box>
      </Box>

      {/* List Section */}
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }}>
          <TableHead>
            <TableRow>
              <StyledTableCell>Index</StyledTableCell>
              <StyledTableCell>Stock Code</StyledTableCell>
              <StyledTableCell>Type</StyledTableCell>
              <StyledTableCell>Redemption Code</StyledTableCell>
              <StyledTableCell>LPA URL</StyledTableCell>
              <StyledTableCell>ICCID</StyledTableCell>
              <StyledTableCell>MSISDN</StyledTableCell>
              <StyledTableCell>State</StyledTableCell>
              <StyledTableCell>Exp.Date</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(data?.data || []).map((row, index) => (
              <StyledTableRow
                key={row.esimInventoryId}
                onClick={() => {
                  navigate(`/stocks/esim-inventory/${row.esimInventoryId}`);
                }}
              >
                <StyledTableCell component="th" scope="row">
                  {index + 1}
                </StyledTableCell>
                <StyledTableCell>{row.stock.stockCode}</StyledTableCell>
                <StyledTableCell>{row.esimType}</StyledTableCell>
                <StyledTableCell>{row.redemptionCode}</StyledTableCell>
                <StyledTableCell>{row.lpaUrl}</StyledTableCell>
                <StyledTableCell>{row.iccid}</StyledTableCell>
                <StyledTableCell>{row.msisdn}</StyledTableCell>
                <StyledTableCell>{row.esimStatus}</StyledTableCell>
                <StyledTableCell>
                  {timestampToDate(row.expireAt, 'YYYY-MM-DD')}
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {/* Pagination */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          mt: 2,
        }}
      >
        <Pagination
          count={Math.ceil((totalCount ?? 0) / PAGE_SIZE)}
          page={page}
          onChange={handleChangePage}
        />
      </Box>
    </>
  );
};

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#000',
    color: '#fff',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(() => ({
  backgroundColor: '#FFFFFF',
  '&:hover': {
    backgroundColor: '#F3F6F9',
    cursor: 'pointer',
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export default EsimInventories;
