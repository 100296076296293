import * as React from 'react';
import {
  Select as BaseSelect,
  SelectProps as BaseSelectProps,
  selectClasses,
  SelectRootSlotProps,
} from '@mui/base/Select';
import { Option as BaseOption, optionClasses } from '@mui/base/Option';
import { Popper as BasePopper } from '@mui/base/Popper';
import { styled } from '@mui/system';
import UnfoldMoreRoundedIcon from '@mui/icons-material/UnfoldMoreRounded';
import { blue, grey } from './colors';
import InputWrapper, { InputWrapperBaseProps } from './InputWrapper';
import { SelectOption } from '@mui/base/useOption';

export type SelectValue = null | ValidSelectValue;
export type ValidSelectValue = number | string;

type SelectProps = {
  optionList: { value: SelectValue; label: string }[];
  value: SelectValue;
  setValue: (value: SelectValue) => void;
  noneOption?: boolean;
} & BaseSelectProps<ValidSelectValue, false> &
  InputWrapperBaseProps;

const Select = ({
  optionList,
  value,
  setValue,
  noneOption = false,
  ...props
}: SelectProps) => {
  return (
    <InputWrapper {...(props as InputWrapperBaseProps)}>
      <StyledSelect
        value={value}
        onChange={(_, newValue) => setValue(newValue)}
        renderValue={(option: SelectOption<SelectValue> | null) => {
          if (!option || !option.value) {
            return props.placeholder;
          }
          return `${option.label}`;
        }}
        {...props}
      >
        {noneOption && <Option value={null}>None</Option>}
        {optionList.map((option) => {
          return (
            <Option key={option.value} value={option.value}>
              {option.label}
            </Option>
          );
        })}
      </StyledSelect>
    </InputWrapper>
  );
};

function StyledSelect(props: BaseSelectProps<ValidSelectValue, false>) {
  const slots: BaseSelectProps<ValidSelectValue, false>['slots'] = {
    root: StyledButton,
    listbox: Listbox,
    popper: Popper,
    ...props.slots,
  };

  return <BaseSelect {...props} slots={slots} />;
}

const CustomButton = React.forwardRef(function CustomButton(
  props: SelectRootSlotProps<ValidSelectValue, false>,
  ref: React.ForwardedRef<HTMLButtonElement>
) {
  return (
    <button
      type="button"
      {...props}
      ref={ref}
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <span>{props.children}</span>
      <UnfoldMoreRoundedIcon />
    </button>
  );
});

const StyledButton = styled(CustomButton, { shouldForwardProp: () => true })(
  ({ theme }) => `
  box-sizing: border-box;
  width: 100%;
  font-size: 0.875rem;
  font-weight: 400;
  min-width: 200px;
  padding: 8px 12px;
  border-radius: 8px;
  text-align: left;
  line-height: 1.5;
  background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
  border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
  color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  box-shadow: 0px 2px 2px ${
    theme.palette.mode === 'dark' ? grey[900] : grey[50]
  };

  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 120ms;

  &:hover {
    background: ${theme.palette.mode === 'dark' ? grey[800] : grey[50]};
    border-color: ${theme.palette.mode === 'dark' ? grey[600] : grey[300]};
  }

  &.${selectClasses.focusVisible} {
    outline: 0;
    border-color: ${blue[400]};
    box-shadow: 0 0 0 3px ${
      theme.palette.mode === 'dark' ? blue[600] : blue[200]
    };
  }

  & > svg {
    font-size: 1rem;
    vertical-align: middle;
  }

  `
);

const Listbox = styled('ul')(
  ({ theme }) => `
  box-sizing: border-box;
  padding: 6px;
  margin: 12px 0;
  min-width: 200px;
  max-height: 300px;
  border-radius: 12px;
  overflow: auto;
  outline: 0px;
  background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
  border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
  color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  box-shadow: 0px 2px 6px ${
    theme.palette.mode === 'dark' ? 'rgba(0,0,0, 0.50)' : 'rgba(0,0,0, 0.05)'
  };
  `
);

const Option = styled(BaseOption)(
  ({ theme }) => `
  list-style: none;
  padding: 8px;
  border-radius: 8px;
  cursor: default;

  &:last-of-type {
    border-bottom: none;
  }

  &.${optionClasses.selected} {
    background-color: ${theme.palette.mode === 'dark' ? blue[900] : blue[100]};
    color: ${theme.palette.mode === 'dark' ? blue[100] : blue[900]};
  }

  &.${optionClasses.highlighted} {
    background-color: ${theme.palette.mode === 'dark' ? grey[800] : grey[100]};
    color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  }

  &.${optionClasses.highlighted}.${optionClasses.selected} {
    background-color: ${theme.palette.mode === 'dark' ? blue[900] : blue[100]};
    color: ${theme.palette.mode === 'dark' ? blue[100] : blue[900]};
  }

  &.${optionClasses.disabled} {
    color: ${theme.palette.mode === 'dark' ? grey[700] : grey[400]};
  }

  &:hover:not(.${optionClasses.disabled}) {
    background-color: ${theme.palette.mode === 'dark' ? grey[800] : grey[100]};
    color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  }
  `
);

const Popper = styled(BasePopper)`
  z-index: 1500;
`;

export default Select;
