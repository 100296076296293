import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { PageResponse } from '@models/api';
import { OrderEventResponse, getOrderEvents } from '@apis/order';

export const useOrderEvents = (orderId: number) => {
  const queryOptions: UseQueryOptions<
    PageResponse<OrderEventResponse>,
    Error
  > = {
    queryKey: ['orderEvents', orderId],
    queryFn: () => {
      return getOrderEvents(orderId);
    },
  };
  return useQuery(queryOptions);
};
