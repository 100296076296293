import * as React from 'react';
import TextInput from '@components/common/TextInput';
import { Box, Typography } from '@mui/material';
import { CloseOutlined, ErrorOutline } from '@mui/icons-material';
import Label from '@components/common/Label';
import IconButton from '@components/common/IconButton';
import Button from '@components/common/Button';
import { PERMITTED_USER_GROUP, UserGroup, UserStatus } from '@models/user';
import CheckBox from '@components/common/CheckBox';
import Select, { SelectValue } from '@components/common/Select';
import Modal from '@components/common/Modal';
import {
  SignUpRequest,
  createUser,
  getUserByEmail,
  getUserByUsername,
} from '@apis/users';
import { useUserList } from '@queries/users/useUserList';
import { alertMissingValues } from '@utils/common';

type AddUserContentProps = {
  onClose: (refetch: boolean) => void;
};

type DuplicateCheckStatus = 'NOT_CHECKED' | 'DUPLICATED' | 'OK';
const AddUserModalContent = ({ onClose }: AddUserContentProps) => {
  const [passwordConfirm, setPasswordConfirm] = React.useState<string>('');
  const [form, setForm] = React.useState<SignUpRequest>({
    name: '',
    username: '',
    position: '',
    group: UserGroup.MANAGER,
    email: '',
    phoneNumber: '',
    status: UserStatus.ACTIVE,
    password: '',
  });
  const [errorMessage, setErrorMessage] = React.useState('');
  const [error, setError] = React.useState(false);
  const [usernameStatus, setUsernameStatus] =
    React.useState<DuplicateCheckStatus>('NOT_CHECKED');
  const [emailStatus, setEmailStatus] =
    React.useState<DuplicateCheckStatus>('NOT_CHECKED');

  const handleChangeInput = (name: keyof SignUpRequest, value: string) => {
    setForm((prev) => ({ ...prev, [name]: value }));
  };

  const onUsernameChanged = (username: string) => {
    setUsernameStatus('NOT_CHECKED');
    setForm((prev) => ({ ...prev, username: username }));
  };

  const onEmailChanged = (email: string) => {
    setEmailStatus('NOT_CHECKED');
    setForm((prev) => ({ ...prev, email: email }));
  };

  const handleChangeCheckBox = (value: boolean) => {
    setForm((prev) => ({
      ...prev,
      status: value ? UserStatus.ACTIVE : UserStatus.INACTIVE,
    }));
  };

  const handleChangeSelect = (name: 'group', value: SelectValue) => {
    setForm((prev) => ({ ...prev, [name]: value as UserGroup }));
  };

  const handleOnCreate = async () => {
    if (usernameStatus === 'NOT_CHECKED') {
      alert('Please check if the manager username is duplicated');
      return;
    } else if (usernameStatus === 'DUPLICATED') {
      alert('Username is duplicated');
      return;
    }

    if (emailStatus === 'NOT_CHECKED') {
      alert('Please check if the manager email is duplicated');
      return;
    } else if (emailStatus === 'DUPLICATED') {
      alert('Email is duplicated');
      return;
    }
    if (passwordConfirm !== form.password) {
      alert('Password and Confirm Password are not matched');
      return;
    }

    // validate
    const hasMissingValues = alertMissingValues(form);

    if (hasMissingValues) return;

    const { message } = await createUser(form);

    if (message !== 'OK') {
      setErrorMessage(message);
      setError(true);
    } else {
      onClose(true);
    }
  };

  const handleCheckUsernameDuplicate = async () => {
    const { data } = await getUserByUsername(form.username);
    if (data.length === 0) {
      setUsernameStatus('OK');
      alert('Username is not duplicated!\nYou can use this username!');
    } else {
      setUsernameStatus('DUPLICATED');
      alert('Username is duplicated!\nPlease use another username!');
    }
  };

  const handleCheckEmailDuplicate = async () => {
    const { data } = await getUserByEmail(form.email);
    if (data.length === 0) {
      setEmailStatus('OK');
      alert('Email is not duplicated!\nYou can use this Email!');
    } else {
      setEmailStatus('DUPLICATED');
      alert('Email is duplicated!\nPlease use another Email!');
    }
  };

  return (
    <>
      <Box display={'flex'} flexDirection={'column'}>
        <Box
          display="flex"
          width={'100%'}
          flexDirection="row"
          justifyContent={'space-between'}
        >
          <Box display={'flex'}>
            <Label>New User</Label>
          </Box>
          <IconButton onClick={() => onClose(false)}>
            <CloseOutlined />
          </IconButton>
        </Box>
        <Box display="flex" width={'100%'} flexDirection="column">
          <Box
            display={'flex'}
            flexDirection={'row'}
            width={'100%'}
            alignItems={'center'}
            gap={2}
          >
            <TextInput
              label={'ID'}
              value={form.username}
              onChange={(e) => onUsernameChanged(e.target.value)}
              placeholder="Enter ID and check for duplication"
              layout="horizontal"
              inputWidth={250}
              labelWidth={90}
            />
            <Button
              text={'CHECK'}
              color="secondary"
              onClick={handleCheckUsernameDuplicate}
            />
          </Box>
          <TextInput
            label={'Name'}
            value={form.name}
            onChange={(e) => handleChangeInput('name', e.target.value)}
            placeholder="Enter the name"
            layout="horizontal"
            inputWidth={250}
            labelWidth={90}
          />
          <TextInput
            label={'PW'}
            value={form.password}
            onChange={(e) => handleChangeInput('password', e.target.value)}
            placeholder="Enter the password"
            layout="horizontal"
            inputWidth={250}
            labelWidth={90}
          />
          <TextInput
            label={'Re-enter PW'}
            value={passwordConfirm}
            onChange={(e) => setPasswordConfirm(e.target.value)}
            placeholder="Re-enter the password"
            layout="horizontal"
            inputWidth={250}
            labelWidth={90}
          />
          <TextInput
            label={'Position'}
            value={form.position}
            onChange={(e) => handleChangeInput('position', e.target.value)}
            placeholder="Enter the position"
            layout="horizontal"
            inputWidth={250}
            labelWidth={90}
          />
          <Select
            value={form.group}
            setValue={(v) => handleChangeSelect('group', v)}
            optionList={Object.keys(UserGroup)
              .filter((key) => key !== UserGroup.PARTNER)
              .map((v) => ({
                label: v,
                value: v,
              }))}
            placeholder="Select group"
            layout="horizontal"
            label="Group"
            inputWidth={250}
            labelWidth={90}
          />
          <Box
            display={'flex'}
            flexDirection={'row'}
            width={'100%'}
            alignItems={'center'}
            gap={2}
          >
            <TextInput
              label={'E-mail'}
              value={form.email}
              onChange={(e) => onEmailChanged(e.target.value)}
              placeholder="Enter the e-mail address"
              layout="horizontal"
              inputWidth={250}
              labelWidth={90}
            />
            <Button
              text={'CHECK'}
              color="secondary"
              onClick={handleCheckEmailDuplicate}
            />
          </Box>
          <TextInput
            label={'Contact'}
            value={form.phoneNumber}
            onChange={(e) => handleChangeInput('phoneNumber', e.target.value)}
            placeholder="Enter the contact"
            layout="horizontal"
            inputWidth={250}
            labelWidth={90}
          />
          <CheckBox
            label={'Active'}
            value={form.status === 'ACTIVE'}
            onCheck={(checked) => handleChangeCheckBox(checked)}
          />
        </Box>

        <Box display={'flex'} justifyContent={'flex-end'} mt={2}>
          <Button text={'CREATE'} onClick={handleOnCreate} />
        </Box>
      </Box>
      <Modal open={error} onClose={() => {}}>
        <Box display={'flex'} flexDirection={'row'} gap={2}>
          <ErrorOutline color={'error'} />
          <Typography textAlign={'center'}>{errorMessage}</Typography>
        </Box>
        <Box display={'flex'} justifyContent={'flex-end'}>
          <Button
            color="primary"
            onClick={() => {
              setError(false);
            }}
            text={'OK'}
          />
        </Box>
      </Modal>
    </>
  );
};

export default AddUserModalContent;
