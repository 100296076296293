import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { GetOrdersParams, getOrdersCount } from '@apis/order';
import { User, UserGroup } from '@models/user';

export const useOrdersCount = (params: GetOrdersParams, user: User | null) => {
  const queryOptions: UseQueryOptions<number, Error> = {
    queryKey: ['ordersCount', user?.userId],
    queryFn: () => {
      return getOrdersCount({
        ...params,
        ...(user && user.group === UserGroup.PARTNER
          ? { partnerId: user.partnerId }
          : {}),
      });
    },
    enabled:
      !!user && (user.group === UserGroup.PARTNER ? !!params.partnerId : true),
  };
  return useQuery(queryOptions);
};
