import { FormControlProps } from '@mui/base/FormControl';
import { Box, Typography } from '@mui/material';
import TextInput from './TextInput';
import InputWrapper, { InputWrapperBaseProps } from './InputWrapper';

type DateRangeInputProps = {
  startDate: string;
  endDate: string;
  handleSelectStartDate: (date: string) => void;
  handleSelectEndDate: (date: string) => void;
} & FormControlProps &
  InputWrapperBaseProps;

const DateRangeInput = ({
  startDate,
  endDate,
  handleSelectStartDate,
  handleSelectEndDate,
  ...props
}: DateRangeInputProps) => {
  return (
    <InputWrapper {...(props as InputWrapperBaseProps)}>
      <Box display="flex" gap={2} flexDirection={'row'}>
        <TextInput
          value={startDate}
          onChange={(e) => handleSelectStartDate(e.target.value)}
          inputWidth={200}
          placeholder="Start Date"
          type="date"
        />
        <Typography>~</Typography>
        <TextInput
          value={endDate}
          onChange={(e) => handleSelectEndDate(e.target.value)}
          inputWidth={200}
          placeholder="End Date"
          type="date"
        />
      </Box>
    </InputWrapper>
  );
};

export default DateRangeInput;
