import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { PageResponse } from '@models/api';
import { ContinentResponse, getAllContinents } from '@apis/continents';

export const useContinentList = () => {
  const queryOptions: UseQueryOptions<
    PageResponse<ContinentResponse>,
    Error
  > = {
    queryKey: ['continents'],
    queryFn: () => {
      return getAllContinents();
    },
  };
  return useQuery(queryOptions);
};
