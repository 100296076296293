import { PageResponse } from '@models/api';
import API from './API';

export type SummaryCommonParams = {
  from: number;
  to: number;
};

export type PeriodUnit = 'DAYS' | 'MONTHS' | 'WEEKS' | 'YEARS';

export type GetSalesParams = {
  periodUnit: PeriodUnit;
} & SummaryCommonParams;

export type GetSalesResponse = {
  from: string;
  to: string;
  amount: number;
};

export const getSales = async (params: GetSalesParams) => {
  const { data } = await API.get<PageResponse<GetSalesResponse>>(
    '/v1/summary/sales',
    { params }
  );
  return data;
};

export type GetSalesRankProductResponse = {
  rank: number;
  productId: number;
  productName: string;
  quantity: number;
  amount: number;
};

export const getProductSalesRank = async (params: SummaryCommonParams) => {
  const { data } = await API.get<PageResponse<GetSalesRankProductResponse>>(
    '/v1/summary/sales/rank/product',
    { params }
  );
  return data;
};

export type GetSalesRankPartnerResponse = {
  rank: number;
  partnerId: number;
  partnerName: string;
  quantity: number;
  amount: number;
};

export const getPartnerSalesRank = async (params: SummaryCommonParams) => {
  const { data } = await API.get<PageResponse<GetSalesRankPartnerResponse>>(
    '/v1/summary/sales/rank/partner',
    { params }
  );
  return data;
};

export type GetSalesInventoryRemainedResponse = {
  rank: number;
  stockId: number;
  stockCode: string;
  remained: number;
  shipped: number;
};

export const getInventoryRemainedSalesRank = async (
  params: SummaryCommonParams
) => {
  const { data } = await API.get<
    PageResponse<GetSalesInventoryRemainedResponse>
  >('/v1/summary/sales/rank/inventory-remained', {
    params,
  });
  return data;
};
