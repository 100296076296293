import {
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { OrderResponse } from '@apis/order';
import { toUsdDisplay } from '../../utils/money';
import { timestampToDate } from '@utils/time';
import { useRecoilValue } from 'recoil';
import { userState } from '../../recoils/atoms';

type OrderBaseTableProps = {
  order: OrderResponse;
};

const OrderBaseTable = ({ order }: OrderBaseTableProps) => {
  const user = useRecoilValue(userState);
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }}>
        <TableHead>
          <TableRow>
            <StyledTableCell>Order Code</StyledTableCell>
            <StyledTableCell>Product Code</StyledTableCell>
            <StyledTableCell>Product Name</StyledTableCell>
            {user?.group !== 'PARTNER' && (
              <StyledTableCell>Stock Code</StyledTableCell>
            )}
            <StyledTableCell>Order Qty.</StyledTableCell>
            <StyledTableCell>Order Amount (USD)</StyledTableCell>
            <StyledTableCell>Refund Qty.</StyledTableCell>
            <StyledTableCell>Refund Amount (USD)</StyledTableCell>
            <StyledTableCell>Reserved Date</StyledTableCell>
            <StyledTableCell>Order Date</StyledTableCell>
            <StyledTableCell>Partner</StyledTableCell>
            <StyledTableCell>State</StyledTableCell>
            <StyledTableCell>External Reserve Code</StyledTableCell>
            <StyledTableCell>External Order Id</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <StyledTableRow>
            <StyledTableCell>{order.orderCode}</StyledTableCell>
            <StyledTableCell>{order.product.productCode}</StyledTableCell>
            <StyledTableCell>{order.product.productName}</StyledTableCell>
            {user?.group !== 'PARTNER' && (
              <StyledTableCell>{order.stock.stockCode}</StyledTableCell>
            )}
            <StyledTableCell>{order.quantity}</StyledTableCell>
            <StyledTableCell>
              {toUsdDisplay(order.paymentAmount)}
            </StyledTableCell>
            <StyledTableCell>{order.refundQuantity}</StyledTableCell>
            <StyledTableCell>
              {toUsdDisplay(order.refundAmount)}
            </StyledTableCell>
            <StyledTableCell>
              {order.reservedAt ? timestampToDate(order.reservedAt) : 'N/A'}
            </StyledTableCell>
            <StyledTableCell>
              {timestampToDate(order.createdAt)}
            </StyledTableCell>
            <StyledTableCell>{order.partner.name}</StyledTableCell>
            <StyledTableCell>{order.orderStatus}</StyledTableCell>
            <StyledTableCell>{order.externalReserveCode}</StyledTableCell>
            <StyledTableCell>{order.externalOrderId}</StyledTableCell>
          </StyledTableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#000',
    color: '#fff',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(() => ({
  backgroundColor: '#FFFFFF',
  '&:hover': {
    cursor: 'pointer',
    backgroundColor: '#F3F6F9',
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export default OrderBaseTable;
