import API from './API';
import { PageParams, PageResponse } from '@models/api';
import {
  PartnerProductPriceBulkUpload,
  PartnerProductPriceBulkUploadDetailResponse,
} from '../models/partnerBulk';

export const uploadPriceFile = async (partnerId: number, file: File) => {
  const formData = new FormData();

  formData.append('file', file, file.name);

  const { data } = await API.post<PageResponse<any>>(
    `/v1/partners/${partnerId}/price-bulk/upload`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  );
  return data;
};

export const getPriceUploadHistoriesCount = async (partnerId: number) => {
  const { data } = await API.get<PageResponse<number>>(
    `/v1/partners/${partnerId}/price-bulk/upload/count`
  );
  return data.data[0];
};

export const getPriceUploadHistories = async (
  partnerId: number,
  params: PageParams
) => {
  const { data } = await API.get<PageResponse<PartnerProductPriceBulkUpload>>(
    `/v1/partners/${partnerId}/price-bulk/upload`,
    { params }
  );
  return data;
};

export const getPriceUploadHistory = async (
  partnerId: number,
  uploadId: number
) => {
  const { data } = await API.get<PageResponse<PartnerProductPriceBulkUpload>>(
    `/v1/partners/${partnerId}/price-bulk/upload/${uploadId}`
  );
  return data;
};

export const deletePriceUploadHistory = async (
  partnerId: number,
  uploadId: number
) => {
  const { data } = await API.delete(
    `/v1/partners/${partnerId}/price-bulk/upload/${uploadId}`
  );
  return data;
};

export const getPriceUploadHistoryDetailsCount = async (
  partnerId: number,
  uploadId: number
) => {
  const { data } = await API.get<PageResponse<number>>(
    `/v1/partners/${partnerId}/price-bulk/upload/${uploadId}/details/count`
  );
  return data.data[0];
};

export type GetPriceUploadHistoryDetailsParams = {
  onlyFailed?: boolean;
};

export const getPriceUploadHistoryDetails = async (
  partnerId: number,
  uploadId: number,
  params: PageParams & GetPriceUploadHistoryDetailsParams
) => {
  const { data } = await API.get<
    PageResponse<PartnerProductPriceBulkUploadDetailResponse>
  >(`/v1/partners/${partnerId}/price-bulk/upload/${uploadId}/details`, {
    params,
  });
  return data;
};

export const confirmPriceUpload = async (
  partnerId: number,
  uploadId: number
) => {
  const { data } = await API.post(
    `/v1/partners/${partnerId}/price-bulk/upload/${uploadId}/confirm`
  );
  return data;
};
