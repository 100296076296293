import * as React from 'react';
import TextInput from '@components/common/TextInput';
import { Box } from '@mui/material';
import { CloseOutlined } from '@mui/icons-material';
import Label from '@components/common/Label';
import IconButton from '@components/common/IconButton';
import Button from '@components/common/Button';
import { SupplierUpsertRequest, createSupplier } from '@apis/supplier';
import { alertMissingValues, dropEmptyValues } from '@utils/common';

type AddSupplierContentProps = {
  onClose: (refetch: boolean) => void;
};

const AddSupplierContent = ({ onClose }: AddSupplierContentProps) => {
  const [form, setForm] = React.useState<SupplierUpsertRequest>({
    supplierName: '',
    managerName: '',
    isDeleted: false,
  });

  const handleChangeInput = (
    name: keyof SupplierUpsertRequest,
    value: string | boolean
  ) => {
    setForm((prev) => ({ ...prev, [name]: value }));
  };

  const handleOnCreate = async () => {
    // validate
    const _form = dropEmptyValues(form, ['email', 'phoneNumber']);
    const hasMissingValues = alertMissingValues(_form);

    if (hasMissingValues) return;

    await createSupplier(_form);

    onClose(true);
  };

  return (
    <Box display={'flex'} flexDirection={'column'}>
      <Box
        display="flex"
        width={'100%'}
        flexDirection="row"
        justifyContent={'space-between'}
      >
        <Box display={'flex'}>
          <Label>New Supplier</Label>
        </Box>
        <IconButton onClick={() => onClose(false)}>
          <CloseOutlined />
        </IconButton>
      </Box>
      <Box display="flex" width={'100%'} flexDirection="column">
        <TextInput
          label={'Supplier Name'}
          value={form.supplierName}
          onChange={(e) => handleChangeInput('supplierName', e.target.value)}
          placeholder="Enter the supplier name"
        />
        <TextInput
          label={'Manager'}
          value={form.managerName}
          onChange={(e) => handleChangeInput('managerName', e.target.value)}
          placeholder="Enter the manager name"
        />
        <TextInput
          label={'E-mail'}
          value={form.email}
          onChange={(e) => handleChangeInput('email', e.target.value)}
          placeholder="Enter the e-mail address"
        />
        <TextInput
          label={'Phone'}
          value={form.phoneNumber}
          onChange={(e) => handleChangeInput('phoneNumber', e.target.value)}
          placeholder="Enter the phone number"
        />
      </Box>

      <Box display={'flex'} justifyContent={'flex-end'} mt={2}>
        <Button text={'CREATE'} onClick={handleOnCreate} />
      </Box>
    </Box>
  );
};

export default AddSupplierContent;
