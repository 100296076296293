import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { getPartners } from '@apis/partner';
import { PageResponse } from '@models/api';
import { Partner, PartnersSearchForm } from '@models/partner';
import { dropEmptyValues } from '../../utils/common';

export const usePartnerList = ({
  page,
  pageSize,
  searchParam,
}: {
  page: number;
  pageSize: number;
  searchParam: PartnersSearchForm;
}) => {
  const queryOptions: UseQueryOptions<PageResponse<Partner>, Error> = {
    queryKey: ['partners', page],
    queryFn: () => {
      return getPartners({
        pageNo: page,
        pageSize: pageSize,
        ...dropEmptyValues(searchParam, [
          'name',
          'manager',
          'email',
          'address',
        ]),
      });
    },
    refetchOnMount: 'always',
  };
  return useQuery(queryOptions);
};
