import './App.css';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import Home from '@pages/Home';
import Login from '@pages/Login';
import Root from '@pages/Root';
import Error from '@pages/Error';
import Partners from '@pages/partners/Partners';
import AddPartner from '@pages/partners/AddPartner';
import PartnerDetail from '@pages/partners/PartnerDetail';
import { checkIfSignIn } from '@utils/user';
import Codes from '@pages/stock/Codes';
import EsimInventories from '@pages/stock/EsimInventories';
import EsimInventoryDetail from '@pages/stock/EsimInventoryDetail';
import Upload from '@pages/stock/Upload';
import Update from '@pages/stock/Update';
import Suppliers from '@pages/stock/Suppliers';
import UploadDetail from '@pages/stock/UploadDetail';
import UpdateDetail from '@pages/stock/UpdateDetail';
import Products from '@pages/product/Products';
import AddProduct from '@pages/product/AddProduct';
import ProductDetail from '@pages/product/ProductDetail';
import Orders from '@pages/orders/Orders';
import OrderDetail from '@pages/orders/OrderDetail';
import Users from '@pages/users/Users';
import ForgotPassword from '@pages/ForgotPassword';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import MyDetail from '@pages/partners/MyDetail';
import PartnerRoute from './PartnerRoute';
import AdminRoute from './AdminRoute';
import PartnerPriceList from '@pages/partners/PartnerPriceList';
import { PartnerPriceUpload } from '@pages/partners/PartnerPriceUpload';
import { PartnerPriceUploadDetail } from './pages/partners/PartnerPriceUploadDetail';
import { ExchangeMain } from './exchange/main';
import { ExchangeError } from './exchange/error';
import { ExchangeSuccess } from './exchange/success';
import MyPartnerPriceList from './pages/partners/MyPartnerPriceList';
import { AddOrder } from './pages/orders/AddOrder';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Root />,
    errorElement: <Error />,
    loader: checkIfSignIn,
    children: [
      {
        element: <AdminRoute />,
        children: [
          { path: 'home', element: <Home /> },
          {
            path: 'partners',
            children: [
              { path: '', element: <Partners /> },
              { path: 'add', element: <AddPartner /> },
              { path: ':partnerId', element: <PartnerDetail /> },
              { path: ':partnerId/prices', element: <PartnerPriceList /> },
              {
                path: ':partnerId/prices/upload',
                element: <PartnerPriceUpload />,
              },
              {
                path: ':partnerId/prices/upload/:uploadId',
                element: <PartnerPriceUploadDetail />,
              },
            ],
          },
          {
            path: 'users',
            children: [{ path: '', element: <Users /> }],
          },
          {
            path: 'products',
            children: [
              { path: '', element: <Products /> },
              { path: 'add', element: <AddProduct /> },
              { path: ':productId', element: <ProductDetail /> },
            ],
          },
          {
            path: 'stocks',
            children: [
              { path: 'codes', element: <Codes /> },
              { path: 'esim-inventory', element: <EsimInventories /> },
              {
                path: 'esim-inventory/:esimInventoryId',
                element: <EsimInventoryDetail />,
              },
              {
                path: 'upload',
                children: [
                  { path: '', element: <Upload /> },
                  { path: ':uploadId', element: <UploadDetail /> },
                ],
              },
              {
                path: 'update',
                children: [
                  { path: '', element: <Update /> },
                  { path: ':updateId', element: <UpdateDetail /> },
                ],
              },
              { path: 'suppliers', element: <Suppliers /> },
            ],
          },
          {
            path: 'orders',
            children: [
              { path: '', element: <Orders /> },
              { path: ':orderId', element: <OrderDetail /> },
            ],
          },
        ],
      },
      {
        element: <PartnerRoute />,
        children: [
          {
            path: 'my-detail',
            children: [{ path: '', element: <MyDetail /> }],
          },
          {
            path: 'my-detail/prices',
            children: [{ path: '', element: <MyPartnerPriceList /> }],
          },
          {
            path: 'orders',
            children: [
              { path: '', element: <Orders /> },
              { path: ':orderId', element: <OrderDetail /> },
              { path: 'add', element: <AddOrder /> },
            ],
          },
        ],
      },
    ],
  },
  { path: 'login', element: <Login /> },
  { path: 'forgot-password', element: <ForgotPassword /> },
  {
    children: [
      {
        path: 'exchange',
        children: [
          { path: '', element: <ExchangeMain /> },
          { path: ':encryptedEsimId', element: <ExchangeSuccess /> },
          { path: 'error', element: <ExchangeError /> },
        ],
      },
    ],
  },
]);

function App() {
  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY as string}
    >
      <RouterProvider router={router} />
      <ReactQueryDevtools initialIsOpen={true} />
    </GoogleReCaptchaProvider>
  );
}

export default App;
