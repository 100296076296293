import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
  styled,
  tableCellClasses,
} from '@mui/material';
import { useState } from 'react';
import TextInput from '@components/common/TextInput';
import { EsimStatus, EsimType } from '@models/stock';
import Select, { SelectValue } from '@components/common/Select';
import IconButton from '@components/common/IconButton';
import { DeleteOutline } from '@mui/icons-material';
import Button from '@components/common/Button';
import { useNavigate, useParams } from 'react-router-dom';
import {
  EsimInventoryResponse,
  addEsimInventoryMemo,
  deleteEsimInventoryMemo,
  editEsimInventory,
} from '@apis/esim';
import { useInventoryDetail } from '@queries/inventories/useInventoryDetail';
import { useInventoryEvents } from '@queries/inventories/useInventoryEvents';
import { dateToTimestamp, timestampToDate } from '@utils/time';
import useAsyncEffect from '@hooks/useAsyncEffect';
import Modal from '@components/common/Modal';
import QRCode from 'qrcode.react';

const EsimInventoryDetail = () => {
  const { esimInventoryId } = useParams();
  const [form, setForm] = useState<EsimInventoryResponse>({
    esimInventoryId: Number(esimInventoryId),
    redemptionCode: '',
    serialNo: '',
    esimType: EsimType.DISCOVERY,
    esimStatus: EsimStatus.READY,
    expireAt: 0,
    stock: {
      stockId: 0,
      stockCode: '',
      stockName: '',
      esimType: EsimType.DISCOVERY,
    },
    iccid: '',
    imsi: '',
    msisdn: '',
    pin: '',
    puk: '',
  });
  const [memo, setMemo] = useState('');
  const [showQR, setShowQR] = useState(false);

  const { data: esimInventoryDetail, refetch } = useInventoryDetail(
    Number(esimInventoryId)
  );

  const navigate = useNavigate();

  const { data: esimInventoryEventsData, refetch: refetchInventoryEventsData } =
    useInventoryEvents(Number(esimInventoryId));

  const handleChangeInput = (
    name: keyof EsimInventoryResponse,
    value: string | number
  ) => {
    setForm((prev) => ({ ...prev, [name]: value }));
  };

  const handleChangeSelect = (
    name: keyof EsimInventoryResponse,
    value: SelectValue
  ) => {
    setForm((prev) => ({ ...prev, [name]: value }));
  };

  const handleOnQR = () => {
    setShowQR(true);
  };

  const handleOnUpdate = async () => {
    if (!!window.confirm('Are you sure to update this inventory?')) {
      await editEsimInventory({
        esimInventoryId: Number(esimInventoryId),
        esimStatus: form.esimStatus,
        expireAt: form.expireAt,
      });
    }

    refetch();
    refetchInventoryEventsData();
  };

  const handleOnAddMemo = async () => {
    await addEsimInventoryMemo(Number(esimInventoryId), memo);

    refetchInventoryEventsData();
    setMemo('');
  };

  const handleDeleteMemo = async (esimInventoryEventId: number) => {
    if (!!window.confirm('Are you sure to delete this event?')) {
      await deleteEsimInventoryMemo(
        Number(esimInventoryId),
        esimInventoryEventId
      );
    }

    refetchInventoryEventsData();
  };

  useAsyncEffect(async () => {
    if (!esimInventoryId || !esimInventoryDetail) return;
    setForm(esimInventoryDetail?.data[0]);
  }, [esimInventoryDetail, esimInventoryId]);

  return (
    <>
      <Typography variant="h4" component="h4">
        {form.stock.stockCode}
      </Typography>
      <Box display={'flex'} flexDirection={'column'} gap={3} mt={2}>
        <Box
          display={'flex'}
          flexDirection={'row'}
          alignItems={'center'}
          gap={3}
        >
          {form.esimType === EsimType.LPA && (
            <>
              <TextInput
                label={`LPA URI`}
                value={form.lpaUrl}
                onChange={(e) => handleChangeInput('lpaUrl', e.target.value)}
                layout="horizontal"
                labelWidth={100}
                inputWidth={700}
                disabled
              />
              <Button text={'QR'} onClick={handleOnQR} />
            </>
          )}
          {form.esimType === EsimType.REDEMPTION && (
            <TextInput
              label={`Redemption Code`}
              value={form.redemptionCode}
              onChange={(e) =>
                handleChangeInput('redemptionCode', e.target.value)
              }
              layout="horizontal"
              labelWidth={100}
              inputWidth={700}
              disabled
            />
          )}
        </Box>
        <Box
          display={'flex'}
          flexDirection={'row'}
          gap={3}
          alignItems={'center'}
        >
          <TextInput
            label={'MSISDN'}
            value={form.msisdn}
            onChange={(e) => handleChangeInput('msisdn', e.target.value)}
            layout="horizontal"
            labelWidth={100}
            inputWidth={200}
            disabled
          />
          <TextInput
            label={'ICCID'}
            value={form.iccid}
            onChange={(e) => handleChangeInput('iccid', e.target.value)}
            layout="horizontal"
            labelWidth={100}
            inputWidth={200}
            disabled
          />
          <TextInput
            label={'IMSI'}
            value={form.imsi}
            onChange={(e) => handleChangeInput('imsi', e.target.value)}
            layout="horizontal"
            labelWidth={100}
            inputWidth={200}
            disabled
          />
          <TextInput
            label={'Serial No'}
            value={form.serialNo}
            onChange={(e) => handleChangeInput('serialNo', e.target.value)}
            labelWidth={100}
            layout="horizontal"
            inputWidth={200}
            disabled
          />
        </Box>
        <Box
          display={'flex'}
          flexDirection={'row'}
          gap={3}
          alignItems={'center'}
        >
          <TextInput
            label={'PIN'}
            value={form.pin}
            onChange={(e) => handleChangeInput('pin', e.target.value)}
            layout="horizontal"
            labelWidth={100}
            inputWidth={200}
            disabled
          />
          <TextInput
            label={'PUK'}
            value={form.puk}
            onChange={(e) => handleChangeInput('puk', e.target.value)}
            layout="horizontal"
            labelWidth={100}
            inputWidth={200}
            disabled
          />
        </Box>
        <Box
          display={'flex'}
          flexDirection={'row'}
          gap={3}
          alignItems={'center'}
        >
          <TextInput
            label={'Exp. Date'}
            value={timestampToDate(form.expireAt, 'YYYY-MM-DD')}
            onChange={(e) => {
              const expireAt = dateToTimestamp(e.target.value);
              handleChangeInput('expireAt', expireAt);
            }}
            layout="horizontal"
            labelWidth={100}
            type="date"
            inputWidth={200}
          />
          <Select
            label={'State'}
            value={form.esimStatus}
            setValue={(value) => handleChangeSelect('esimStatus', value)}
            optionList={Object.values(EsimStatus).map((s) => ({
              label: s,
              value: s,
            }))}
            placeholder="Select eSIM type"
            layout="horizontal"
            labelWidth={100}
            inputWidth={200}
          />
          <Button text={'UPDATE'} onClick={handleOnUpdate} />
        </Box>
        <Box
          display={'flex'}
          flexDirection={'row'}
          gap={3}
          alignItems={'center'}
        >
          <TextInput
            label={'Memo'}
            value={memo}
            onChange={(e) => setMemo(e.target.value)}
            layout="horizontal"
            labelWidth={100}
            inputWidth={800}
          />
          <Button text={'ADD'} onClick={handleOnAddMemo} />
        </Box>
      </Box>

      <Toolbar />
      <Typography variant="h5">Memos</Typography>

      <TableContainer
        component={Paper}
        sx={{
          marginTop: 2,
        }}
      >
        <Table>
          <TableHead
            sx={{
              backgroundColor: '#f5f5f5',
            }}
          >
            <TableRow>
              <StyledTableCell>Index</StyledTableCell>
              <StyledTableCell>Event</StyledTableCell>
              <StyledTableCell>Owner</StyledTableCell>
              <StyledTableCell>Description</StyledTableCell>
              <StyledTableCell>Order ID</StyledTableCell>
              <StyledTableCell>Date & Time</StyledTableCell>
              <StyledTableCell>Delete</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(esimInventoryEventsData?.data || []).map((event, index) => (
              <StyledTableRow key={event.esimInventoryEventId}>
                <StyledTableCell>{index + 1}</StyledTableCell>
                <StyledTableCell>{event.eventType}</StyledTableCell>
                <StyledTableCell>{event.owner}</StyledTableCell>
                <StyledTableCell>{event.description}</StyledTableCell>
                <StyledTableCell
                  sx={{
                    color: '#1976d2',
                    cursor: 'pointer',
                    textDecoration: 'underline',
                  }}
                  onClick={() => {
                    navigate(`/orders/${event.orderId}`);
                  }}
                >
                  {event.orderId}
                </StyledTableCell>
                <StyledTableCell>
                  {timestampToDate(event.createdAt)}
                </StyledTableCell>
                <StyledTableCell>
                  <IconButton
                    onClick={() => {
                      handleDeleteMemo(event.esimInventoryEventId);
                    }}
                  >
                    <DeleteOutline />
                  </IconButton>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Toolbar />
      <Modal
        open={showQR}
        onClose={() => {
          setShowQR(false);
        }}
      >
        <QRCode value={form.lpaUrl ?? ''} size={400} />
      </Modal>
    </>
  );
};

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#000',
    color: '#fff',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(() => ({
  backgroundColor: '#FFFFFF',
  '&:hover': {
    backgroundColor: '#F3F6F9',
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export default EsimInventoryDetail;
