import { userState } from '@recoils/atoms';
import { Navigate, Outlet } from 'react-router-dom';
import { useRecoilValueLoadable } from 'recoil';

function PartnerRoute() {
  const userLoadingState = useRecoilValueLoadable(userState);

  switch (userLoadingState.state) {
    case 'hasValue':
      return <Outlet />;
    case 'loading':
      return <div>Loading...</div>;
    case 'hasError':
      return <Navigate to="/login" />;
  }
}

export default PartnerRoute;
