import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { getPartnerProductPricesCount } from '@apis/partner';
import { GetProductsParams } from '../../apis/product';
import { dropEmptyValues } from '../../utils/common';

export const usePartnerProductPriceCount = ({
  partnerId,
  searchParam,
}: {
  partnerId: number;
  searchParam: GetProductsParams;
}) => {
  const queryOptions: UseQueryOptions<number, Error> = {
    queryKey: ['partnerProductPriceCount', partnerId],
    queryFn: () => {
      return getPartnerProductPricesCount(
        partnerId,
        dropEmptyValues(searchParam, [
          'productCode',
          'productName',
          'stockId',
          'countryId',
          'productStatus',
        ])
      );
    },
    enabled: !!partnerId,
  };
  return useQuery(queryOptions);
};
