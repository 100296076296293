import { Toolbar, Typography } from '@material-ui/core';
import AppBar from '@mui/material/AppBar';
import useAuth from '@hooks/useAuth';
import { Box, Divider, Link } from '@mui/material';
import { useRecoilState } from 'recoil';
import { userState } from '@recoils/atoms';
import Modal from './Modal';
import ChangePasswordContent from '@components/user/ChangePasswordContent';
import { useState } from 'react';
import { UserGroup } from '@models/user';
import { usePartnerDetail } from '@queries/partners/usePartnerDetail';
import { toUsdDisplay } from '@utils/money';

function Header() {
  const { logOut, isAuthenticated } = useAuth();
  const [changePassword, setChangePassword] = useState(false);

  const [user, _setUser] = useRecoilState(userState);
  const { data: partnerDetailForm, refetch } = usePartnerDetail(
    Number(user?.partnerId),
    // polls every 30 seconds
    30 * 1000
  );
  const handleChangePassword = () => {
    setChangePassword(true);
  };

  const handleLogout = () => {
    if (!!window.confirm('Are you sure you want to logout?')) {
      logOut();
    }
  };

  return (
    <>
      <AppBar
        position="fixed"
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <Toolbar
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
            background: '#E2E2E2',
          }}
        >
          <Typography
            variant="h4"
            style={{
              color: '#000',
              fontWeight: 'bold',
            }}
          >
            eSIMSTA
          </Typography>

          <Box display="flex" flexDirection="row" gap={2}>
            {/* partner인 경우 */}
            {user && user.group === UserGroup.PARTNER && (
              <Box display="flex" flexDirection="row" gap={1}>
                <Typography
                  style={{
                    color: '#000',
                    fontWeight: 'bold',
                  }}
                >
                  {partnerDetailForm?.data[0].name}
                </Typography>
                <Typography
                  style={{
                    color: '#000',
                  }}
                >
                  USD
                </Typography>
                <Typography
                  style={{
                    color: '#000',
                    fontWeight: 'bold',
                  }}
                >
                  {toUsdDisplay(partnerDetailForm?.data[0].balance || 0)}
                </Typography>
              </Box>
            )}
            {user && (
              <Link
                component="button"
                variant="body2"
                color="#000"
                style={{
                  cursor: 'text',
                }}
              >
                {user.name} ({user.username})
              </Link>
            )}
            <Divider orientation="vertical" flexItem />
            <Link
              component="button"
              variant="body2"
              color="#000"
              underline="always"
              onClick={handleLogout}
            >
              Logout
            </Link>
            <Divider orientation="vertical" flexItem />
            <Link
              component="button"
              variant="body2"
              color="#000"
              underline="always"
              onClick={handleChangePassword}
            >
              Change password
            </Link>
            <Modal
              open={changePassword}
              onClose={() => {
                setChangePassword(false);
              }}
            >
              <ChangePasswordContent
                onClose={() => {
                  setChangePassword(false);
                }}
              />
            </Modal>
          </Box>
        </Toolbar>
      </AppBar>
    </>
  );
}
export default Header;
