import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { PageResponse } from '@models/api';
import { EsimInventoryResponse, getEsimInventory } from '@apis/esim';

export const useInventoryDetail = (esimInventoryId: number) => {
  const queryOptions: UseQueryOptions<
    PageResponse<EsimInventoryResponse>,
    Error
  > = {
    queryKey: ['inventoryDetail', esimInventoryId],
    queryFn: () => {
      return getEsimInventory(esimInventoryId);
    },
  };
  return useQuery(queryOptions);
};
