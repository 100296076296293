import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { PageResponse } from '@models/api';
import { getSales, GetSalesResponse } from '@apis/summary';
import { getTimestampRangeByPeriod } from '@utils/time';

export const useSummaryToday = () => {
  const queryOptions: UseQueryOptions<PageResponse<GetSalesResponse>, Error> = {
    queryKey: ['dashboardSummaryToday'],
    queryFn: () => {
      const { from, to } = getTimestampRangeByPeriod('DAYS');
      return getSales({
        periodUnit: 'DAYS',
        from,
        to,
      });
    },
  };
  return useQuery(queryOptions);
};

export const useSummaryThisWeek = () => {
  const queryOptions: UseQueryOptions<PageResponse<GetSalesResponse>, Error> = {
    queryKey: ['dashboardSummaryThisWeek'],
    queryFn: () => {
      const { from, to } = getTimestampRangeByPeriod('WEEKS');
      return getSales({
        periodUnit: 'WEEKS',
        from,
        to,
      });
    },
  };
  return useQuery(queryOptions);
};

export const useSummayThisMonth = () => {
  const queryOptions: UseQueryOptions<PageResponse<GetSalesResponse>, Error> = {
    queryKey: ['dashboardSummaryThisMonth'],
    queryFn: () => {
      const { from, to } = getTimestampRangeByPeriod('MONTHS');
      return getSales({
        periodUnit: 'MONTHS',
        from,
        to,
      });
    },
  };
  return useQuery(queryOptions);
};

export const useSummaryThisYear = () => {
  const queryOptions: UseQueryOptions<PageResponse<GetSalesResponse>, Error> = {
    queryKey: ['dashboardSummaryThisYear'],
    queryFn: () => {
      const { from, to } = getTimestampRangeByPeriod('YEARS');
      return getSales({
        periodUnit: 'YEARS',
        from,
        to,
      });
    },
  };
  return useQuery(queryOptions);
};
