import * as React from 'react';
import { FormControl, FormControlProps } from '@mui/base/FormControl';
import { Box } from '@mui/material';
import Label from './Label';

export type InputWrapperBaseProps = {
  label?: string;
  layout?: 'horizontal' | 'vertical';
  labelWidth?: number;
  inputWidth?: number;
  unit?: string;
};

type InputWrapperProps = React.PropsWithChildren<
  InputWrapperBaseProps & FormControlProps
>;

const InputWrapper = ({
  label,
  layout = 'vertical',
  labelWidth = 50,
  inputWidth,
  children,
  unit,
  ...props
}: InputWrapperProps) => {
  return (
    <FormControl
      {...props}
      style={{
        width: layout === 'horizontal' ? undefined : inputWidth,
      }}
    >
      <Box
        display={'flex'}
        flexDirection={layout === 'horizontal' ? 'row' : 'column'}
        gap={layout === 'horizontal' ? 2 : 0}
        alignItems={layout === 'horizontal' ? 'center' : 'flex-start'}
        justifyContent={layout === 'horizontal' ? 'flex-start' : 'center'}
        width="100%"
      >
        {label && (
          <Box
            width={layout === 'horizontal' ? labelWidth : '100%'}
            display={'flex'}
          >
            <Label>{label}</Label>
          </Box>
        )}
        <Box width={layout === 'horizontal' ? inputWidth : '100%'}>
          {children}
        </Box>
        {unit && (
          <Box display={'flex'}>
            <Label>{unit}</Label>
          </Box>
        )}
      </Box>
    </FormControl>
  );
};

export default InputWrapper;
