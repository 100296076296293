import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { PageResponse } from '@models/api';
import { GetProductsParams, ProductResponse, getProducts } from '@apis/product';
import { dropEmptyValues } from '../../utils/common';

export const useProductList = ({
  page,
  searchParam,
}: {
  page: number;
  searchParam: GetProductsParams;
}) => {
  const queryOptions: UseQueryOptions<PageResponse<ProductResponse>, Error> = {
    queryKey: ['products', page],
    queryFn: () => {
      return getProducts({
        pageNo: page,
        pageSize: 50,
        ...dropEmptyValues(searchParam, [
          'productCode',
          'productName',
          'stockId',
          'countryId',
          'productStatus',
        ]),
      });
    },
    refetchOnMount: 'always',
  };
  return useQuery(queryOptions);
};
