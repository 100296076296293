import { useRef, useState, MouseEvent, DragEvent, ChangeEvent } from 'react';
import { styled } from '@mui/system';
import Button from './Button';
import { Box } from '@mui/material';

type DragDropFileProps = {
  onConfirmFile: (file: File) => void;
};

const DragDropFile = ({ onConfirmFile }: DragDropFileProps) => {
  const [dragActive, setDragActive] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const [file, setFile] = useState<File | null>(null);

  const handleDrag = (e: DragEvent<HTMLDivElement | HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const handleDrop = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      setFile(e.dataTransfer.files[0]);
    }
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      setFile(e.target.files[0]);
    }
  };

  const onButtonClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (!inputRef.current) return;
    inputRef.current.click();
  };

  return (
    <Form onDragEnter={handleDrag} onSubmit={(e) => e.preventDefault()}>
      <Input ref={inputRef} type="file" onChange={handleChange} />
      <Label dragActive={dragActive}>
        {file ? (
          <Box
            display="flex"
            flexDirection={'column'}
            justifyContent={'center'}
            textAlign={'center'}
          >
            <p>{file.name}</p>
            <Box justifyContent={'center'} display="flex">
              <Button
                text={'Confirm'}
                onClick={() => {
                  onConfirmFile(file);
                }}
              />
            </Box>
          </Box>
        ) : (
          <div>
            <p>Drag and drop your file here</p>
            <p>or</p>
            <Button text={'Choose File'} onClick={onButtonClick} />
          </div>
        )}
      </Label>

      {dragActive && (
        <DragFileElement
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={handleDrop}
        />
      )}
    </Form>
  );
};

const Form = styled('form')({
  height: '16rem',
  maxWidth: '100%',
  textAlign: 'center',
  position: 'relative',
});

const Input = styled('input')({
  display: 'none',
});

const Label = styled('label')(({ dragActive }: { dragActive: boolean }) => ({
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderWidth: '2px',
  borderRadius: '1rem',
  borderStyle: 'dashed',
  borderColor: '#cbd5e1',
  backgroundColor: dragActive ? '#ffffff' : '#f8fafc',
}));

const DragFileElement = styled('div')({
  position: 'absolute',
  width: '100%',
  height: '100%',
  borderRadius: '1rem',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
});

export default DragDropFile;
