import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { PageResponse } from '@models/api';
import { OrderResponse, getOrder } from '@apis/order';

export const useOrderDetail = (orderId: number) => {
  const queryOptions: UseQueryOptions<PageResponse<OrderResponse>, Error> = {
    queryKey: ['orderDetail', orderId],
    queryFn: () => {
      return getOrder(orderId);
    },
  };
  return useQuery(queryOptions);
};
