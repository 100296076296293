import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { getPartnerTransactions } from '@apis/partner';
import { PageResponse } from '@models/api';
import { PartnerTransaction } from '@models/partner';

export const usePartnerTransactions = ({
  page,
  pageSize,
  partnerId,
}: {
  page: number;
  pageSize: number;
  partnerId: number;
}) => {
  const queryOptions: UseQueryOptions<
    PageResponse<PartnerTransaction>,
    Error
  > = {
    queryKey: ['partnerTransactions', page, partnerId],
    queryFn: () => {
      return getPartnerTransactions(partnerId, {
        pageNo: page,
        pageSize: pageSize,
      });
    },
    enabled: !!partnerId,
  };
  return useQuery(queryOptions);
};
