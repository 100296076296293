import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { PageResponse } from '@models/api';
import { GetStocksParams, StockResponse, getStocks } from '@apis/stock';
import { dropEmptyValues } from '../../utils/common';

export const useStockList = ({
  page,
  searchParam,
}: {
  page: number;
  searchParam: GetStocksParams;
}) => {
  const queryOptions: UseQueryOptions<PageResponse<StockResponse>, Error> = {
    queryKey: ['stocks', page],
    queryFn: () => {
      return getStocks({
        pageNo: page,
        pageSize: 50,
        ...dropEmptyValues(searchParam, [
          'stockCode',
          'stockName',
          'regionId',
          'supplierId',
          'status',
          'ordering',
        ]),
      });
    },
    refetchOnMount: 'always',
  };
  return useQuery(queryOptions);
};
