import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { PageResponse } from '@models/api';
import { getUsers } from '@apis/users';
import { User } from '@models/user';
import { UserSearchForm } from '@pages/users/Users';
import { dropEmptyValues } from '../../utils/common';

export const useUserList = ({
  page,
  pageSize,
  searchParam,
}: {
  page: number;
  pageSize: number;
  searchParam: UserSearchForm;
}) => {
  const queryOptions: UseQueryOptions<PageResponse<User>, Error> = {
    queryKey: ['users', page],
    queryFn: () => {
      return getUsers({
        pageNo: page,
        pageSize: pageSize,
        ...dropEmptyValues(searchParam, [
          'username',
          'name',
          'group',
          'status',
        ]),
      });
    },
    refetchOnMount: 'always',
  };
  return useQuery(queryOptions);
};
