import * as React from 'react';
import { Input as BaseInput, InputProps } from '@mui/base/Input';
import { styled } from '@mui/system';
import { blue, grey } from './colors';
import InputWrapper, { InputWrapperBaseProps } from './InputWrapper';

type TextAreaInputProps = InputProps & InputWrapperBaseProps;

const TextAreaInput = React.forwardRef(function CustomInput(
  props: TextAreaInputProps,
  ref: React.ForwardedRef<HTMLDivElement>
) {
  return (
    <InputWrapper {...(props as InputWrapperBaseProps)}>
      <BaseInput
        slots={{
          root: RootDiv,
          input: 'input',
          textarea: TextareaElement,
        }}
        {...props}
        ref={ref}
      />
    </InputWrapper>
  );
});

const RootDiv = styled('div')`
  display: flex;
  max-width: 100%;
`;

const TextareaElement = styled('textarea', {
  shouldForwardProp: (prop) =>
    !['ownerState', 'minRows', 'maxRows'].includes(prop.toString()),
})(
  ({ theme }) => `
  width: 100%;
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5rem;
  padding: 8px 12px;
  border-radius: 8px 8px 0 8px;
  color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
  border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
  box-shadow: 0px 2px 4px ${
    theme.palette.mode === 'dark' ? 'rgba(0,0,0, 0.5)' : 'rgba(0,0,0, 0.05)'
  };

  &:hover {
    border-color: ${blue[400]};
  }

  &:focus {
    border-color: ${blue[400]};
    box-shadow: 0 0 0 3px ${
      theme.palette.mode === 'dark' ? blue[700] : blue[200]
    };
  }

  // firefox
  &:focus-visible {
    outline: 0;
  }
`
);

export default TextAreaInput;
