import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { getUsersCount } from '@apis/users';
import { UserSearchForm } from '@pages/users/Users';
import { dropEmptyValues } from '../../utils/common';

export const useUserCount = (searchParam: UserSearchForm) => {
  const queryOptions: UseQueryOptions<number, Error> = {
    queryKey: ['usersCount'],
    queryFn: () => {
      return getUsersCount(
        dropEmptyValues(searchParam, ['username', 'name', 'group', 'status'])
      );
    },
  };
  return useQuery(queryOptions);
};
