import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { PageResponse } from '@models/api';
import {
  GetSuppliersParams,
  SupplierResponse,
  getSuppliers,
} from '@apis/supplier';
import { dropEmptyValues } from '../../utils/common';

export const useSupplierList = ({
  page,
  searchParam,
}: {
  page: number;
  searchParam: GetSuppliersParams;
}) => {
  const queryOptions: UseQueryOptions<PageResponse<SupplierResponse>, Error> = {
    queryKey: ['suppliers', page],
    queryFn: () => {
      return getSuppliers({
        pageNo: page,
        pageSize: 50,
        ...dropEmptyValues(searchParam, [
          'supplierName',
          'managerName',
          'email',
          'phoneNumber',
        ]),
      });
    },
    refetchOnMount: 'always',
  };
  return useQuery(queryOptions);
};
