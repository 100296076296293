import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { PageResponse } from '@models/api';
import { getAllSuppliers, ListSupplierResponse } from '@apis/supplier';

export const useAllSupplierList = () => {
  const queryOptions: UseQueryOptions<
    PageResponse<ListSupplierResponse>,
    Error
  > = {
    queryKey: ['allSuppliers'],
    queryFn: () => {
      return getAllSuppliers();
    },
  };
  return useQuery(queryOptions);
};
