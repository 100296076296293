import * as React from 'react';
import TextInput from '@components/common/TextInput';
import { Box, Divider } from '@mui/material';
import { CloseOutlined } from '@mui/icons-material';
import Label from '@components/common/Label';
import IconButton from '@components/common/IconButton';
import Button from '@components/common/Button';
import { RefundForm } from '@models/order';
import { OrderResponse, refundOrder } from '@apis/order';

type FullRefundEsimContentProps = {
  onClose: (refresh: boolean) => void;
  order: OrderResponse;
  totalCount: number;
};

const FullRefundEsimContent = ({
  onClose,
  order,
  totalCount,
}: FullRefundEsimContentProps) => {
  const [form, setForm] = React.useState<RefundForm>({
    refundFee: 0,
  });

  const handleOnConfirm = async () => {
    // refund API
    if (
      !!window.confirm(
        `Would you like to proceed with a refund of $${
          order.paymentAmount - form.refundFee
        }?`
      )
    ) {
      await refundOrder(order.orderId, {
        esimType: order.stock.esimType,
        refundType: 'FULL_REFUND',
        esimIds: [],
        refundFee: form.refundFee,
      });
      onClose(true);
    }
  };

  const remainingPaymentAmount = order.paymentAmount - order.refundAmount;

  return (
    <Box display={'flex'} flexDirection={'column'}>
      <Box
        display="flex"
        width={'100%'}
        flexDirection="row"
        justifyContent={'space-between'}
      >
        <Box display={'flex'}>
          <Label>Full Refund</Label>
        </Box>
        <IconButton onClick={() => onClose(false)}>
          <CloseOutlined />
        </IconButton>
      </Box>
      <Box display="flex" width={'100%'} flexDirection="column" gap={1}>
        {/* order */}
        <TextInput label={'Order Code'} value={order.orderCode} disabled />
        <Divider />
        {/* product */}
        <TextInput
          label={'Product Code'}
          value={order.product.productCode}
          disabled
        />
        <TextInput
          label={'Product Name'}
          value={order.product.productName}
          disabled
        />
        <Divider />
        {/* refund form */}
        <Box width={'100%'} display="flex" flexDirection="column">
          <Box width={'100%'} display="flex" flexDirection="column">
            <Label sx={{ width: 200 }}>{'Amount (USD)'}</Label>
            <Box width={'100%'} display="flex" alignItems="center" gap={3}>
              <TextInput
                layout="horizontal"
                value={order.paymentAmount / totalCount}
                disabled
              />
              X
              <TextInput layout="horizontal" value={totalCount} disabled />
              =
              <TextInput
                layout="horizontal"
                value={order.paymentAmount}
                disabled
              />
            </Box>
          </Box>
          <Box width={'100%'} display="flex" flexDirection="column">
            <Label sx={{ width: 200 }}>{'Refund Fee (USD)'}</Label>
            <Box width={'100%'} display="flex" alignItems="center" gap={3}>
              <TextInput
                layout="horizontal"
                value={form.refundFee}
                onChange={(e) =>
                  setForm({
                    ...form,
                    refundFee: Number(e.target.value),
                  })
                }
              />
              X
              <TextInput layout="horizontal" value={totalCount} disabled />
              =
              <TextInput
                layout="horizontal"
                value={form.refundFee * totalCount}
                disabled
              />
            </Box>
          </Box>
          <Box width={'100%'} display="flex" flexDirection="column">
            <Label sx={{ width: 200 }}>{'Refund Amount (USD)'}</Label>
            <TextInput
              layout="horizontal"
              value={remainingPaymentAmount - form.refundFee * totalCount}
              disabled
            />
          </Box>
        </Box>
      </Box>
      <Box display={'flex'} justifyContent={'flex-end'} mt={2}>
        <Button
          text={'OK'}
          onClick={handleOnConfirm}
          disabled={
            totalCount === 0 ||
            form.refundFee * totalCount >= remainingPaymentAmount
          }
        />
      </Box>
    </Box>
  );
};

export default FullRefundEsimContent;
