import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { PageResponse } from '@models/api';
import {
  GetSalesRankProductResponse,
  PeriodUnit,
  getProductSalesRank,
} from '@apis/summary';
import { getTimestampRangeByPeriod } from '@utils/time';

export const useSummarySalesByProduct = (period: PeriodUnit) => {
  const queryOptions: UseQueryOptions<
    PageResponse<GetSalesRankProductResponse>,
    Error
  > = {
    queryKey: ['dashboardSummaySalesByProduct'],
    queryFn: () => {
      const { from, to } = getTimestampRangeByPeriod(period);
      return getProductSalesRank({ from, to });
    },
  };
  return useQuery(queryOptions);
};
