import { EsimType } from '@models/stock';

export const ESIM_TYPE_OPTION_LIST = [
  {
    value: EsimType.LPA,
    label: 'LPA URI',
  },
  {
    value: EsimType.REDEMPTION,
    label: 'Redemption Code',
  },
  {
    value: EsimType.DISCOVERY,
    label: 'eSIM Discovery',
  },
];
