import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { getPriceUploadHistoryDetailsCount } from '../../apis/partnerBulk';

export const usePriceUploadDetailsCount = (
  partnerId: number,
  uploadId: number
) => {
  const queryOptions: UseQueryOptions<number, Error> = {
    queryKey: ['priceIploadDetailsCount', partnerId, uploadId],
    queryFn: () => {
      return getPriceUploadHistoryDetailsCount(partnerId, uploadId);
    },
  };
  return useQuery(queryOptions);
};
