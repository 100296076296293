import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { GetOrderEsimsParams, getOrderEsimsCount } from '@apis/order';
import { dropEmptyValues } from '@utils/common';

export const useOrderEsimsCount = (
  orderId: number,
  params: GetOrderEsimsParams
) => {
  const queryOptions: UseQueryOptions<number, Error> = {
    queryKey: ['orderEsimsCount', orderId],
    queryFn: () => {
      return getOrderEsimsCount(
        orderId,
        dropEmptyValues(params, ['esimType', 'parameterType', 'parameterValue'])
      );
    },
  };
  return useQuery(queryOptions);
};
