import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { GetEsimInventoriesParams, getEsimInventoryCount } from '@apis/esim';
import { dropEmptyValues } from '../../utils/common';

export const useInventoryCount = (searchParam: GetEsimInventoriesParams) => {
  const queryOptions: UseQueryOptions<number, Error> = {
    queryKey: ['esimInventoriesCount'],
    queryFn: () => {
      return getEsimInventoryCount(
        dropEmptyValues(searchParam, [
          'stockId',
          'esimType',
          'esimStatus',
          'expireAtFrom',
          'expireAtTo',
          'parameterType',
          'parameterValue',
        ])
      );
    },
  };
  return useQuery(queryOptions);
};
