import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { GetSuppliersParams, getSuppliersCount } from '@apis/supplier';
import { dropEmptyValues } from '../../utils/common';

export const useSupplierCount = (searchParam: GetSuppliersParams) => {
  const queryOptions: UseQueryOptions<number, Error> = {
    queryKey: ['suppliersCount'],
    queryFn: () => {
      return getSuppliersCount(
        dropEmptyValues(searchParam, [
          'supplierName',
          'managerName',
          'email',
          'phoneNumber',
        ])
      );
    },
  };
  return useQuery(queryOptions);
};
