import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { PageResponse } from '@models/api';
import {
  GetUpdateHistoriesResponse,
  getUpdateHistory,
} from '@apis/esimBulk/update';

export const useUpdateDetail = (updateId: number) => {
  const queryOptions: UseQueryOptions<
    PageResponse<GetUpdateHistoriesResponse>,
    Error
  > = {
    queryKey: ['updateDetail', updateId],
    queryFn: () => {
      return getUpdateHistory(updateId);
    },
  };
  return useQuery(queryOptions);
};
