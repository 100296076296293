export type PartnerProductPriceBulkUpload = {
  partnerProductPriceBulkUploadId: number;
  filename: string;
  isDeleted: boolean;
  partnerId: number;
  totalCount: number;
  successCount: number;
  failedCount: number;
  status: string;
  createdAt: number;
  updatedAt: number;
};

export enum PartnerProductPriceBulkUploadStatus {
  PENDING = 'PENDING',
  PROCESSING = 'PROCESSING',
  COMPLETED = 'COMPLETED',
}

export type PartnerProductPriceBulkUploadDetailResponse = {
  partnerProductPriceBulkUploadDetailId: number;
  partnerProductPriceBulkUploadId: number;
  productCode: string;
  priceStatus: string;
  uploadStatus: PartnerProductPriceBulkUploadDetailStatus;
  discountRate: number;
  createdAt: number;
  updatedAt: number;
};
export type PartnerProductPriceBulkUploadDetail = {
  partnerProductPriceBulkUploadDetailId: number;
  partnerProductPriceBulkUploadId: number;
  productId: number;
  priceStatus: string;
  uploadStatus: PartnerProductPriceBulkUploadDetailStatus;
  discountRate: number;
  createdAt: number;
  updatedAt: number;
};
export enum PartnerProductPriceBulkUploadDetailStatus {
  OK = 'OK',
  DUPLICATE = 'DUPLICATE',
  INVALID = 'INVALID',
}
