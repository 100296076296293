import { EsimType } from '@models/stock';

export type EsimStock = {
  stockId: number;
  stockCode: string;
  stockName: string;
  esimType: EsimType;
};

export enum UploadStatus {
  OK = 'OK',
  DUPLICATE = 'DUPLICATE',
  ALREADY_EXIST = 'ALREADY_EXIST',
}

export enum UpdateStatus {
  OK = 'OK',
  DUPLICATE = 'DUPLICATE',
  ALREADY_EXIST = 'ALREADY_EXIST',
}

export enum Status {
  PENDING = 'PENDING',
  PROCESSING = 'PROCESSING',
  COMPLETED = 'COMPLETED',
}
