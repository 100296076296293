import API from '@apis/API';
import { PageResponse } from '@models/api';

export type ContinentResponse = {
  continentId: number;
  continentCode: string;
  englishName: string;
  koreanName: string;
};
export const getAllContinents = async () =>
  (await API.get<PageResponse<ContinentResponse>>(`/v1/continents`)).data;
