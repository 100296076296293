import {
  EsimInventoryResponse,
  GetEsimInventoriesParams,
  getEsimInventories,
} from '@apis/esim';
import { PageResponse } from '@models/api';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { dropEmptyValues } from '../../utils/common';

export const useInventoryList = ({
  page,
  pageSize,
  searchParam,
}: {
  page: number;
  pageSize: number;
  searchParam: GetEsimInventoriesParams;
}) => {
  const queryOptions: UseQueryOptions<
    PageResponse<EsimInventoryResponse>,
    Error
  > = {
    queryKey: ['esimInventories', page, searchParam],
    queryFn: () => {
      return getEsimInventories({
        pageNo: page,
        pageSize: pageSize,
        ...dropEmptyValues(searchParam, [
          'stockId',
          'esimType',
          'esimStatus',
          'expireAtFrom',
          'expireAtTo',
          'parameterType',
          'parameterValue',
        ]),
      });
    },
    refetchOnMount: 'always',
  };
  return useQuery(queryOptions);
};
