import { PageResponse } from '../models/api';
import API from './API';

export type RedeemEsimRequest = {
  redemptionCode: string;
  locale: string;
  email: string;
};
export type RedeemEsimResponse = {
  encryptedEsimId: string;
};

export type EsimDetailResponse = {
  productName: string;
  expireAt: number;
  lpaUrl: string;
};

export const redeemEsim = async (
  request: RedeemEsimRequest
): Promise<PageResponse<RedeemEsimResponse>> => {
  const { data } = await API.post<PageResponse<RedeemEsimResponse>>(
    '/v1/exchange',
    request
  );
  return data;
};

export const resendMail = async (
  request: RedeemEsimRequest
): Promise<PageResponse<RedeemEsimResponse>> => {
  const { data } = await API.post<PageResponse<RedeemEsimResponse>>(
    '/v1/exchange/resend',
    request
  );
  return data;
};

export const getEsimExchangeDetail = async (
  encryptedId: string
): Promise<PageResponse<EsimDetailResponse>> => {
  const { data } = await API.get<PageResponse<EsimDetailResponse>>(
    `/v1/exchange/${encryptedId}`
  );
  return data;
};
