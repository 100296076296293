import * as React from 'react';
import TextInput from '@components/common/TextInput';
import { Box } from '@mui/material';
import { CloseOutlined } from '@mui/icons-material';
import Label from '@components/common/Label';
import IconButton from '@components/common/IconButton';
import Button from '@components/common/Button';
import { SupplierUpsertRequest, editSupplier } from '@apis/supplier';
import { useSupplierDetail } from '@queries/suppliers/useSupplierDetail';
import useAsyncEffect from '@hooks/useAsyncEffect';
import { alertMissingValues, dropEmptyValues } from '@utils/common';

type EditSupplierContentProps = {
  onClose: (refetch: boolean) => void;
  supplierId: number;
};

type EditSupplierUpsertRequest = SupplierUpsertRequest;

const EditSupplierContent = ({
  onClose,
  supplierId,
}: EditSupplierContentProps) => {
  const [form, setForm] = React.useState<EditSupplierUpsertRequest>({
    supplierName: '',
    managerName: '',
    isDeleted: false,
    email: '',
    phoneNumber: '',
  });

  const { data: supplierDetailForm, isLoading } = useSupplierDetail(supplierId);

  const handleChangeInput = (
    name: keyof EditSupplierUpsertRequest,
    value: string | boolean
  ) => {
    setForm((prev) => ({ ...prev, [name]: value }));
  };

  const handleOnEdit = async () => {
    //validation
    const _form = dropEmptyValues(form, ['email', 'phoneNumber']);
    const hasMissingValues = alertMissingValues(_form);

    if (hasMissingValues) return;

    await editSupplier(supplierId, {
      ..._form,
    });

    onClose(true);
  };

  const handleOnDelete = async () => {
    if (!!window.confirm('Are you sure to delete this supplier?')) {
      await editSupplier(supplierId, {
        ...form,
        isDeleted: true,
      });
    }

    onClose(true);
  };

  React.useEffect(() => {
    if (!supplierDetailForm || !supplierDetailForm?.data[0]) return;
    const { supplierName, managerName, email, phoneNumber, isDeleted } =
      supplierDetailForm?.data[0];
    setForm({
      supplierName,
      managerName,
      email,
      phoneNumber,
      isDeleted,
    });
  }, [supplierDetailForm]);

  return (
    <Box display={'flex'} flexDirection={'column'} width={'100%'}>
      <Box
        display="flex"
        width={'100%'}
        flexDirection="row"
        justifyContent={'space-between'}
      >
        <Box display={'flex'}>
          <Label>
            {form.supplierName} ({supplierDetailForm?.data[0].supplierCode})
          </Label>
        </Box>
        <IconButton onClick={() => onClose(false)}>
          <CloseOutlined />
        </IconButton>
      </Box>
      <Box display="flex" width={'100%'} flexDirection="column">
        <TextInput
          label={'Manager'}
          value={form.managerName}
          onChange={(e) => handleChangeInput('managerName', e.target.value)}
          placeholder="Enter the manager name"
        />
        <TextInput
          label={'E-mail'}
          value={form.email}
          onChange={(e) => handleChangeInput('email', e.target.value)}
          placeholder="Enter the e-mail address"
        />
        <TextInput
          label={'Phone'}
          value={form.phoneNumber}
          onChange={(e) => handleChangeInput('phoneNumber', e.target.value)}
          placeholder="Enter the phone number"
        />
      </Box>

      <Box
        display={'flex'}
        flexDirection={'row'}
        width={'100%'}
        justifyContent={'space-between'}
        mt={2}
      >
        <Button text={'DELETE'} onClick={handleOnDelete} color={'error'} />
        <Button text={'OK'} onClick={handleOnEdit} />
      </Box>
    </Box>
  );
};

export default EditSupplierContent;
