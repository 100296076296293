import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { getActivePartnerProductPricesCount } from '@apis/partner';
import { dropEmptyValues } from '../../utils/common';
import { GetProductsParams } from '../../apis/product';

export const useActivePartnerProductPriceCount = ({
  partnerId,
  searchParam,
}: {
  partnerId: number;
  searchParam: GetProductsParams;
}) => {
  const queryOptions: UseQueryOptions<number, Error> = {
    queryKey: ['activePartnerProductPriceCount', partnerId],
    queryFn: () => {
      return getActivePartnerProductPricesCount(
        partnerId,
        dropEmptyValues(searchParam, [
          'productCode',
          'productName',
          'stockId',
          'countryId',
          'productStatus',
        ])
      );
    },
    enabled: !!partnerId,
  };
  return useQuery(queryOptions);
};
