import { timestampToDate } from './time';

export function generateProductCode(): string {
  const prefix = 'ESTA';

  const timestamp = Date.now();
  const generateRandomSuffix = () => {
    return Math.floor(1000 + Math.random() * 9000);
  };

  const suffix = generateRandomSuffix();

  return `${prefix}-${timestamp}-${suffix}`;
}

export function generateTransactionId(
  date: string,
  transactionId: number
): string {
  const timePrefix = timestampToDate(date, 'YYYYMMDD');
  const transactionIdSuffix = transactionId.toString().padStart(7, '0');

  return `${timePrefix}-${transactionIdSuffix}`;
}
