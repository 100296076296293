import API from './API';
import { PageParams, PageResponse } from '@models/api';
import { EsimType, StockStatus } from '@models/stock';

export enum StockOrder {
  REORDER_REQUIRED = 'REORDER_REQUIRED',
}

export enum StockSupplierTaskType {
  API = 'API',
}

export type GetStocksParams = {
  stockCode?: string;
  stockName?: string;
  regionId?: number;
  supplierId?: number;
  status?: StockStatus;
  ordering?: StockOrder;
};

export type StockResponse = {
  stockId: number;
  stockCode: string;
  stockName: string;
  esimType: EsimType;
  region: {
    regionId: number;
    regionCode: string;
  };
  supplier: {
    supplierId: number;
    supplierName: string;
  };
  status: StockStatus;
  remainedCount?: number;
  totalShippedCount?: number;
  lastWeekShippedCount?: number;
  refundedCount?: number;
  expiredCount?: number;
  supplierTaskType: StockSupplierTaskType;
  topUpValidityDays?: number;
};

export type ListStockResponse = {
  stockId: number;
  stockCode: string;
  stockName: string;
  status: StockStatus;
};

export type StockUpsertRequest = {
  stockCode: string;
  stockName: string;
  esimType: EsimType;
  regionId: number;
  supplierId: number;
  isUsingApi: boolean;
  status: StockStatus;
  topUpValidityDays?: number;
};

export const countStocks = async (
  params: GetStocksParams
): Promise<PageResponse<number>> =>
  (await API.get<PageResponse<number>>('/v1/stocks/count', { params })).data;

export const getStockCount = async (params: GetStocksParams) => {
  const { data } = await API.get<PageResponse<number>>('/v1/stocks/count', {
    params,
  });
  return data.data[0];
};

export const getStocks = async (params: GetStocksParams & PageParams) => {
  const { data } = await API.get<PageResponse<StockResponse>>('/v1/stocks', {
    params,
  });
  return data;
};

export const getAllStocks = async (): Promise<
  PageResponse<ListStockResponse>
> => (await API.get<PageResponse<ListStockResponse>>('/v1/stocks/all')).data;

export const getStock = async (stockId: number) => {
  const { data } = await API.get<PageResponse<StockResponse>>(
    `/v1/stocks/${stockId}`
  );
  return data;
};

export const createStock = async (
  request: StockUpsertRequest
): Promise<StockResponse> =>
  (await API.post<StockResponse>(`/v1/stocks`, request)).data;

export const editStock = async (
  stockId: number,
  request: StockUpsertRequest & { isDeleted?: boolean }
): Promise<StockResponse> =>
  (await API.put<StockResponse>(`/v1/stocks/${stockId}`, request)).data;

export const getStockByStockCode = async (
  stockCode: string
): Promise<PageResponse<StockResponse>> =>
  (
    await API.get<PageResponse<StockResponse>>(
      `/v1/stocks/stockCode/${stockCode}`
    )
  ).data;

export const exportStocksExcel = async (params: GetStocksParams) => {
  const { data } = await API.get('/v1/stocks/export-excel', {
    params,
    responseType: 'blob',
  });
  return data;
};
