import {
  GetStocksParams,
  StockOrder,
  StockResponse,
  exportStocksExcel,
} from '@apis/stock';
import Button from '@components/common/Button';
import CheckBox from '@components/common/CheckBox';
import Modal from '@components/common/Modal';
import Select, { SelectValue } from '@components/common/Select';
import TextInput from '@components/common/TextInput';
import AddStockCodeModalContent from '@components/stock/AddStockCodeModalContent';
import EditStockCodeModalContent from '@components/stock/EditStockCodeModalContent';
import { KOREAN_TIME_ZONE } from '@constants/timezone';
import useAsyncEffect from '@hooks/useAsyncEffect';
import { StockStatus } from '@models/stock';
import {
  Box,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
  tableCellClasses,
} from '@mui/material';
import { useRegionList } from '@queries/regions/useRegionList';
import { useStockCount } from '@queries/stocks/useStockCount';
import { useStockList } from '@queries/stocks/useStockList';
import { useAllSupplierList } from '@queries/suppliers/useAllSupplierList';
import { downloadBlob } from '@utils/file';
import { utcToZonedTime } from 'date-fns-tz';
import React from 'react';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { filterSearchParams } from '../../utils/search';
import SearchSelect from '../../components/common/SearchSelect';

const PAGE_SIZE = 50;

const Codes = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchForm, setSearchForm] = useState<GetStocksParams>({
    stockCode: searchParams.get('stockCode') || '',
    stockName: searchParams.get('stockName') || '',
    regionId: searchParams.get('regionId')
      ? Number(searchParams.get('regionId'))
      : undefined,
    supplierId: searchParams.get('supplierId')
      ? Number(searchParams.get('supplierId'))
      : undefined,
    status: (searchParams.get('status') as StockStatus) || undefined,
    ordering: (searchParams.get('ordering') as StockOrder) || undefined,
  });

  const [addMode, setAddMode] = useState(false);
  const [selected, setSelected] = useState<StockResponse | null>(null);

  const [page, setPage] = useState(
    searchParams.get('page') ? Number(searchParams.get('page')) : 1
  );
  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPage(value);
    setSearchParams({
      ...filterSearchParams(
        Object.fromEntries(new URLSearchParams(searchForm as any).entries())
      ),
      page: value.toString(),
    });
  };

  const { data, refetch, isLoading } = useStockList({
    page: page,
    searchParam: searchForm,
  });
  const {
    data: totalCount,
    refetch: refetchCount,
    isLoading: isLoadingCount,
  } = useStockCount(searchForm);

  const handleOnSearch = () => {
    setSearchParams({
      ...filterSearchParams(
        Object.fromEntries(new URLSearchParams(searchForm as any).entries())
      ),
    });
    refetch();
    refetchCount();
  };

  const [regionOptionList, setRegionOptionList] = React.useState<
    { value: SelectValue; label: string }[]
  >([]);
  const [supplierOptionList, setSupplierOptionList] = React.useState<
    { value: SelectValue; label: string }[]
  >([]);
  const { data: regionsData } = useRegionList();
  const { data: suppliersData } = useAllSupplierList();

  const handleOnExport = async () => {
    const nowInUTC = new Date();
    const kstDate = utcToZonedTime(nowInUTC, KOREAN_TIME_ZONE);
    const res = await exportStocksExcel(searchForm);
    const fileName = `stock-codes-${kstDate.toISOString()}.xlsx`;
    downloadBlob(res, fileName);
  };

  const handleOnAdd = () => {
    setAddMode(true);
  };

  const handleChangeInput = (
    name: 'stockCode' | 'stockName',
    value: string
  ) => {
    setSearchForm((prev) => ({ ...prev, [name]: value }));
  };

  const handleChangeSelect = (
    name: 'regionId' | 'supplierId' | 'status',
    value: SelectValue
  ) => {
    setSearchForm((prev) => ({ ...prev, [name]: value }));
  };

  const handleToggleReorderRequired = (value: boolean) => {
    setSearchForm((prev) => ({
      ...prev,
      ordering: value ? StockOrder.REORDER_REQUIRED : undefined,
    }));
  };

  const getIfReorderRequired = (remained: number) => {
    return remained < 1000;
  };

  const handleCloseAddModal = (needsRefetch: boolean) => {
    setAddMode(false);

    if (needsRefetch) {
      refetch();
      refetchCount();
    }
  };

  const handleCloseEditModal = (needsRefetch: boolean) => {
    setSelected(null);

    if (needsRefetch) {
      refetch();
      refetchCount();
    }
  };

  useAsyncEffect(async () => {
    if (!regionsData) return;
    setRegionOptionList(
      regionsData?.data.map((r) => ({
        label: r.regionCode,
        value: r.regionId,
      }))
    );
  }, [regionsData]);

  useAsyncEffect(async () => {
    if (!suppliersData) return;
    setSupplierOptionList(
      suppliersData?.data.map((s) => ({
        label: s.supplierName + ' (' + s.supplierCode + ')',
        value: s.supplierId,
      }))
    );
  }, [suppliersData]);

  return (
    <>
      <Typography variant="h4" component="h4">
        Stock Codes
      </Typography>
      <Box display={'flex'} justifyContent={'flex-end'}>
        <Button text={'Add'} onClick={handleOnAdd} />
      </Box>

      {/* Search Section */}
      <Box
        mb={3}
        gap={2}
        display={'flex'}
        flexDirection={'column'}
        alignItems={'flex-end'}
      >
        <Box width={'100%'} display={'flex'} gap={1} flexDirection={'row'}>
          <TextInput
            label={'Stock Code'}
            value={searchForm.stockCode}
            onChange={(e) => handleChangeInput('stockCode', e.target.value)}
            inputWidth={400}
            placeholder="Enter the stock code"
          />
          <TextInput
            label={'Stock Name'}
            value={searchForm.stockName}
            onChange={(e) => handleChangeInput('stockName', e.target.value)}
            inputWidth={400}
            placeholder="Enter the stock name"
          />
        </Box>
        <Box
          width={'100%'}
          display={'flex'}
          gap={2}
          flexDirection={'row'}
          alignItems={'flex-end'}
        >
          <SearchSelect
            disablePortal
            label={'Region'}
            value={
              regionOptionList.find((v) => v.value === searchForm.regionId) ||
              null
            }
            setValue={(v) => handleChangeSelect('regionId', v?.value || null)}
            options={regionOptionList}
            placeholder="Select the region"
          />
          <SearchSelect
            disablePortal
            label={'Supplier'}
            options={supplierOptionList}
            placeholder="Select the supplier"
            value={
              supplierOptionList.find(
                (v) => v.value === searchForm.supplierId
              ) || null
            }
            setValue={(v) => handleChangeSelect('supplierId', v?.value || null)}
          />
          <SearchSelect
            disablePortal
            label={'Status'}
            value={
              searchForm.status
                ? {
                    value: searchForm.status,
                    label: searchForm.status,
                  }
                : null
            }
            setValue={(value) =>
              handleChangeSelect('status', value?.value || null)
            }
            options={Object.keys(StockStatus).map((v) => ({
              label: v,
              value: v,
            }))}
            placeholder="Select the status"
          />
          <Button text={'Search'} onClick={handleOnSearch} />
          <Button
            text={'Export'}
            onClick={handleOnExport}
            color={'secondary'}
          />
        </Box>
      </Box>
      <CheckBox
        label={'Reorder Required'}
        value={searchForm.ordering === StockOrder.REORDER_REQUIRED}
        onCheck={handleToggleReorderRequired}
      />
      {/* List Section */}
      <TableContainer
        component={Paper}
        sx={{
          marginTop: 2,
        }}
      >
        <Table sx={{ minWidth: 700 }}>
          <TableHead>
            <TableRow>
              <StyledTableCell>Index</StyledTableCell>
              <StyledTableCell>Stock Code</StyledTableCell>
              <StyledTableCell>Stock Name</StyledTableCell>
              <StyledTableCell>Supplier</StyledTableCell>
              <StyledTableCell>Region</StyledTableCell>
              <StyledTableCell>Remained</StyledTableCell>
              <StyledTableCell>Shipped (Last 1 week)</StyledTableCell>
              <StyledTableCell>Shipped (Total)</StyledTableCell>
              <StyledTableCell>Refunded</StyledTableCell>
              <StyledTableCell>Expired</StyledTableCell>
              <StyledTableCell>Status</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(data?.data || []).map((row, index) => (
              <StyledTableRow
                key={row.stockId}
                isReorderRequired={getIfReorderRequired(row.remainedCount || 0)}
                onClick={() => {
                  setSelected(row);
                }}
              >
                <StyledTableCell component="th" scope="row">
                  {index + 1}
                </StyledTableCell>
                <StyledTableCell>{row.stockCode}</StyledTableCell>
                <StyledTableCell>{row.stockName}</StyledTableCell>
                <StyledTableCell>{row.supplier.supplierName}</StyledTableCell>
                <StyledTableCell>{row.region.regionCode}</StyledTableCell>
                <StyledTableCell>
                  <Typography
                    color={
                      getIfReorderRequired(row.remainedCount || 0)
                        ? 'error'
                        : 'initial'
                    }
                  >
                    {row.remainedCount || 0}
                  </Typography>
                </StyledTableCell>
                <StyledTableCell>
                  {row.lastWeekShippedCount || 0}
                </StyledTableCell>
                <StyledTableCell>{row.totalShippedCount || 0}</StyledTableCell>
                <StyledTableCell>{row.refundedCount || 0}</StyledTableCell>
                <StyledTableCell>{row.expiredCount || 0}</StyledTableCell>
                <StyledTableCell>{row.status}</StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {/* Pagination */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          mt: 2,
        }}
      >
        <Pagination
          count={Math.ceil((totalCount ?? 0) / PAGE_SIZE)}
          page={page}
          onChange={handleChangePage}
        />
      </Box>
      <Modal open={!!selected} onClose={() => handleCloseEditModal(false)}>
        {!!selected && (
          <EditStockCodeModalContent
            onClose={handleCloseEditModal}
            selected={selected}
          />
        )}
      </Modal>
      <Modal open={addMode} onClose={() => handleCloseAddModal(false)}>
        <AddStockCodeModalContent onClose={handleCloseAddModal} />
      </Modal>
    </>
  );
};

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#000',
    color: '#fff',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(
  ({ isReorderRequired }: { isReorderRequired: boolean }) => ({
    backgroundColor: isReorderRequired ? '#FFEBEE' : '#FFFFFF',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: isReorderRequired ? '#FFEBEE' : '#F3F6F9',
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  })
);

export default Codes;
