import API from '@apis/API';
import { PageResponse } from '@models/api';

export type CountryResponse = {
  countryId: number;
  countryCode: string;
  continentId: number;
  englishName: string;
  koreanName: string;
};

export const getAllCountries = async () =>
  (await API.get<PageResponse<CountryResponse>>(`/v1/countries`)).data;
