import React from 'react';
import {
  Box,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import Button from '@components/common/Button';
import {
  exportOrderReservationItemsExcel,
  OrderReservationItem,
} from '@apis/order';
import { downloadBlob } from '@utils/file';
import { utcToZonedTime } from 'date-fns-tz';
import { KOREAN_TIME_ZONE } from '@constants/timezone';

type ReservationItemTableProps = {
  orderId: number;
  reservationItems: OrderReservationItem[];
};

export const ReservationItemTable = ({
  orderId,
  reservationItems,
}: ReservationItemTableProps) => {
  const handleOnExport = async () => {
    const res = await exportOrderReservationItemsExcel(orderId);
    const nowInUTC = new Date();
    const kstDate = utcToZonedTime(nowInUTC, KOREAN_TIME_ZONE);
    const fileName = `reservation-tiems-${orderId}-${kstDate.toISOString()}.xlsx`;
    downloadBlob(res, fileName);
  };

  return (
    <>
      {/* List Section */}
      <Box width={'100%'} display={'flex'} gap={2} flexDirection={'row'} mt={2}>
        <Typography variant="h4" component="h4">
          Reserved Item List ({reservationItems.length} in total)
        </Typography>
        <Button text={'Export'} onClick={handleOnExport} color={'secondary'} />
      </Box>
      <TableContainer
        component={Paper}
        sx={{
          marginTop: 2,
        }}
      >
        <Table sx={{ minWidth: 700 }}>
          <TableHead>
            <TableRow>
              <StyledTableCell>Index</StyledTableCell>
              <>
                <StyledTableCell>Customer Name</StyledTableCell>
                <StyledTableCell>Email</StyledTableCell>
                <StyledTableCell>Airport Code</StyledTableCell>
                <StyledTableCell>Status</StyledTableCell>
                <StyledTableCell>Passport Country</StyledTableCell>
                <StyledTableCell>Passport Gender</StyledTableCell>
                <StyledTableCell>Passport Name</StyledTableCell>
                <StyledTableCell>Passport Number</StyledTableCell>
              </>
            </TableRow>
          </TableHead>
          <TableBody>
            {reservationItems.map((item, index) => (
              <StyledTableRow key={item.orderReservationItemId}>
                <StyledTableCell component="th" scope="row">
                  {index + 1}
                </StyledTableCell>
                <StyledTableCell component="th" scope="row">
                  {item.customerName}
                </StyledTableCell>
                <StyledTableCell component="th" scope="row">
                  {item.email}
                </StyledTableCell>
                <StyledTableCell component="th" scope="row">
                  {item.airportCode}
                </StyledTableCell>
                <StyledTableCell component="th" scope="row">
                  {item.itemStatus}
                </StyledTableCell>
                <StyledTableCell component="th" scope="row">
                  {item.passportData?.passportCountry}
                </StyledTableCell>
                <StyledTableCell component="th" scope="row">
                  {item.passportData?.passportGender}
                </StyledTableCell>
                <StyledTableCell component="th" scope="row">
                  {item.passportData?.passportName}
                </StyledTableCell>
                <StyledTableCell component="th" scope="row">
                  {item.passportData?.passportNumber}
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#000',
    color: '#fff',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(() => ({
  backgroundColor: '#FFFFFF',
  '&:hover': {
    backgroundColor: '#F3F6F9',
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));
