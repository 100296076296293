import API from '@apis/API';
import { PageResponse } from '@models/api';

export type OperatorResponse = {
  operatorId: number;
  operatorCode: string;
  continentId: number;
  countryId: number;
};

export const getAllOperators = async () =>
  (await API.get<PageResponse<OperatorResponse>>(`/v1/operators`)).data;
