import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { PageResponse } from '@models/api';
import {
  getInventoryRemainedSalesRank,
  GetSalesInventoryRemainedResponse,
} from '@apis/summary';
import { utcToZonedTime } from 'date-fns-tz';
import { KOREAN_TIME_ZONE } from '@constants/timezone';

export const useSummaryInventoryStatus = () => {
  const queryOptions: UseQueryOptions<
    PageResponse<GetSalesInventoryRemainedResponse>,
    Error
  > = {
    queryKey: ['dashboardSummayInventoryStatus'],
    queryFn: () => {
      const nowInUTC = new Date();
      const kstDate = utcToZonedTime(nowInUTC, KOREAN_TIME_ZONE);
      const todayStart = kstDate;
      todayStart.setHours(0, 0, 0, 0);

      const todayEnd = kstDate;
      todayEnd.setHours(23, 59, 59, 999);
      return getInventoryRemainedSalesRank({
        from: todayStart.getTime(),
        to: todayEnd.getTime(),
      });
    },
  };
  return useQuery(queryOptions);
};
