import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { getPartnerProductPrices } from '@apis/partner';
import { PageResponse } from '@models/api';
import { PartnerProductPriceResponse } from '@models/partner';
import { GetProductsParams } from '../../apis/product';
import { dropEmptyValues } from '../../utils/common';

export const usePartnerProductPrices = ({
  page,
  pageSize,
  partnerId,
  searchParam,
}: {
  page: number;
  pageSize: number;
  partnerId: number;
  searchParam: GetProductsParams;
}) => {
  const queryOptions: UseQueryOptions<
    PageResponse<PartnerProductPriceResponse>,
    Error
  > = {
    queryKey: ['partnerProductPrices', page, partnerId],
    queryFn: () => {
      return getPartnerProductPrices(partnerId, {
        pageNo: page,
        pageSize: pageSize,
        ...dropEmptyValues(searchParam, [
          'productName',
          'stockId',
          'countryId',
          'productStatus',
        ]),
      });
    },
    enabled: !!partnerId,
  };
  return useQuery(queryOptions);
};
