import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { PageResponse } from '@models/api';
import { PartnerProductPriceBulkUpload } from '../../models/partnerBulk';
import { getPriceUploadHistories } from '../../apis/partnerBulk';

export const usePriceUploadHistories = (
  partnerId: number,
  {
    pageNo,
  }: {
    pageNo: number;
  }
) => {
  const queryOptions: UseQueryOptions<
    PageResponse<PartnerProductPriceBulkUpload>,
    Error
  > = {
    queryKey: ['priceUploadHistories', partnerId, pageNo],
    queryFn: () => {
      return getPriceUploadHistories(partnerId, {
        pageNo: pageNo,
        pageSize: 50,
      });
    },
  };
  return useQuery(queryOptions);
};
