import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { useSummarySalesByPeriod } from '@queries/summaries/useSummarySalesByPeriod';
import { utcToZonedTime } from 'date-fns-tz';
import { KOREAN_TIME_ZONE } from '@constants/timezone';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const getDayList = () => {
  const dayList = [];
  for (let i = 0; i < 7; i++) {
    const nowInUTC = new Date();
    const kstDate = utcToZonedTime(nowInUTC, KOREAN_TIME_ZONE);
    dayList.push(
      new Date(kstDate.setDate(new Date().getDate() - i)).toLocaleDateString()
    );
  }
  return dayList.reverse();
};

function DashBoard() {
  const labels = getDayList();
  const { data: salesByPeriod } = useSummarySalesByPeriod();

  const min = salesByPeriod?.data.reduce(
    (acc, cur) => (acc > cur.amount ? cur.amount : acc),
    0
  );
  const max = salesByPeriod?.data.reduce(
    (acc, cur) => (acc < cur.amount ? cur.amount : acc),
    0
  );

  const data = {
    labels,
    datasets: [
      {
        label: 'sales',
        data: salesByPeriod?.data.map((item) => item.amount),
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'bottom' as const,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        min: min,
        max: Math.max(max || 0, 1200),
      },
    },
  };

  return (
    <Line
      options={options}
      data={data}
      style={{
        height: 300,
      }}
    />
  );
}

export default DashBoard;
