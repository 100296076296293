import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { PageResponse } from '@models/api';
import { EsimDetailResponse, getEsimExchangeDetail } from '@apis/exchange';

export const useEsimExchangeDetail = (encryptedEsimId: string) => {
  const queryOptions: UseQueryOptions<
    PageResponse<EsimDetailResponse>,
    Error
  > = {
    queryKey: ['esimExchangeDetail', encryptedEsimId],
    queryFn: () => {
      return getEsimExchangeDetail(encryptedEsimId);
    },
  };
  return useQuery(queryOptions);
};
