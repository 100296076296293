import API from './API';
import { PageResponse } from '@models/api';

export type SignInRequest = {
  username: string;
  password: string;
};

export type SignInResponse = {
  accessToken: string;
  refreshToken: string;
};

export const signIn = async (
  request: SignInRequest
): Promise<PageResponse<SignInResponse>> =>
  (await API.post<PageResponse<SignInResponse>>('/v1/auth/sign-in', request))
    .data;

export const refreshToken = async (refreshToken: string) =>
  (
    await API.post<PageResponse<string>>(`/v1/auth/refresh`, {
      refreshToken: refreshToken,
    })
  ).data;

export type CaptchaTokenVerifyRequest = {
  token: string;
};

export type CaptchaTokenVerifyResponse = {
  success: boolean;
  hostname?: string;
};

export const verifyCaptchaToken = async (
  token: string
): Promise<PageResponse<CaptchaTokenVerifyResponse>> =>
  (
    await API.post<PageResponse<CaptchaTokenVerifyResponse>>(
      `/v1/auth/captcha`,
      {
        token: token,
      }
    )
  ).data;
